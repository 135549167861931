<template>
	<PageCard title="Ihre Zugangsdaten">
		<div class="flex-col text-left px-6 py-5 min-w-card">
			<div v-if="state === 1" class="flex flex-1 text-red-600 mb-5 text-xs">
				Um die Sicherheit Ihres Kontos zu garantieren erhalten Sie eine E-Mail mit weiteren
				Anweisungen zum Ändern der Zugangsdaten.
			</div>

			<div class="border-b border-gray-300 pb-3 mb-3 text-sm">
				<div class="pl-2 pb-1 text-xs">E-Mail-Adresse</div>
				<div v-if="state === 0" class="pl-2 text-black">
					{{ email }}
				</div>
				<div v-else-if="state === 1" class="p-2">
					<CustomButton
						text="Email ändern"
						:loading="isEmailLoading"
						@click="requestNewEmail"
					></CustomButton>
				</div>
			</div>

			<div class="border-b border-gray-300 pb-3 mb-3 text-sm">
				<div class="pl-2 pb-1 text-xs">Passwort</div>
				<div v-if="state === 0" class="pl-2 text-black">************</div>
				<div v-else-if="state === 1" class="p-2">
					<CustomButton
						text="Passwort ändern"
						:loading="isPasswordLoading"
						@click="requestNewPassword"
					></CustomButton>
				</div>
			</div>

			<div class="pb-3 mb-3 text-sm">
				<div class="pl-2 pb-1 text-xs">Zwei-Faktor-Authentifizierung</div>
				<div v-if="state === 0" class="pl-2 text-black">
					{{ twoFactorType }}
				</div>
				<div v-else-if="state === 1" class="p-2">
					<CustomButton
						text="Ändern"
						:loading="isTwoFactorLoading"
						@click="requestNewTwoFactorType"
					></CustomButton>
				</div>
			</div>
		</div>

		<div v-if="state === 0" class="flex flex-row">
			<div class="flex-1"></div>
			<CustomButtonIcon
				:icon="this.$icons.edit"
				tooltip="Bearbeiten"
				@click="editAccount"
			></CustomButtonIcon>
			<div class="flex-1"></div>
		</div>
		<div v-else-if="state === 1" class="flex flex-row">
			<div class="flex-1"></div>
			<CustomButtonIcon
				:icon="this.$icons.cancel"
				tooltip="Abbrechen"
				class="mx-2 bg-btnbg_secondary_light"
				@click="cancelEditAccount"
			></CustomButtonIcon>
			<div class="flex-1"></div>
		</div>
	</PageCard>
</template>

<script>
import PageCard from '../PageCard'
import CustomButton from '../inputs/CustomButton'
import CustomButtonIcon from '../inputs/CustomButtonIcon'

export default {
	name: 'AccountInformationCard',
	components: {
		PageCard,
		CustomButton,
		CustomButtonIcon,
	},
	props: {
		loaded: {
			type: Object,
			default() {
				return {
					accountInfo: false,
				}
			},
		},
	},
	data() {
		return {
			state: 0,
			userId: '',
			email: '',
			twoFactorType: '',
			isEmailLoading: false,
			isPasswordLoading: false,
			isTwoFactorLoading: false,
		}
	},
	created() {
		this.getAccountInfo()
	},
	methods: {
		getAccountInfo: function () {
			let context = this
			this.$http
				.get('/panel/account/getAccountInfo')
				.then((response) => {
					context.loaded.accountInfo = true

					const status = response.data.status.value
					if ((response.status === 200) & (status === 100)) {
						context.email = response.data.body.email
						context.userId = response.data.body.resellerId
						context.twoFactorType = response.data.body.twoFactorAuthenticationType

						if (context.twoFactorType === 'NONE') {
							context.twoFactorType = 'deaktiviert'
						}
					}
				})
				.catch((err) => {
					console.log(err)
				})
				.finally(() => {
					context.state = 0
				})
		},
		editAccount: function () {
			this.state = 1
		},
		cancelEditAccount: function () {
			this.state = 0
		},
		requestNewEmail: function () {
			this.isEmailLoading = true

			let context = this
			this.$http
				.post('/panel/auth/initResetEmail', {
					email: this.email,
				})
				.then((response) => {
					context.isEmailLoading = false

					const status = response.data.status.value
					if (response.status === 200 && status === 100) {
						context.$toast.success(
							'Änderungsantrag erfolgreich eingereicht',
							context.$toaststyle.successMid
						)
					} else {
						context.$toast.error(response.data.body, context.$toaststyle.errorMid)
					}
				})
				.catch((err) => {
					console.log(err)

					context.isEmailLoading = false
					context.$toast.error(err.message, context.$toaststyle.errorMid)
				})
				.finally(() => {
					context.state = 0
				})
		},
		requestNewPassword: function () {
			this.isPasswordLoading = true

			let context = this
			this.$http
				.post('/panel/auth/initResetPassword', {
					email: this.email,
				})
				.then((response) => {
					context.isPasswordLoading = false

					const status = response.data.status.value
					if (response.status === 200 && status === 100) {
						context.$toast.success(
							'Änderungsantrag erfolgreich eingereicht',
							context.$toaststyle.successMid
						)
					} else {
						context.$toast.error(response.data.body, context.$toaststyle.errorMid)
					}
				})
				.catch((err) => {
					console.log(err)

					context.isPasswordLoading = false
					context.$toast.error(err.message, context.$toaststyle.errorMid)
				})
				.finally(() => {
					context.state = 0
				})
		},
		requestNewTwoFactorType: function () {
			this.isTwoFactorLoading = true

			let context = this
			this.$http
				.post('/panel/auth/initResetTwoFactorType', {
					email: this.email,
				})
				.then((response) => {
					context.isTwoFactorLoading = false

					const status = response.data.status.value
					if (response.status === 200 && status === 100) {
						context.$toast.success(
							'Änderungsantrag erfolgreich eingereicht',
							context.$toaststyle.successMid
						)
					} else {
						context.$toast.error(response.data.body, context.$toaststyle.errorMid)
					}
				})
				.catch((err) => {
					console.log(err)

					context.isTwoFactorLoading = false
					context.$toast.error(err.message, context.$toaststyle.errorMid)
				})
		},
	},
}
</script>

<style scoped></style>