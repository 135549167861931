<template>
	<GenericPopup v-if="show">
		<template v-slot:content>
			<PageCard title="Neues Geschäft für diesen Kunden anlegen" class="max-w-400px">
				<div class="text-sm text-justify">
					Diese Informationen sind für die Übersicht der cybo-boxen hilfreich. Wenn Sie
					nicht wissen, wie Sie das Geschäft nennen sollen, lassen Sie dieses Feld einfach
					leer und es wird automatisch ein Name generiert.
				</div>

				<CustomForm ref="addShopToCustomerForm">
					<div class="mx-auto justify-center">
						<CustomInput
							v-model="nameObj"
							placeholder="Name des Geschäfts"
							type="text"
							class="mx-auto mt-4"
							:rules="[$rules.required, $rules.lengthBelow50]"
						></CustomInput>

						<div class="flex flex-row">
							<div class="flex-1 mr-3">
								<CustomInput
									v-model="streetNameObj"
									placeholder="Straße"
									type="text"
									class="mx-auto mt-3"
									:rules="[$rules.required, $rules.lengthBelow50]"
								></CustomInput>
							</div>
							<div class="w-1/4">
								<CustomInput
									v-model="streetNumberObj"
									placeholder="Nr."
									type="text"
									class="mx-auto mt-3"
									:rules="[$rules.required, $rules.lengthBelow50]"
								></CustomInput>
							</div>
						</div>

						<div class="flex flex-row">
							<div class="w-1/3">
								<CustomInput
									v-model="zipCodeObj"
									placeholder="PLZ"
									type="text"
									class="mt-3"
									:rules="[$rules.required, $rules.lengthBelow50]"
								></CustomInput>
							</div>
							<div class="flex-1 ml-3">
								<CustomInput
									v-model="cityNameObj"
									placeholder="Ort"
									type="text"
									class="mt-3"
									:rules="[$rules.required, $rules.lengthBelow50]"
								></CustomInput>
							</div>
						</div>
					</div>
				</CustomForm>

				<div class="flex flex-row mt-7">
					<div class="flex flex-1"></div>
					<CustomButton
						text="Abbrechen"
						default-width
						class="mr-2"
						@click="close"
					></CustomButton>
					<CustomButton
						text="Hinzufügen"
						default-width
						:loading="isLoading"
						@click="addShop"
					></CustomButton>
				</div>
			</PageCard>
		</template>
	</GenericPopup>
</template>

<script>
import GenericPopup from '../../components/popup/GenericPopup'
import PageCard from '../../components/PageCard'
import CustomInput from '../../components/inputs/CustomInput'
import CustomButton from '../../components/inputs/CustomButton'
import CustomForm from '../../components/inputs/CustomForm'

export default {
	name: 'ResellerCustomerAddShopPopup',
	components: {
		CustomForm,
		CustomButton,
		CustomInput,
		PageCard,
		GenericPopup,
	},
	props: {
		customerId: {
			type: String,
			default: undefined,
		},
	},
	data() {
		return {
			show: false,
			isLoading: false,
			nameObj: {
				text: '',
				errorMessage: '',
			},
			streetNameObj: {
				text: '',
				errorMessage: '',
			},
			streetNumberObj: {
				text: '',
				errorMessage: '',
			},
			zipCodeObj: {
				text: '',
				errorMessage: '',
			},
			cityNameObj: {
				text: '',
				errorMessage: '',
			},
		}
	},
	methods: {
		open: function () {
			this.show = true
		},
		close: function () {
			this.show = false

			this.$refs['addShopToCustomerForm'].reset()
		},
		addShop: function () {
			if (this.$refs['addShopToCustomerForm'].validate()) {
				const context = this

				context.$http
					.post('/panel/reseller/addShopToCustomer', {
						customerId: context.customerId,
						shopName: context.nameObj.text,
						shopStreetName: context.streetNameObj.text,
						shopStreetNumber: context.streetNumberObj.text,
						shopZipCode: context.zipCodeObj.text,
						shopCityName: context.cityNameObj.text,
					})
					.then((response) => {
						const status = response.data.status.value

						if (response.status === 200 && status === 100) {
							context.close()
							context.$emit('success')
						} else {
							if (status === 1053) {
								context.nameObj.errorMessage = 'Name bereits vergeben'
							} else {
								context.$emit('error', response.data.body)
								context.close()
							}
						}
					})
					.catch((err) => {
						console.log(err)
						context.$emit('error', err.message)
						context.close()
					})
			}
		},
	},
}
</script>

<style scoped></style>