<template>
	<PageCard title="Ausstehende Kontoaktivierungen">
		<div class="text-center">
			<div v-if="customerList.length > 0" class="bg-listbg_dark rounded-3xl px-4 py-4">
				<div class="flex flex-row text-list_textheader_dark h-9 text-xs">
					<div class="w-48 h-full py-1 font-bold pl-4 text-left">Status</div>
					<div class="w-56 h-full py-1 font-bold pl-3 text-left">E-Mail-Adresse</div>
					<div class="w-40 h-full py-1 font-bold pl-3 text-left">Telefonnummer</div>
					<div class="flex-1 h-full py-1 font-bold pl-3 text-left"></div>
				</div>

				<div>
					<!-- click method is not on this elem so that the button click does not trigger it -->
					<div
						v-for="customer in customerList"
						:key="customer.id"
						class="flex flex-row text-new_fg rounded-3xl hover:bg-white text-xs border-t-2 border-white"
						@click="$emit('openCustomerPage', customer.id)"
					>
						<div class="text-left w-48 h-full py-3 pl-4">
							<BadgeFatPurple
								v-if="customer.accountState === 'VERIFICATION_PENDING'"
								text="AUSSTEHEND"
							></BadgeFatPurple>
							<BadgeFatRed v-else text="ABGELAUFEN"></BadgeFatRed>
						</div>
						<div class="w-56 h-full py-3 pl-3 text-left">
							{{ customer.email }}
						</div>
						<div class="w-40 h-full py-3 pl-3 text-left">
							{{ customer.phoneNumber }}
						</div>
						<div class="flex-1 h-full py-2 pl-3">
							<CustomButtonInline
								v-if="customer.accountState === 'VERIFICATION_PENDING'"
								text="entfernen"
								@click="initDeletePending(customer.id)"
							></CustomButtonInline>

							<div v-else>
								<CustomButtonInline
									text="erneuern"
									:default-width="true"
									class="mx-1 mb-1"
									@click="initResetPending(customer.id)"
								></CustomButtonInline>
								<CustomButtonInline
									text="entfernen"
									class="mx-1"
									@click="initDeletePending(customer.id)"
								></CustomButtonInline>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div v-else class="bg-listbg_dark rounded-3xl px-4 py-4 text-list_text_dark">
				Sie haben keinen Kunden mit ausstehender Kontoaktivierung
			</div>
		</div>

		<ConfirmPopup
			v-if="deletePendingObj.show"
			title="Kundenkonto löschen"
			text="Wollen Sie das Kundenkonto wirklich löschen?"
			:loading="deletePendingObj.loading"
			@cancel="cancelDeletePending"
			@confirm="deletePending"
		></ConfirmPopup>

		<ConfirmPopup
			v-if="resetPendingObj.show"
			title="Aktivierungsfrist zurücksetzen"
			text="Wollen Sie die Frist zur Kontoaktivierung dieses Kunden erneuern?"
			:loading="resetPendingObj.loading"
			@cancel="cancelResetPending"
			@confirm="resetPending"
		></ConfirmPopup>
	</PageCard>
</template>

<script>
import PageCard from '../../components/PageCard'

import CustomButtonInline from '../../components/inputs/CustomButtonInline'
import ConfirmPopup from '../../components/popup/ConfirmPopup'
import BadgeFatPurple from '../../components/badges/BadgeFatPurple'
import BadgeFatRed from '../../components/badges/BadgeFatRed'

export default {
	name: 'CustomerPendingList',
	components: {
		BadgeFatRed,
		BadgeFatPurple,
		ConfirmPopup,
		CustomButtonInline,
		PageCard,
	},
	props: {
		loaded: {
			type: Object,
			default() {
				return {
					customerPending: false,
				}
			},
		},
	},
	data() {
		return {
			customerList: [],
			deletePendingObj: {
				show: false,
				loading: false,
				customerId: undefined,
			},
			resetPendingObj: {
				show: false,
				loading: false,
				customerId: undefined,
			},
		}
	},
	created() {
		this.getPendingCustomerList()
	},
	methods: {
		isLoading: function (customerId) {
			return this.buttonLoading === customerId
		},
		getPendingCustomerList: function () {
			let context = this
			this.$http
				.get('/panel/reseller/getPendingCustomerList')
				.then((response) => {
					const status = response.data.status.value

					context.loaded.customerPending = true

					if (response.status === 200 && status === 100) {
						context.customerList = response.data.body
					}
				})
				.catch((err) => {
					console.log(err)
				})
		},
		initDeletePending: function (customerId) {
			this.deletePendingObj.show = true
			this.deletePendingObj.loading = false
			this.deletePendingObj.customerId = customerId
		},
		cancelDeletePending: function () {
			this.deletePendingObj.show = false
			this.deletePendingObj.lading = false
			this.deletePendingObj.customerId = undefined
		},
		deletePending: function () {
			const context = this

			context.deletePendingObj.loading = true

			context.$http
				.post('/panel/reseller/cancelPendingCustomerActivation', {
					customerId: context.deletePendingObj.customerId,
				})
				.then((response) => {
					const status = response.data.status.value

					if (response.status === 200 && status === 100) {
						context.getPendingCustomerList()

						context.$toast.success(
							'Kundenkonto wurde gelöscht',
							context.$toaststyle.successMid
						)
					} else {
						context.$toast.error(
							'Ein Fehler ist aufgetreten',
							context.$toaststyle.errorShort
						)
					}
				})
				.catch((err) => {
					console.log(err)

					context.$toast.error(err.message, context.$toaststyle.errorMid)
				})
				.finally(() => {
					context.cancelDeletePending()
				})
		},
		initResetPending: function (customerId) {
			this.resetPendingObj.show = true
			this.resetPendingObj.loading = false
			this.resetPendingObj.customerId = customerId
		},
		cancelResetPending: function () {
			this.resetPendingObj.show = false
			this.resetPendingObj.loading = false
			this.resetPendingObj.customerId = undefined
		},
		resetPending: function () {
			const context = this

			context.resetPendingObj.loading = true

			context.$http
				.post('/panel/reseller/resetExpiredCustomerActivation', {
					customerId: context.resetPendingObj.customerId,
				})
				.then((response) => {
					const status = response.data.status.value

					if (response.status === 200 && status === 100) {
						context.getPendingCustomerList()

						context.$toast.success(
							'Aktivierungsfrist wurde zurückgesetzt',
							context.$toaststyle.successMid
						)
					} else {
						context.$toast.error(
							'Ein Fehler ist aufgetreten',
							context.$toaststyle.errorShort
						)
					}
				})
				.catch((err) => {
					console.log(err)

					context.$toast.error(err.message, context.$toaststyle.errorMid)
				})
				.finally(() => {
					context.cancelResetPending()
				})
		},
	},
}
</script>

<style scoped></style>