<template>
	<div class="flex flex-1 flex-col h-screen">
		<PageBody no-margin class="flex flex-col">
			<!-- WAIT FOR IDENTIFIER VALIDATION -->
			<div v-if="isValidState === 0" class="flex flex-row">
				<div class="flex-1"></div>

				<PageCard title="Verifikation im Gange">
					<div>
						<img alt="loading" :src="this.$icons.loadingGif" class="w-10 mx-auto" />
					</div>
					<div class="text-center">Bitte warten..</div>
				</PageCard>

				<div class="flex-1"></div>
			</div>

			<!-- SET PASSWORD -->
			<div v-else-if="isValidState === 1" class="flex flex-col lg:flex-row">
				<div class="flex-1"></div>

				<div class="flex flex-col">
					<img
						alt="cybo-logo"
						:src="this.$icons.cyboLogoWhiteSlim"
						class="max-h-20 mt-5"
					/>
					<div class="mb-5 text-white font-bold">Serviceportal</div>

					<PageCard title="Änderungsantrag für Ihre Zugangsdaten" class="w-96 mx-auto">
						<div class="text-sm text-justify">
							Im Folgenden können Sie die Zugangsdaten für Ihr Benutzerkonto ändern.
						</div>
					</PageCard>
				</div>

				<PageCard title="Passwort neu setzen" class="w-96">
					<div class="text-sm font-bold mt-3 mb-2">Ihre E-Mail-Adresse</div>
					<div class="text-sm pb-3">
						<div class="pl-2 pb-1 pt-2 text-xs">E-Mail-Adresse</div>
						<div class="pl-2 text-black">
							{{ email }}
						</div>
					</div>

					<div class="text-sm font-bold mt-3 mb-2">Ihr neues Passwort</div>

					<div class="text-sm text-justify">
						Bitte geben Sie ein neues Passwort für Ihr Benutzerkonto ein. Das Passwort
						muss aus mindestens 5 Zeichen bestehen und darf keine Leerzeichen enthalten.
					</div>
					<div class="mt-3">
						<CustomForm ref="resetPasswordForm">
							<CustomInput
								type="password"
								placeholder="Passwort"
								:value="passwordObj"
								:rules="[
									$rules.required,
									$rules.lengthAbove5,
									$rules.lengthBelow50,
									$rules.noSpaces,
								]"
							></CustomInput>
							<CustomInput
								type="password"
								placeholder="Passwort wiederholen"
								:value="passwordBackupObj"
								class="mt-3"
								:rules="[$rules.required]"
							></CustomInput>
						</CustomForm>
					</div>
					<div class="flex flex-row mt-10">
						<div class="flex-1"></div>
						<CustomButton
							text="Neu setzen"
							:loading="isLoading"
							@click="resetPassword"
						></CustomButton>
					</div>
				</PageCard>

				<div class="flex-1"></div>
			</div>

			<!-- PAGE NOT AVAILABLE -->
			<div v-else>
				<OnetimepageNotAvailable></OnetimepageNotAvailable>
			</div>

			<div class="flex flex-1"></div>

			<div class="text-white text-sm py-4">
				Copyright © 2022 NovaNode GmbH |
				<a class="hover:underline cursor-pointer" @click="switchToPrivacyPolicyPage">
					Datenschutzerklärung
				</a>
				|
				<a class="hover:underline cursor-pointer" @click="switchToLegalNoticePage">
					Impressum
				</a>
			</div>
		</PageBody>
	</div>
</template>

<script>
import PageBody from './components/PageBody'
import PageCard from './components/PageCard'

import CustomInput from './components/inputs/CustomInput'
import CustomButton from './components/inputs/CustomButton'
import OnetimepageNotAvailable from './components/onetimepages/OnetimepageNotAvailable'
import CustomForm from './components/inputs/CustomForm'

export default {
	name: 'ResetPasswordPage',
	components: {
		CustomForm,
		OnetimepageNotAvailable,
		PageBody,
		PageCard,
		CustomInput,
		CustomButton,
	},
	data() {
		return {
			isLoading: false,
			identifier: undefined,
			isValidState: 0,
			email: undefined,
			passwordObj: {
				text: '',
				errorMessage: '',
			},
			passwordBackupObj: {
				text: '',
				errorMessage: '',
			},
		}
	},
	created() {
		this.identifier = this.$route.params.identifier
		this.validateIdentifier(this.identifier)
	},
	methods: {
		resetErrors: function () {
			this.passwordObj.errorMessage = ''
			this.passwordBackupObj.errorMessage = ''
		},
		validateIdentifier: function (identifier) {
			let context = this
			this.$http
				.post('/panel/auth/validatePageIdentifier', {
					identifier: identifier,
				})
				.then((response) => {
					const status = response.data.status.value
					if (response.status === 200 && status === 100) {
						context.isValidState = 1
						context.email = response.data.body.email
					} else {
						context.isValidState = 2
					}
				})
				.catch((err) => {
					context.isValidState = 2
					console.log(err)
				})
		},
		resetPassword: function () {
			if (!this.$refs['resetPasswordForm'].validate()) {
				return
			}

			if (this.passwordObj.text !== this.passwordBackupObj.text) {
				this.passwordBackupObj.errorMessage = 'Passwörter stimmen nicht überein'

				this.$toast.error('Bitte Eingaben prüfen', this.$toaststyle.errorShort)

				return
			}

			const context = this
			context.isLoading = true

			context.$http
				.post('/panel/auth/resetPassword', {
					identifier: this.identifier,
					email: this.email,
					password: this.passwordObj.text,
				})
				.then((response) => {
					const status = response.data.status.value
					if (response.status === 200 && status === 100) {
						context.$router.push('/login').catch(() => {
							// required for router to not throw exception
						})

						context.$toast.success(
							'Passwort erfolgreich geändert',
							context.$toaststyle.successMid
						)
					} else {
						console.log(response.data)

						context.$toast.error(
							'Ein Fehler ist aufgetreten',
							context.$toaststyle.errorShort
						)
					}
				})
				.catch((err) => {
					console.log(err)

					context.$toast.error(
						'Ein Fehler ist aufgetreten',
						context.$toaststyle.errorShort
					)
				})
				.finally(() => {
					context.isLoading = true
				})
		},
		switchToPrivacyPolicyPage: function () {
			const routeData = this.$router.resolve({
				name: 'privacyPolicyPage',
				params: {
					from: 'index',
				},
			})

			window.open(routeData.href, '_blank')
		},
		switchToLegalNoticePage: function () {
			const routeData = this.$router.resolve({
				name: 'legalNoticePage',
				params: {
					from: 'index',
				},
			})

			window.open(routeData.href, '_blank')
		},
	},
}
</script>

<style scoped></style>