<template>
	<div class="mb-5 select-none">
		<div class="flex overflow-hidden ml-1.5 group-hover:ml-20" style="transition: 0.3s all">
			<div class="ht-12 mt-5 h-12 flex-shrink-0 cursor-pointer" @click="goToIndexPage">
				<img class="w-full h-full" :src="this.$icons.cyboLogoNavbarCollapsed" alt="" />
			</div>

			<div
				class="ht-12 mt-5 h-12 fixed ml-12 hidden group-hover:block cursor-pointer"
				style="width: 144px"
				@click="goToIndexPage"
			>
				<img class="w-full h-full" :src="this.$icons.cyboLogoNavbarExpanded" alt="" />
			</div>
		</div>
		<div class="flex flex-col h-5 mx-auto text-xs text-navfg_light">
			<div class="hidden group-hover:block">Serviceportal</div>
			<div class="hidden group-hover:block">v{{ VERSION }}</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'NavbarLogoItem',
	methods: {
		goToIndexPage: function () {
			this.$router
				.push({
					path: '/',
				})
				.catch(() => {})
		},
	},
}
</script>

<style scoped></style>