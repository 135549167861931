<template>
	<PageCard title="Händler mit aktivem Konto" class="relative z-9">
		<CustomButtonIcon
			v-if="showAddButton"
			:icon="this.$icons.addActive"
			:tooltip="'Händler hinzufügen'"
			class="absolute top-2 right-4"
			@click="openResellerAddPage"
		></CustomButtonIcon>

		<div class="text-center">
			<div
				v-if="filteredResellerList.length > 0"
				class="bg-listbg_dark rounded-3xl px-4 py-4"
			>
				<div class="flex flex-row text-list_textheader_dark min-h-12">
					<div class="w-40 h-full py-1 font-bold text-left pl-4">Händlernummer</div>
					<div class="flex-1 h-full py-1 font-bold text-left pl-4">Geschäftsname</div>
					<div class="w-36 h-full py-1 font-bold text-right pr-4">Aktive Kunden</div>
					<div class="w-36 h-full py-1 font-bold text-right pr-4">Ausstehende Kunden</div>
					<div class="w-36 h-full py-1 font-bold text-right pr-4">Aktive Boxen</div>
					<div class="w-36 h-full py-1 font-bold text-right pr-4">Ausstehende Boxen</div>
					<div
						class="w-24 h-full py-1 text-left pl-4 font-bold cursor-pointer hover:underline"
						@click="sortBy('created')"
					>
						Zeit
					</div>
					<div
						class="w-28 flex flex-row h-full py-1 font-bold text-left pl-4 cursor-pointer hover:underline"
						@click="sortBy('created')"
					>
						<div class="flex-1"></div>
						<div class="flex-1">Datum</div>
						<div v-if="sort.column === 'created'" class="flex-1 pl-4 pt-1">
							<img
								v-if="sort.ascending"
								:src="this.$icons.arrowUp"
								alt=""
								class="h-4"
							/>
							<img v-else :src="this.$icons.arrowDown" alt="" class="h-4" />
						</div>
					</div>
				</div>

				<div>
					<!-- click method is not on this elem so that the button click does not trigger it -->
					<div
						v-for="reseller in filteredResellerList"
						:key="reseller.id"
						class="flex flex-row text-list_text_dark cursor-pointer rounded-3xl hover:bg-white"
					>
						<div
							class="w-40 h-full py-3 text-left pl-4 border-t"
							@click="$emit('clickOnReseller', reseller.id)"
						>
							{{ reseller.resellerId }}
						</div>
						<div
							class="flex-1 h-full py-3 text-left pl-4 border-t"
							@click="$emit('clickOnReseller', reseller.id)"
						>
							{{ reseller.companyName }}
						</div>
						<div
							class="w-36 h-full py-3 text-right pr-4 border-t"
							@click="$emit('clickOnReseller', reseller.id)"
						>
							{{ reseller.numCustomersActive }}
						</div>
						<div
							class="w-36 h-full py-3 text-right pr-4 border-t"
							@click="$emit('clickOnReseller', reseller.id)"
						>
							{{ reseller.numCustomersPending }}
						</div>
						<div
							class="w-36 h-full py-3 text-right pr-4 border-t"
							@click="$emit('clickOnReseller', reseller.id)"
						>
							{{ reseller.numBoxesActive }}
						</div>
						<div
							class="w-36 h-full py-3 text-right pr-4 border-t"
							@click="$emit('clickOnReseller', reseller.id)"
						>
							{{ reseller.numBoxesPending }}
						</div>
						<div
							class="w-24 h-full py-3 text-left pl-4 border-t"
							@click="$emit('clickOnReseller', reseller.id)"
						>
							{{ $utils.date2timeString(new Date(reseller.created)) }}
						</div>
						<div
							class="w-28 h-full py-3 text-left pl-4 border-t"
							@click="$emit('clickOnReseller', reseller.id)"
						>
							{{ $utils.date2dateString(new Date(reseller.created)) }}
						</div>
					</div>
				</div>
			</div>

			<div v-else class="bg-listbg_dark rounded-3xl px-4 py-4 text-list_text_dark">
				Bisher gibt es noch keinen Händler mit aktivem Konto
			</div>

			<CustomPagination
				v-if="page.max > 1"
				:current-page-num="page.current"
				:max-page-num="page.max"
				class="mt-4"
				@previous="previousPage"
				@next="nextPage"
			></CustomPagination>
		</div>
	</PageCard>
</template>

<script>
import PageCard from '../../components/PageCard'

import CustomButtonIcon from '../../components/inputs/CustomButtonIcon'
import CustomPagination from '../../components/CustomPagination'

export default {
	name: 'ResellerList',
	components: {
		CustomPagination,
		CustomButtonIcon,
		PageCard,
	},
	props: {
		loaded: {
			type: Object,
			default() {
				return {
					resellerListActive: false,
				}
			},
		},
		showAddButton: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			resellerList: [],
			page: {
				entriesPerPage: 10,
				current: 1,
				max: 1,
			},
			sort: {
				column: 'created',
				ascending: false,
			},
		}
	},
	computed: {
		filteredResellerList: function () {
			let tmp = this.resellerList

			if (tmp.length === 0) {
				return tmp
			}

			let sortColumn = 'created'
			if (this.sort.column in tmp[0]) {
				sortColumn = this.sort.column
			}

			tmp.sort((a, b) => {
				if (a[sortColumn] < b[sortColumn]) {
					return -1
				} else if (a[sortColumn] > b[sortColumn]) {
					return 1
				}
				return 0
			})

			if (!this.sort.ascending) {
				tmp.reverse()
			}

			let numPages =
				Math.trunc(tmp.length / this.page.entriesPerPage) +
				(tmp.length % this.page.entriesPerPage === 0 ? 0 : 1)

			this.updatePages(numPages)

			const indexStart = (this.page.current - 1) * this.page.entriesPerPage
			let indexStop = indexStart + this.page.entriesPerPage
			if (indexStop > tmp.length) {
				indexStop = tmp.length
			}

			tmp = tmp.slice(indexStart, indexStop)

			return tmp
		},
	},
	created() {
		this.getResellerListActive()
	},
	methods: {
		previousPage: function () {
			if (this.page.current <= 1) {
				return
			}
			this.page.current -= 1
		},
		nextPage: function () {
			if (this.page.current >= this.page.max) {
				return
			}
			this.page.current += 1
		},
		sortBy: function (column) {
			if (column === this.sort.column) {
				this.sort.ascending = !this.sort.ascending
			} else {
				this.sort.column = column
				this.sort.ascending = false
			}
		},
		getResellerListActive: function () {
			let context = this
			this.$http
				.get('/panel/admin/getResellerList')
				.then((response) => {
					context.loaded.resellerListActive = true

					const status = response.data.status.value
					if (response.status === 200 && status === 100) {
						context.resellerList = response.data.body
					}
				})
				.catch((err) => {
					context.loaded.resellerListActive = true
					console.log(err)
				})
		},
		openResellerAddPage: function () {
			this.$router.push({
				path: '/admin/addReseller',
			})
		},
		updatePages: function (maxPage) {
			this.page.max = maxPage
		},
	},
}
</script>

<style scoped></style>