<template>
	<div class="flex flex-col">
		<PageHeader headline="Details zu dieser Box"></PageHeader>

		<PageBody class="pt-4">
			<PageCard title="Ereignisse">
				<div
					v-if="activityLog.length > 0"
					class="bg-listbg_dark rounded-3xl px-4 py-4 text-xs"
				>
					<div class="flex flex-row text-list_textheader_dark min-h-12">
						<div class="w-10 h-full py-1 font-bold text-left pl-4">Nr.</div>
						<div class="w-40 h-full py-1 font-bold text-left pl-4">Datum</div>
						<div class="w-24 h-full py-1 font-bold text-left pl-4">Status</div>
						<div class="flex-1 h-full py-1 font-bold text-left pl-4">Aktivität</div>
					</div>

					<div class="max-h-64 overflow-y-auto">
						<!-- click method is not on this elem so that the button click does not trigger it -->
						<div
							v-for="(item, index) in sortedActivityLog"
							:key="item.id"
							class="flex flex-row text-list_text_dark rounded-3xl hover:bg-white"
						>
							<div class="w-10 h-full py-1 text-left pl-4 border-t">
								{{ index + 1 }}
							</div>
							<div class="w-40 h-full py-1 text-left pl-4 border-t">
								{{
									$utils.date2dateString(new Date(item['timestampCreated'])) +
									' ' +
									$utils.date2timeString(new Date(item['timestampCreated']))
								}}
							</div>
							<div class="w-24 h-full py-1 text-left pl-4 border-t">
								<span
									v-if="item.status === 'OK'"
									class="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-indigo-100 bg-green-600 rounded"
								>
									OK
								</span>
								<span
									v-else-if="item.status === 'CRITICAL_ERROR'"
									class="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-indigo-100 bg-red-600 rounded"
								>
									ERROR
								</span>
								<span
									v-else-if="item.status === 'WARNING'"
									class="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-indigo-100 bg-yellow-400 rounded"
								>
									WARNING
								</span>
							</div>
							<div class="flex-1 h-full py-1 text-left pl-4 border-t">
								{{ item.message }}
							</div>
						</div>
					</div>
				</div>

				<div v-else class="bg-listbg_dark rounded-3xl px-4 py-4 text-list_text_dark">
					Keine Aktivitäten dieser Box vorhanden..
				</div>
			</PageCard>
		</PageBody>

		<LoadingScreen :has-loaded="hasLoaded"></LoadingScreen>
	</div>
</template>

<script>
import PageHeader from '../components/PageHeader'
import PageBody from '../components/PageBody'
import PageCard from '../components/PageCard'
import LoadingScreen from '../components/LoadingScreen'

export default {
	name: 'AdminBoxPage',
	components: {
		LoadingScreen,
		PageCard,
		PageBody,
		PageHeader,
	},
	data() {
		return {
			boxId: '',
			loaded: {
				activityLog: false,
			},
			activityLog: [],
		}
	},
	computed: {
		hasLoaded: function () {
			return this.loaded.activityLog
		},
		sortedActivityLog: function () {
			// eslint-disable-next-line vue/no-side-effects-in-computed-properties
			return this.activityLog.sort(function (a, b) {
				if (a['timestampCreated'] < b['timestampCreated']) {
					return -1
				} else if (a['timestampCreated'] > b['timestampCreated']) {
					return 1
				}
				return 0
			})
		},
	},
	created() {
		this.boxId = this.$route.params.identifier

		this.getBoxActivity(this.boxId)
	},
	methods: {
		getBoxActivity: function (boxId) {
			this.loaded.activityLog = false

			this.$http
				.post('/panel/admin/getBoxActivityLog', {
					boxId: boxId,
				})
				.then((response) => {
					this.loaded.activityLog = true

					const status = response.data.status.value
					if (response.status === 200 && status === 100) {
						this.activityLog = response.data.body
					}
				})
				.catch((err) => {
					this.loaded.activityLog = true
					console.log(err)
				})
		},
	},
}
</script>

<style scoped></style>