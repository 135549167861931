<template>
	<div class="flex flex-col">
		<PageHeader headline="Ihr Profil" description="Informationen über Sie"></PageHeader>

		<PageBody>
			<div class="flex flex-row pt-10">
				<div class="flex-1"></div>

				<div class="flex flex-col xl:flex-row">
					<PageCard title="" class="align-top">
						<div class="flex-col text-left min-w-card">
							<div class="pl-2 pb-1 text-xs">Händlernummer</div>
							<div class="pl-2 text-black border-b border-gray-300 pb-3 mb-3 text-sm">
								{{ resellerId }}
							</div>

							<div class="pl-2 pb-1 text-xs">Ihr Händler-Aktivierungsschlüssel</div>
							<div class="pl-2 text-black text-sm">
								{{ resellerKey }}
							</div>
						</div>
					</PageCard>

					<AccountInformationCard :loaded="loaded"></AccountInformationCard>

					<ProfileInformationCard :loaded="loaded"></ProfileInformationCard>
				</div>

				<div class="flex-1"></div>
			</div>
		</PageBody>

		<LoadingScreen :has-loaded="hasLoaded"></LoadingScreen>
	</div>
</template>

<script>
import PageHeader from '../components/PageHeader'
import PageBody from '../components/PageBody'
import PageCard from '../components/PageCard'

import AccountInformationCard from '../components/profile/AccountInformationCard'
import ProfileInformationCard from '../components/profile/ProfileInformationCard'
import LoadingScreen from '../components/LoadingScreen'

export default {
	name: 'ResellerProfilePage',
	components: {
		LoadingScreen,
		PageCard,
		PageHeader,
		PageBody,
		AccountInformationCard,
		ProfileInformationCard,
	},
	data() {
		return {
			loaded: {
				profileInfo: false,
				accountInfo: false,
				metaInfo: false,
			},
			resellerId: '',
			resellerKey: '',
		}
	},
	computed: {
		hasLoaded: function () {
			return this.loaded.profileInfo && this.loaded.accountInfo && this.loaded.metaInfo
		},
	},
	created() {
		this.getMetaInfo()
	},
	methods: {
		getMetaInfo: function () {
			let context = this
			this.$http
				.get('/panel/reseller/getMetaInfo')
				.then((response) => {
					context.loaded.metaInfo = true

					const status = response.data.status.value
					if (response.status === 200 && status === 100) {
						context.resellerId = response.data.body.resellerId
						context.resellerKey = response.data.body.resellerKey
					}
				})
				.catch((err) => {
					console.log(err)
				})
		},
	},
}
</script>

<style scoped></style>