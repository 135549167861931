<template>
	<div class="flex flex-row fixed top-0 left-0 w-full h-full bg-loader_bg z-front">
		<div class="flex-1"></div>

		<PageCard :title="title" class="mt-32">
			<div class="text-sm">
				{{ text }}
			</div>
			<div class="flex flex-row mt-5">
				<div class="flex-1"></div>

				<CustomButtonIcon
					:icon="this.$icons.confirm"
					tooltip="Schliessen"
					@click="confirmAction"
				></CustomButtonIcon>

				<div class="flex-1"></div>
			</div>
		</PageCard>

		<div class="flex-1"></div>
	</div>
</template>

<script>
import PageCard from '../PageCard'
import CustomButtonIcon from '../inputs/CustomButtonIcon'

export default {
	name: 'InfoPopup',
	components: {
		CustomButtonIcon,
		PageCard,
	},
	props: {
		title: {
			type: String,
			default: '',
		},
		text: {
			type: String,
			default: '',
		},
	},
	methods: {
		confirmAction: function () {
			if (this.loading) {
				return
			}
			this.$emit('confirm')
		},
	},
}
</script>

<style scoped></style>