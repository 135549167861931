<template>
	<div class="w-full h-44 pl-20 flex flex-row bg-page_body_bg">
		<slot></slot>
	</div>
</template>

<script>
export default {
	name: 'PageFooter',
}
</script>

<style scoped></style>