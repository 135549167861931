<template>
	<div class="flex flex-col">
		<PageHeader
			headline="Kundenübersicht"
			description="Informationen zu Ihren Kunden"
		></PageHeader>

		<PageBody>
			<div class="pt-10 container mx-auto">
				<PageCard title="Informationen">
					<div class="text-xs">
						Das Konto eines von Ihnen neu hinzugefügten Kunden ist zunächst inaktiv. Der
						Kunde erhält eine E-Mail mit weiteren Anweisungen zum Aktivieren des
						Kundenkontos, welche nur eine begrenzte Zeit möglich ist. Vor Ablauf dieser
						Frist können Sie hier diese Kontoaktivierung widerrufen oder nach Ablauf die
						Kontoaktivierung erneuern oder diese löschen.
					</div>
				</PageCard>

				<CustomerPendingList :loaded="loaded"></CustomerPendingList>
			</div>
		</PageBody>
	</div>
</template>

<script>
import PageHeader from '../components/PageHeader'
import PageBody from '../components/PageBody'
import CustomerPendingList from './components/CustomerPendingList'
import PageCard from '../components/PageCard'

export default {
	name: 'ResellerCustomerPendingListPage',
	components: {
		PageCard,
		CustomerPendingList,
		PageBody,
		PageHeader,
	},
	data() {
		return {
			loaded: {
				customerPending: false,
			},
		}
	},
	computed: {
		hasLoaded: function () {
			return this.loaded.customerPending
		},
	},
}
</script>

<style scoped></style>