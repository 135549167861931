<template>
	<div
		class="relative text-dd_text_inactive bg-dd_bg_inactive border-2 rounded-3xl h-9 max-w-sm outline"
		:class="customClass"
	>
		<input
			id="input-field"
			v-model="value.text"
			placeholder=" "
			:type="type"
			:disabled="value.disabled"
			class="absolute top-0 left-0 px-4 h-full w-full z-10 appearance-none focus:outline-none bg-transparent"
			@keyup.enter="$emit('enter')"
		/>

		<label
			for="input-field"
			class="label-small absolute top-0 left-0 origin-0 z-9 ml-6 py-2 bg-dd_bg_inactive duration-300"
			:class="customClassLabel"
			@keyup.enter="$emit('enter')"
		>
			{{ placeholder }}
		</label>

		<div
			v-if="value.errorMessage"
			class="absolute top-10 left-5 text-in_info mb-10 text-red-700"
		>
			{{ value.errorMessage }}
		</div>
	</div>
</template>

<script>
export default {
	name: 'CustomInputSmall',
	props: {
		placeholder: {
			type: String,
			default: '',
		},
		value: {
			type: Object,
			required: true,
		},
		type: {
			type: String,
			default: 'text',
		},
	},
	data() {
		return {
			text: '',
		}
	},
	computed: {
		customClass: function () {
			let classText = ''

			classText += this.value.errorMessage
				? 'border-red-700 mb-6 '
				: 'border-dd_text_inactive '

			if (this.value.text) {
				classText += 'text-in_text_active '
			}
			return classText
		},
		customClassLabel: function () {
			let classText = ''
			if (this.value.text) {
				classText += ''
			} else {
				//
			}
			return classText
		},
	},
	watch: {
		value() {
			this.$emit('input', this.value)
		},
	},
}
</script>

<style scoped></style>