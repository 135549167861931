<template>
	<div class="flex flex-col w-full min-h-screen bg-gradient_left text-white">
		<div class="pt-10 hover:underline cursor-pointer" @click="goBack">← zurück</div>

		<div class="text-3xl pt-10 pb-12 text-white">Impressum</div>

		<div class="flex flex-row mb-20">
			<div class="w-1/5"></div>

			<div class="flex-1 text-justify text-gray7">
				<div class="text-xl font-bold">Angaben gemäß § 5 TMG</div>
				<ul class="mt-6">
					<li>NovaNode GmbH</li>
					<li>Dresdner Straße 44</li>
					<li>63110 Rodgau</li>
				</ul>
				<br />

				<div class="pt-10 pb-4">Kontakt:</div>
				<ul>
					<li>Joel Daon</li>
					<li>
						Telefon:
						<a class="hover:text-primary" href="tel:+49610674410">
							+49 (0) 6106 74410</a
						>
					</li>
					<li>
						E-Mail:
						<a class="hover:text-primary" href="mailto:info@cybo.de">info[at]cybo.de</a>
					</li>
				</ul>
				<br />

				<div class="pt-10 pb-4">EU-Streitschlichtung</div>
				<p>
					Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS)
					bereit:
					<a
						href="https://ec.europa.eu/consumers/odr"
						target="_blank"
						rel="noopener noreferrer"
					>
						https://ec.europa.eu/consumers/odr </a
					>. Unsere E-Mail-Adresse finden Sie oben im Impressum.
				</p>

				<div class="pt-10 pb-4">
					Verbraucherstreitbeilegung / Universalschlichtungsstelle
				</div>
				<p>
					Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
					Verbraucherschlichtungsstelle teilzunehmen.
				</p>

				<div class="pt-10 pb-4">Haftung für Inhalte</div>
				<p>
					Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen
					Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind
					wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
					gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen,
					die auf eine rechtswidrige Tätigkeit hinweisen.
				</p>
				<p>
					Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach
					den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung
					ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung
					möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir
					diese Inhalte umgehend entfernen.
				</p>

				<br />
				<div class="pt-10 pb-4">Haftung für Links</div>
				<p>
					Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir
					keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine
					Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige
					Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden
					zum Zeitpunkt der Verlinkung auf mögliche mögliche Rechtsverstöße überprüft.
					Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine
					permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete
					Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von
					Rechtsverletzungen werden wir derartige Links umgehend entfernen.
				</p>

				<div class="pt-10 pb-4">Urheberrecht</div>
				<p>
					Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten
					unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung,
					Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes
					bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
					Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen
					Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber
					erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden
					Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
					Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden
					Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte
					umgehend entfernen.
				</p>
			</div>

			<div class="w-1/5"></div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'LegalNoticePage',
	props: {
		from: {
			type: String,
			required: true,
			default: 'index',
		},
	},
	methods: {
		goBack: function () {
			this.$router.push({
				name: this.from,
			})
		},
		switchToLoginPage: function () {
			this.$router.push({
				path: '/login',
			})
		},
	},
}
</script>

<style scoped></style>