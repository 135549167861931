<template>
	<div
		v-if="active"
		class="flex h-8 ml-10 overflow-hidden mt-2 mr-4 cursor-pointer rounded-full bg-navfg_light"
		@click="handleClick"
	>
		<img :src="imageActive" alt="" class="w-10" />
		<span class="h-full ml-2 text-left text-sm leading-8 text-navbg_light">
			{{ text }}
		</span>
	</div>

	<div
		v-else
		class="flex h-8 ml-10 overflow-hidden mt-2 mr-4 cursor-pointer rounded-full hover:border-navborder_hover_light hover:shadow-nav_hover_light"
		@click="handleClick"
	>
		<img :src="image" alt="" class="w-10" />
		<span class="h-full ml-2 text-left text-sm leading-8 text-navfg_light">
			{{ text }}
		</span>
	</div>
</template>

<script>
export default {
	name: 'NavbarSubItem',
	props: {
		active: {
			type: Boolean,
			default: false,
		},
		text: {
			type: String,
			required: true,
		},
		image: {
			type: String,
			default: '',
		},
		imageActive: {
			type: String,
			default: '',
		},
	},
	methods: {
		handleClick: function () {
			this.$emit('selected')
		},
	},
}
</script>

<style scoped></style>