<template>
	<div class="flex flex-col">
		<PageHeader headline="Neue cybo-box zuweisen"></PageHeader>

		<PageBody>
			<div class="container mx-auto">
				<div v-if="state < 3" class="flex flex-row pt-4">
					<PageCard title="Einem Kunden eine neue cybo-box zuweisen">
						<div class="text-xs">
							Um einen neuen Aktivierungsschlüssel zu erhalten, muss eine neue
							cybo-box einem Ihrer Kunden zugewiesen werden. Wählen Sie den
							entsprechenden Eintrag des Kunden in der Liste aus und geben Sie die
							erforderlichen Daten ein. Anschließend erhalten Sie eine E-Mail mit dem
							Aktivierungsschlüssel.
						</div>
					</PageCard>
				</div>

				<div v-if="state < 3" class="mb-4">
					<PageCard title="">
						<div class="flex flex-row">
							<div class="flex flex-1">
								<div class="mx-auto" :class="styleStepper(0)">
									<div class="font-bold text-xl text-center">1</div>
									<div>Kunde auswählen</div>
								</div>
							</div>
							<div class="flex flex-1">
								<div class="mx-auto" :class="styleStepper(1)">
									<div class="font-bold text-xl text-center">2</div>
									<div>Geschäftsadresse eingeben</div>
								</div>
							</div>
							<div class="flex flex-1">
								<div class="mx-auto" :class="styleStepper(2)">
									<div class="font-bold text-xl text-center">3</div>
									<div>Eingaben überprüfen</div>
								</div>
							</div>
						</div>
					</PageCard>
				</div>

				<CustomerList
					v-if="state === 0"
					show-add-button
					:loaded="loaded"
					:title="'Wählen Sie einen Kunden aus:'"
					@clickOnCustomer="selectCustomer"
				></CustomerList>

				<div v-if="state === 1" class="flex flex-row">
					<div class="flex flex-1"></div>
					<PageCard title="Geben Sie die Anschrift des Geschäfts der neuen cybo-box ein:">
						<div class="text-xs w-full max-w-card_medium text-justify mb-2">
							Bitte geben Sie die Anschrift des Geschäfts ein, in welchem die neue
							cybo-box installiert werden soll.
						</div>
						<CustomForm ref="assignBoxSelectShopForm">
							<div class="mx-auto justify-center">
								<div class="flex flex-row">
									<div class="mr-3 w-72">
										<CustomInput
											v-model="streetNameObj"
											placeholder="Straße"
											type="text"
											class="mx-auto mt-3"
											:rules="[
												this.$rules.required,
												this.$rules.lengthBelow50,
											]"
										></CustomInput>
									</div>
									<div class="w-28">
										<CustomInput
											v-model="streetNumberObj"
											placeholder="Nr."
											type="text"
											class="mx-auto mt-3"
											:rules="[
												this.$rules.required,
												this.$rules.lengthBelow50,
											]"
										></CustomInput>
									</div>
								</div>

								<div class="flex flex-row">
									<div class="w-28 mr-3">
										<CustomInput
											v-model="zipCodeObj"
											placeholder="PLZ"
											type="text"
											class="mt-3"
											:rules="[
												this.$rules.required,
												this.$rules.lengthBelow50,
											]"
										></CustomInput>
									</div>
									<div class="w-72">
										<CustomInput
											v-model="cityNameObj"
											placeholder="Ort"
											type="text"
											class="mt-3"
											:rules="[
												this.$rules.required,
												this.$rules.lengthBelow50,
											]"
										></CustomInput>
									</div>
								</div>
							</div>
						</CustomForm>

						<div class="mt-4 flex flex-row">
							<CustomButton text="Zurück" @click="stepBack"></CustomButton>
							<div class="flex flex-1"></div>
							<CustomButton text="Weiter" @click="selectShop"></CustomButton>
						</div>
					</PageCard>
					<div class="flex flex-1"></div>
				</div>

				<div v-if="state === 2" class="flex flex-row">
					<div class="flex flex-1"></div>
					<PageCard title="Eingaben überprüfen:" class="w-card_large">
						<div class="text-xs">
							Bitte überprüfen Sie die angegebenen Kundendaten und korrigieren Sie
							eventuelle Fehler.
						</div>
						<div class="my-4">
							<div class="border-b border-gray-300 pb-3 mb-3 text-sm">
								<div class="pl-2 pb-1 text-xs">Kundennummer</div>
								<div class="pl-2 text-black">
									{{ selectedCustomer.details.userId }}
								</div>
							</div>
							<div class="border-b border-gray-300 pb-3 mb-3 text-sm">
								<div class="pl-2 pb-1 text-xs">E-Mail-Adresse</div>
								<div class="pl-2 text-black">
									{{ selectedCustomer.details.email }}
								</div>
							</div>
							<div class="border-b border-gray-300 pb-3 mb-3 text-sm">
								<div class="pl-2 pb-1 text-xs">Unternehmensname</div>
								<div class="pl-2 text-black">
									{{ selectedCustomer.details.companyName }}
								</div>
							</div>
							<div class="border-b border-gray-300 pb-3 mb-3 text-sm">
								<div class="pl-2 pb-1 text-xs">Ansprechpartner</div>
								<div class="pl-2 text-black">
									{{ selectedCustomer.details.contactPerson }}
								</div>
							</div>
							<div class="border-b border-gray-300 pb-3 mb-3 text-sm">
								<div class="pl-2 pb-1 text-xs">Telefonnummer</div>
								<div class="pl-2 text-black">
									{{ selectedCustomer.details.phoneNumber }}
								</div>
							</div>
							<div class="pb-3 mb-3 text-sm">
								<div class="pl-2 pb-1 text-xs">Geschäftsanschrift</div>
								<div class="pl-2 text-black">
									{{ streetNameObj.text + ' ' + streetNumberObj.text }}
									<br />
									{{ zipCodeObj.text + ' ' + cityNameObj.text }}
								</div>
							</div>
						</div>
						<div class="flex flex-row">
							<CustomButton text="Zurück" @click="stepBack"></CustomButton>
							<div class="flex flex-1"></div>
							<CustomButton
								text="Neue cybo-box zuweisen"
								:loading="isLoadingAssignBox"
								@click="assignNewBox"
							></CustomButton>
						</div>
					</PageCard>
					<div class="flex flex-1"></div>
				</div>

				<div v-if="state === 3" class="flex flex-row mt-10">
					<div class="flex flex-1"></div>
					<PageCard title="Neue cybo-box erfolgreich zugewiesen">
						<div class="text-xs w-full max-w-card_medium text-justify mb-2">
							Sie erhalten den Aktivierungsschlüssel für diese cybo-box über eine
							Nachricht an Ihre E-Mail-Adresse. Der Kunde erhält ebenfalls eine
							Benachrichtigung über die Zuweisung an seine E-Mail-Adresse.
						</div>

						<img alt="success" :src="this.$icons.success" class="mx-auto mt-4" />

						<div class="flex flex-row mt-5">
							<div class="flex flex-1"></div>
							<CustomButton
								text="Eine weitere cybo-box zuweisen"
								class="mx-auto"
								@click="resetSteps"
							></CustomButton>
							<div class="flex flex-1"></div>
						</div>
					</PageCard>
					<div class="flex flex-1"></div>
				</div>
			</div>
		</PageBody>

		<LoadingScreen :has-loaded="hasLoaded"></LoadingScreen>
	</div>
</template>

<script>
import PageHeader from '../components/PageHeader'
import PageBody from '../components/PageBody'
import PageCard from '../components/PageCard'

import CustomerList from './components/CustomerList'
import LoadingScreen from '../components/LoadingScreen'
import CustomInput from '../components/inputs/CustomInput'
import CustomForm from '../components/inputs/CustomForm'
import CustomButton from '../components/inputs/CustomButton'

export default {
	name: 'ResellerBoxAddPage',
	components: {
		CustomButton,
		CustomForm,
		CustomInput,
		LoadingScreen,
		PageCard,
		CustomerList,
		PageBody,
		PageHeader,
	},
	data() {
		return {
			// 0 = select customer, 1 = insert address, 2 = confirm, 3 = success info
			state: 0,
			loaded: {
				customerActive: false,
			},
			isLoadingAssignBox: false,
			customerId: undefined,
			streetNameObj: {
				text: '',
				errorMessage: '',
			},
			streetNumberObj: {
				text: '',
				errorMessage: '',
			},
			zipCodeObj: {
				text: '',
				errorMessage: '',
			},
			cityNameObj: {
				text: '',
				errorMessage: '',
			},
			selectedCustomer: {
				id: undefined,
				details: undefined,
			},
		}
	},
	computed: {
		hasLoaded: function () {
			return this.loaded.customerActive
		},
	},
	methods: {
		resetSteps: function () {
			this.state = 0
		},
		stepBack: function () {
			this.state = Math.max(0, this.state - 1)
		},
		stepForward: function () {
			this.state = Math.min(3, this.state + 1)
		},
		selectCustomer: function (id) {
			this.selectedCustomer.id = id

			this.getCustomerInfo()
		},
		selectShop: function () {
			if (!this.$refs['assignBoxSelectShopForm'].validate()) {
				return
			}

			this.stepForward()
		},
		getCustomerInfo: function () {
			const context = this

			context.$http
				.post('/panel/reseller/getCustomerInfo', {
					customerId: context.selectedCustomer.id,
				})
				.then((response) => {
					const status = response.data.status.value

					if (response.status === 200 && status === 100) {
						context.selectedCustomer.details = response.data.body

						context.stepForward()
					} else {
						console.log('ERROR while getting customer info')
					}
				})
				.catch((err) => {
					console.log(err)
				})
		},
		assignNewBox: function () {
			const context = this

			context.isLoadingAssignBox = true

			context.$http
				.post('/panel/reseller/assignBoxToCustomer2', {
					customerId: context.selectedCustomer.id,
					streetName: context.streetNameObj.text,
					streetNumber: context.streetNumberObj.text,
					zipCode: context.zipCodeObj.text,
					cityName: context.cityNameObj.text,
				})
				.then((response) => {
					const status = response.data.status.value

					if (response.status === 200 && status === 100) {
						context.$toast.success(
							'Neue cybo-box erfolgreich zugewiesen',
							context.$toaststyle.successMid
						)
						context.stepForward()
					} else {
						context.$toast.error(
							'Ein Fehler ist aufgetreten',
							context.$toaststyle.errorMid
						)
					}
				})
				.catch((err) => {
					console.log('err')
					context.$toast.error(err.message, context.$toaststyle.errorMid)
				})
				.finally(() => {
					context.isLoadingAssignBox = false
				})
		},
		styleStepper: function (stepNum) {
			if (stepNum === this.state) {
				return 'text-black border-b border-black '
			}
			return ''
		},
	},
}
</script>

<style scoped></style>