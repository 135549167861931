<template>
	<div
		v-if="active"
		class="h-9 mt-7 flex cursor-pointer bg-navfg_light rounded-full mx-5 overflow-hidden"
		@click="handleClick"
	>
		<img :src="imageActive" class="h-full pl-0.5" alt="" />
		<span class="nav-text h-full ml-7 text-left text-sm leading-9 text-navbg_light">
			{{ text }}
		</span>
	</div>

	<div
		v-else
		class="h-9 mt-7 flex cursor-pointer hover:border-navborder_hover_light hover:shadow-nav_hover_light rounded-full mx-5 overflow-hidden"
		style="padding-bottom: 1px"
		@click="handleClick"
	>
		<img :src="image" class="h-full pl-0.5" alt="" />
		<span class="nav-text h-full ml-7 text-left text-sm leading-9 text-navfg_light">
			{{ text }}
		</span>
	</div>
</template>

<script>
export default {
	name: 'NavbarItem',
	props: {
		active: {
			type: Boolean,
			default: false,
		},
		text: {
			type: String,
			default: '',
		},
		image: {
			type: String,
			default: '',
		},
		imageActive: {
			type: String,
			default: '',
		},
	},
	methods: {
		handleClick: function () {
			this.$emit('selected')
		},
	},
}
</script>

<style scoped></style>