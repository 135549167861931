<template>
	<div class="flex flex-col">
		<PageHeader headline="Neuer Kunde"></PageHeader>

		<PageBody>
			<div class="flex flex-row pt-10">
				<div class="flex-1"></div>

				<PageCard title="Einen neuen Kunden anlegen" class="w-96 text-xs">
					<div class="pt-4">
						Bitte geben Sie die E-Mail-Adresse und die Telefonnummer des Kunden an,
						welchem eine neue cybo-box zugewiesen werden soll.
					</div>

					<div class="my-4">
						Dem Kunden wird eine Anleitung zum Erstellen des erforderlichen
						Benutzerkontos an die angegebene E-Mail-Adresse gesendet.
					</div>
				</PageCard>

				<PageCard title="Kontaktdaten des Kunden">
					<div class="text-xs">Mit (*) gekennzeichnete Felder sind Pflichteingaben</div>

					<div class="mx-auto justify-center">
						<CustomForm ref="addNewCustomerForm">
							<div class="py-3 my-1">
								<CustomInput
									v-model="emailObj"
									placeholder="E-Mail-Adresse*"
									type="text"
									class="mx-auto mt-1"
									:rules="[$rules.required, $rules.isEmail, $rules.lengthBelow50]"
								></CustomInput>

								<CustomInput
									v-model="phoneObj"
									placeholder="Telefonnummer*"
									type="text"
									class="mx-auto mt-3"
									:rules="[
										$rules.required,
										$rules.lengthAbove5,
										$rules.lengthBelow30,
									]"
								></CustomInput>
							</div>

							<div class="pb-3 mb-2">
								<div class="p-2 text-sm">Kontaktperson</div>
								<CustomInput
									v-model="contactPersonFirstNameObj"
									placeholder="Vorname"
									type="text"
									class="mx-auto"
									:rules="[$rules.lengthBelow30]"
								></CustomInput>

								<CustomInput
									v-model="contactPersonLastNameObj"
									placeholder="Nachname"
									type="text"
									class="mx-auto mt-3"
									:rules="[$rules.lengthBelow30]"
								></CustomInput>
							</div>

							<div class="pb-3 mb-3">
								<div class="p-2 text-sm">Anschrift des Unternehmens</div>

								<CustomInput
									v-model="companyNameObj"
									placeholder="Firmenname"
									type="text"
									class="mx-auto"
									:rules="[$rules.lengthBelow30]"
								></CustomInput>

								<div class="flex flex-row">
									<div class="flex-1 mr-3">
										<CustomInput
											v-model="companyStreetNameObj"
											placeholder="Straße"
											type="text"
											class="mx-auto mt-3"
											:rules="[$rules.lengthBelow30]"
										></CustomInput>
									</div>
									<div class="w-1/4">
										<CustomInput
											v-model="companyStreetNumberObj"
											placeholder="Nr."
											type="text"
											class="mx-auto mt-3"
											:rules="[$rules.lengthBelow30]"
										></CustomInput>
									</div>
								</div>

								<div class="flex flex-row">
									<div class="w-1/3">
										<CustomInput
											v-model="companyZipCodeObj"
											placeholder="PLZ"
											type="text"
											class="mt-3"
											:rules="[$rules.lengthBelow20]"
										></CustomInput>
									</div>
									<div class="flex-1 ml-3">
										<CustomInput
											v-model="companyCityObj"
											placeholder="Ort"
											type="text"
											class="mt-3"
											:rules="[$rules.lengthBelow30]"
										></CustomInput>
									</div>
								</div>
							</div>
						</CustomForm>
					</div>

					<div class="flex flex-row mt-4">
						<div class="flex-1"></div>
						<CustomButton
							text="Hinzufügen"
							:loading="isLoading"
							@click="addCustomer"
						></CustomButton>
					</div>
				</PageCard>

				<div class="flex-1"></div>
			</div>
		</PageBody>
	</div>
</template>

<script>
import PageHeader from '../components/PageHeader'
import PageBody from '../components/PageBody'
import PageCard from '../components/PageCard'

import CustomButton from '../components/inputs/CustomButton'
import CustomInput from '../components/inputs/CustomInput'
import CustomForm from '../components/inputs/CustomForm'

export default {
	name: 'ResellerCustomerPage',
	components: {
		CustomForm,
		PageCard,
		PageHeader,
		PageBody,
		CustomButton,
		CustomInput,
	},
	data() {
		return {
			isLoading: false,
			emailObj: {
				text: '',
				errorMessage: '',
			},
			contactPersonFirstNameObj: {
				text: '',
				errorMessage: '',
			},
			contactPersonLastNameObj: {
				text: '',
				errorMessage: '',
			},
			phoneObj: {
				text: '',
				errorMessage: '',
			},
			companyNameObj: {
				text: '',
				errorMessage: '',
			},
			companyStreetNameObj: {
				text: '',
				errorMessage: '',
			},
			companyStreetNumberObj: {
				text: '',
				errorMessage: '',
			},
			companyZipCodeObj: {
				text: '',
				errorMessage: '',
			},
			companyCityObj: {
				text: '',
				errorMessage: '',
			},
		}
	},
	methods: {
		goBackToOverview: function () {
			this.$router.push({
				path: '/reseller/customers',
			})
		},
		addCustomer: function () {
			this.isLoading = true

			if (this.$refs['addNewCustomerForm'].validate()) {
				let context = this
				this.$http
					.post('/panel/reseller/addNewCustomer', {
						email: context.emailObj.text,
						phoneNumber: context.phoneObj.text,
						contactPersonFirstName: context.contactPersonFirstNameObj.text,
						contactPersonLastName: context.contactPersonLastNameObj.text,
						companyName: context.companyNameObj.text,
						companyStreetName: context.companyStreetNameObj.text,
						companyStreetNumber: context.companyStreetNumberObj.text,
						companyZipCode: context.companyZipCodeObj.text,
						companyCity: context.companyCityObj.text,
					})
					.then((response) => {
						context.isLoading = false
						const status = response.data.status.value

						if (response.status === 200 && status === 100) {
							context.$router.push({
								name: 'resellerCustomerPendingListPage',
							})

							context.$toast.success(
								'Neuen Kunden erfolgreich hinzugefügt',
								context.$toaststyle.successMid
							)

							return
						}

						switch (status) {
							case 1001:
							case 1002:
								context.emailObj.errorMessage = 'Eingabe ungültig'
								break
							case 1003:
								context.emailObj.errorMessage = 'Email bereits vergeben'
								context.$toast.error(
									'E-Mail bereits vergeben',
									context.$toaststyle.errorMid
								)
								break
							case 1008:
								context.emailObj.errorMessage = 'Email existiert nicht'
								context.$toast.error(
									'E-Mail existiert nicht',
									context.$toaststyle.errorMid
								)
								break
							case 1031:
							case 1032:
								context.phoneObj.errorMessage = 'Eingabe ungültig'
								break
						}
					})
					.catch((err) => {
						console.log(err)

						context.$toast.error(err.message, context.$toaststyle.errorMid)
					})
					.finally(() => {
						context.isLoading = false
					})
			} else {
				this.isLoading = false
			}
		},
	},
}
</script>

<style scoped></style>