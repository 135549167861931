<template>
	<div ref="custom-form-container">
		<slot></slot>
	</div>
</template>

<script>
export default {
	name: 'CustomForm',
	methods: {
		validate: function () {
			let container = this.$refs['custom-form-container']

			let isValid = true

			for (let inputElem of container.getElementsByTagName('input')) {
				let elem = inputElem.parentNode.__vue__

				elem.resetError()

				const value = elem.value.text

				for (const rule of elem.rules) {
					let result = rule(value)

					if (result !== true) {
						elem.setError(result)
						isValid = false
						break
					}
				}

				elem.$forceUpdate()
			}

			return isValid
		},
		resetValidation: function () {
			let container = this.$refs['custom-form-container']

			for (let inputElem of container.getElementsByTagName('input')) {
				let elem = inputElem.parentNode.__vue__

				elem.resetError()
				elem.$forceUpdate()
			}
		},
		reset: function () {
			let container = this.$refs['custom-form-container']

			for (let inputElem of container.getElementsByTagName('input')) {
				let elem = inputElem.parentNode.__vue__

				elem.resetError()
				elem.resetValue()

				elem.$forceUpdate()
			}
		},
	},
}
</script>

<style scoped></style>