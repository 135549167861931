<template>
	<div class="flex flex-col">
		<PageHeader headline="Boxübersicht"></PageHeader>

		<PageBody class="pt-4">
			<PageCard title="">
				<div class="text-center">
					<div class="bg-listbg_dark rounded-3xl px-4 py-4 text-xs mb-2">
						<div class="flex flex-row">
							<div class="mt-2.5 mr-2">
								<input v-model="filter.online" type="checkbox" />
								Nur online
							</div>

							<div class="mx-1">
								<CustomDropdownFilterSmall
									:data="filter.status"
									class="w-36"
									title="Status"
								>
								</CustomDropdownFilterSmall>
							</div>

							<div class="mx-1">
								<CustomDropdownFilterSmall
									:data="filter.type"
									class="w-36"
									title="Typ"
								>
								</CustomDropdownFilterSmall>
							</div>

							<div class="w-36 mx-1">
								<CustomInputSmall
									v-model="filter.activationKey"
									type="text"
									placeholder="Aktivation Key"
								></CustomInputSmall>
							</div>

							<div class="w-36 mx-1">
								<CustomInputSmall
									v-model="filter.serialNumber"
									type="text"
									placeholder="Serial Number"
								></CustomInputSmall>
							</div>
						</div>
					</div>

					<div
						v-if="Object.keys(filteredBoxList).length > 0"
						class="bg-listbg_dark rounded-3xl px-4 py-4 text-xs"
					>
						<div class="flex flex-row text-list_textheader_dark min-h-12">
							<div class="w-24 h-full py-1 font-bold text-left pl-4">Verbindung</div>
							<div class="w-24 h-full py-1 font-bold text-left pl-4">Status</div>
							<div class="w-32 h-full py-1 font-bold text-left pl-4">Boxnummer</div>
							<div class="w-40 h-full py-1 font-bold text-left pl-4">
								Activation Key
							</div>
							<div class="w-40 h-full py-1 font-bold text-left pl-4">
								Serial Number
							</div>
							<div class="w-24 h-full py-1 font-bold text-left pl-4">Version</div>
							<div class="w-32 h-full py-1 font-bold text-left pl-4">Typ</div>
							<div class="w-32 h-full py-1 font-bold text-left pl-4">Hardware</div>
							<div
								class="flex-1 flex flex-row h-full py-1 font-bold text-right pl-4 pr-4 cursor-pointer hover:underline"
								@click="sortBy('latestActivity')"
							>
								<div class="flex-1">Letzte Aktivität</div>
								<div v-if="sort.column === 'latestActivity'" class="pl-4 pt-0.5">
									<img
										v-if="sort.ascending"
										:src="this.$icons.arrowUp"
										alt=""
										class="h-3"
									/>
									<img v-else :src="this.$icons.arrowDown" alt="" class="h-3" />
								</div>
							</div>
						</div>

						<div>
							<!-- click method is not on this elem so that the button click does not trigger it -->
							<div
								v-for="box in filteredBoxList"
								:key="box.id"
								class="flex flex-row text-list_text_dark cursor-pointer rounded-3xl hover:bg-white"
							>
								<div
									class="w-24 h-full py-3 text-left pl-4 border-t"
									@click="clickOnBox(box.id)"
								>
									<div v-if="box.online">
										<img
											:src="$icons.badgeOk"
											alt=""
											class="w-4 float-left mr-2"
										/>
										Online
									</div>
									<div v-else>
										<img
											:src="$icons.badgeOffline"
											alt=""
											class="w-4 float-left mr-2"
										/>
										Offline
									</div>
								</div>
								<div
									class="w-24 h-full pt-2.5 text-left pl-4 border-t"
									@click="clickOnBox(box.id)"
								>
									<span
										v-if="box.status === 'OK'"
										class="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-indigo-100 bg-green-600 rounded"
									>
										OK
									</span>
									<span
										v-else-if="box.status === 'CRITICAL_ERROR'"
										class="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-indigo-100 bg-red-600 rounded"
									>
										ERROR
									</span>
									<span
										v-else-if="box.status === 'WARNING'"
										class="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-indigo-100 bg-yellow-400 rounded"
									>
										WARNING
									</span>
								</div>
								<div
									class="w-32 h-full py-3 text-left pl-4 border-t"
									@click="clickOnBox(box.id)"
								>
									{{ box.boxId }}
								</div>
								<div
									class="w-40 h-full py-3 text-left pl-4 border-t"
									@click="clickOnBox(box.id)"
								>
									{{ box.activationKey }}
								</div>
								<div
									class="w-40 h-full py-3 text-left pl-4 border-t"
									@click="clickOnBox(box.id)"
								>
									{{ box.serialNumber ? box.serialNumber : '' }}
								</div>
								<div
									class="w-24 h-full py-3 text-left pl-4 border-t"
									@click="clickOnBox(box.id)"
								>
									{{ box.versionNumber }}
								</div>
								<div
									class="w-32 h-full pt-2.5 text-left pl-4 border-t"
									@click="clickOnBox(box.id)"
								>
									<span
										v-if="box.type === 'DEVELOPER_KEY'"
										class="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-indigo-100 bg-pink-700 rounded"
									>
										ENTWICKLER
									</span>
									<span
										v-else-if="box.type === 'DISTRIBUTOR_KEY'"
										class="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-indigo-100 bg-indigo-700 rounded"
									>
										HÄNDLER
									</span>
									<span
										v-else-if="box.type === 'REGULAR_KEY'"
										class="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-indigo-100 bg-green-700 rounded"
									>
										REGULÄR
									</span>
								</div>
								<div
									class="w-32 h-full pt-2.5 text-left pl-4 border-t"
									@click="clickOnBox(box.id)"
								>
									<span
										v-if="box.hardwareType"
										class="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-indigo-100 bg-gray-800 rounded"
									>
										{{ box.hardwareType }}
									</span>
								</div>
								<div
									class="flex-1 h-full py-3 text-right pl-4 pr-4 border-t"
									@click="clickOnBox(box.id)"
								>
									<div v-if="box.latestActivity">
										<span class="pr-3">
											{{
												$utils.date2timeString(new Date(box.latestActivity))
											}}
										</span>
										{{ $utils.date2dateString(new Date(box.latestActivity)) }}
									</div>
								</div>
							</div>
						</div>
					</div>

					<div v-else class="bg-listbg_dark rounded-3xl px-4 py-4 text-list_text_dark">
						Aktuell gibt es keine aktiven regulären Boxen
					</div>

					<CustomPagination
						v-if="page.max > 1"
						:current-page-num="page.current"
						:max-page-num="page.max"
						class="mt-4"
						@previous="previousPage"
						@next="nextPage"
					></CustomPagination>
				</div>
			</PageCard>
		</PageBody>

		<LoadingScreen :has-loaded="hasLoaded"></LoadingScreen>
	</div>
</template>

<script>
import PageHeader from '../components/PageHeader'
import PageBody from '../components/PageBody'
import PageCard from '../components/PageCard'

import CustomPagination from '../components/CustomPagination'
import LoadingScreen from '../components/LoadingScreen'
import CustomDropdownFilterSmall from '../components/inputs/CustomDropdownFilterSmall'
import CustomInputSmall from '../components/inputs/CustomInputSmall'

export default {
	name: 'AdminBoxListPage',
	components: {
		CustomInputSmall,
		CustomDropdownFilterSmall,
		LoadingScreen,
		CustomPagination,
		PageCard,
		PageBody,
		PageHeader,
	},
	data() {
		return {
			boxList: {},
			loaded: {
				boxList: false,
			},
			page: {
				entriesPerPage: 10,
				current: 1,
				max: 1,
			},
			sort: {
				column: 'latestActivity',
				ascending: false,
			},
			filter: {
				online: false,
				status: {
					selected: { text: 'Keine Auswahl', value: 'NONE' },
					options: [
						{ text: 'Keine Auswahl', value: 'NONE' },
						{ text: 'OK', value: 'OK' },
						{ text: 'WARNING', value: 'WARNING' },
						{ text: 'ERROR', value: 'ERROR' },
					],
				},
				type: {
					selected: { text: 'Keine Auswahl', value: 'NONE' },
					options: [
						{ text: 'Keine Auswahl', value: 'NONE' },
						{ text: 'REGULÄR', value: 'REGULAR_KEY' },
						{ text: 'ENTWICKLER', value: 'DEVELOPER_KEY' },
						{ text: 'HÄNDLER', value: 'DISTRIBUTOR_KEY' },
					],
				},
				activationKey: {
					text: '',
					errorMessage: '',
				},
				serialNumber: {
					text: '',
					errorMessage: '',
				},
			},
		}
	},
	computed: {
		filteredBoxList: function () {
			let boxList = this.$store.state.status.boxList

			// converts the dictionary to list of values
			let tmp = Object.keys(boxList).map(function (key) {
				return boxList[key]
			})

			// filtering
			if (this.filter.online) {
				tmp = tmp.filter((item) => item.online === true)
			}
			if (this.filter.status.selected.value !== 'NONE') {
				tmp = tmp.filter((item) => item.status === this.filter.status.selected.value)
			}
			if (this.filter.type.selected.value !== 'NONE') {
				tmp = tmp.filter((item) => item.type === this.filter.type.selected.value)
			}
			if (this.filter.activationKey.text !== '') {
				tmp = tmp.filter(
					(item) =>
						item.activationKey
							.toLowerCase()
							.indexOf(this.filter.activationKey.text.toLowerCase()) > -1
				)
			}
			if (this.filter.serialNumber.text !== '') {
				tmp = tmp.filter(
					(item) =>
						item.serialNumber &&
						item.serialNumber
							.toLowerCase()
							.indexOf(this.filter.serialNumber.text.toLowerCase()) > -1
				)
			}

			// sorting
			if (tmp.length > 0) {
				let sortColumn = 'latestActivity'
				if (this.sort.column in tmp[0]) {
					sortColumn = this.sort.column
				}

				tmp.sort((a, b) => {
					if (a[sortColumn] < b[sortColumn]) {
						return -1
					} else if (a[sortColumn] > b[sortColumn]) {
						return 1
					}
					return 0
				})

				if (!this.sort.ascending) {
					tmp.reverse()
				}
			}

			// paging
			let numPages =
				Math.trunc(tmp.length / this.page.entriesPerPage) +
				(tmp.length % this.page.entriesPerPage === 0 ? 0 : 1)

			this.updatePages(numPages)

			const indexStart = (this.page.current - 1) * this.page.entriesPerPage
			let indexStop = indexStart + this.page.entriesPerPage
			if (indexStop > tmp.length) {
				indexStop = tmp.length
			}

			tmp = tmp.slice(indexStart, indexStop)

			return tmp
		},
		hasLoaded: function () {
			return true
		},
	},
	methods: {
		previousPage: function () {
			if (this.page.current <= 1) {
				return
			}
			this.page.current -= 1
		},
		nextPage: function () {
			if (this.page.current >= this.page.max) {
				return
			}
			this.page.current += 1
		},
		sortBy: function (column) {
			if (column === this.sort.column) {
				this.sort.ascending = !this.sort.ascending
			} else {
				this.sort.column = column
				this.sort.ascending = false
			}
		},
		updatePages: function (maxPage) {
			this.page.max = maxPage
		},
		clickOnBox: function (id) {
			this.$router.push({
				path: '/admin/boxes/' + id,
			})
		},
	},
}
</script>

<style scoped></style>