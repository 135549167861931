<template>
	<div class="flex flex-col">
		<PageHeader headline="Profil" description="Informationen über ihren Account"></PageHeader>

		<PageBody>
			<div>Noch im Aufbau..</div>
		</PageBody>
	</div>
</template>

<script>
import PageHeader from '../components/PageHeader'
import PageBody from '../components/PageBody'

export default {
	name: 'AdminProfilePage',
	components: {
		PageHeader,
		PageBody,
	},
}
</script>

<style scoped></style>