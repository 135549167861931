<template>
	<div class="flex flex-col">
		<PageHeader
			headline="Händler"
			description="Übersicht über die eingetragenen cybo-Händler"
		></PageHeader>

		<PageBody class="pt-4">
			<div v-if="reseller" class="flex flex-row">
				<div class="flex-1"></div>

				<div>
					<PageCard title="Aktive Kunden:">
						<div class="text-3xl text-right">
							{{ reseller.numCustomersActive }}
						</div>
					</PageCard>

					<PageCard title="Aktive Boxen:">
						<div class="text-3xl text-right">
							{{ reseller.numBoxesActive }}
						</div>
					</PageCard>

					<PageCard title="Aktivierungsschlüssel:">
						<div class="text-2xl text-center text-black font-mono">
							{{ reseller.resellerKey }}
						</div>
					</PageCard>
				</div>

				<PageCard title="Händlerdaten">
					<div class="flex flex-row">
						<div class="flex-1 flex-col text-right font-bold">
							<div class="p-2 h-12">Kontostatus</div>
							<div class="p-2">Händlernummer</div>
							<div class="p-2">Geschäftsname</div>
							<div class="p-2">E-Mail-Adresse</div>
							<div class="p-2">Telefonnummer</div>
							<div class="p-2">Registrierungsdatum</div>
							<div class="p-2">Anschrift</div>
						</div>

						<div class="flex-1 flex-col text-left text-black">
							<div class="p-2 h-12">
								<BadgeSuccess
									v-if="reseller.state === 'active'"
									text="aktiv"
								></BadgeSuccess>
								<BadgeError v-else text="unbekannt"></BadgeError>
							</div>
							<div class="p-2">
								{{ reseller.resellerId }}
							</div>
							<div class="p-2">
								{{ reseller.companyName }}
							</div>
							<div class="p-2">
								{{ reseller.email }}
							</div>
							<div class="p-2">
								{{ reseller.contactPerson.phoneNumber }}
							</div>
							<div class="p-2">
								{{
									$utils.date2timeString(new Date(reseller.created)) +
									' ' +
									$utils.date2dateString(new Date(reseller.created))
								}}
							</div>
							<div class="p-2">
								{{
									reseller.address.streetName +
									' ' +
									reseller.address.streetNumber
								}}
								<br />
								{{ reseller.address.zipCode + ' ' + reseller.address.cityName }}
								<br />
								{{ reseller.address.countryName }}
							</div>
						</div>
					</div>
				</PageCard>

				<div>
					<PageCard title="Konto deaktivieren">
						<div class="flex flex-row">
							<div class="flex-1"></div>
							<CustomButton text="deaktivieren"></CustomButton>
							<div class="flex-1"></div>
						</div>
					</PageCard>
				</div>

				<div class="flex-1"></div>
			</div>
		</PageBody>

		<LoadingScreen :has-loaded="hasLoaded"></LoadingScreen>
	</div>
</template>

<script>
import PageHeader from '../components/PageHeader'
import PageBody from '../components/PageBody'

import LoadingScreen from '../components/LoadingScreen'
import PageCard from '../components/PageCard'
import BadgeSuccess from '../components/badges/BadgeSuccess'
import BadgeError from '../components/badges/BadgeError'
import CustomButton from '../components/inputs/CustomButton'

export default {
	name: 'AdminResellerPage',
	components: {
		CustomButton,
		BadgeError,
		BadgeSuccess,
		PageCard,
		LoadingScreen,
		PageBody,
		PageHeader,
	},
	data() {
		return {
			resellerId: undefined,
			reseller: undefined,
			loaded: {
				resellerInfo: false,
			},
		}
	},
	computed: {
		hasLoaded: function () {
			return this.loaded.resellerInfo
		},
	},
	created() {
		this.resellerId = this.$route.params.identifier
		this.getResellerInfo(this.resellerId)
	},
	methods: {
		getResellerInfo: function (resellerId) {
			this.loaded.resellerInfo = false

			let context = this
			this.$http
				.post('panel/admin/getResellerInfo', {
					resellerId: resellerId,
				})
				.then((response) => {
					this.loaded.resellerInfo = true

					const status = response.data.status.value
					if (response.status === 200 && status === 100) {
						context.reseller = response.data.body
					}
				})
				.catch((err) => {
					console.log(err)
					this.loaded.resellerInfo = true
				})
		},
	},
}
</script>

<style scoped></style>