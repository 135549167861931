<template>
	<button
		type="button"
		:title="tooltip"
		class="rounded-3xl cursor-pointer min-w-7 h-7 hover:shadow-button_hover duration-200"
		:class="customClass"
		@click="onClick"
	>
		<div v-if="loading" class="w-7">
			<svg
				class="animate-spin h-5 w-5 text-white mx-auto"
				xmlns="http://www.w3.org/2000/svg"
				fill="none"
				viewBox="0 0 24 24"
			>
				<circle
					class="opacity-25"
					cx="12"
					cy="12"
					r="10"
					stroke="currentColor"
					stroke-width="4"
				></circle>
				<path
					class="opacity-75"
					fill="currentColor"
					d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
				></path>
			</svg>
		</div>
		<div v-else class="flex flex-row">
			<img :src="icon" alt="" class="w-7" />
			<div v-if="text" class="text-white text-xs pt-1.5 pr-4">
				{{ text }}
			</div>
		</div>
	</button>
</template>

<script>
export default {
	name: 'CustomButtonIcon',
	props: {
		icon: {
			type: String,
			required: true,
		},
		tooltip: {
			type: String,
			default: '',
		},
		loading: {
			type: Boolean,
			default: false,
		},
		text: {
			type: String,
			default: '',
		},
		bgColor: {
			type: String,
			default: '',
		},
	},
	computed: {
		customClass: function () {
			if (this.bgColor) {
				return '' + this.bgColor
			}
			return 'bg-navfg_light'
		},
	},
	methods: {
		onClick: function () {
			if (this.loading) {
				return
			}
			this.$emit('click')
		},
	},
}
</script>

<style scoped></style>