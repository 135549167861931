<template>
	<div class="flex flex-1 flex-col h-screen">
		<PageBody no-margin class="flex flex-col">
			<!-- WAIT FOR IDENTIFIER VALIDATION -->
			<div v-if="isValidState === 0" class="flex flex-row">
				<div class="flex-1"></div>

				<PageCard title="Verifikation im Gange">
					<div>
						<img alt="loading" :src="this.$icons.loadingGif" class="w-10 mx-auto" />
					</div>
					<div class="text-center">Bitte warten..</div>
				</PageCard>

				<div class="flex-1"></div>
			</div>

			<!-- SET EMAIL -->
			<div v-else-if="isValidState === 1" class="flex flex-col lg:flex-row">
				<div class="flex-1"></div>

				<div class="flex flex-col">
					<img
						alt="cybo-logo"
						:src="this.$icons.cyboLogoWhiteSlim"
						class="max-h-20 mt-5"
					/>
					<div class="mb-5 text-white font-bold">Serviceportal</div>

					<PageCard title="Änderungsantrag für Ihre Zugangsdaten" class="w-96 mx-auto">
						<div class="text-sm text-justify">
							Im Folgenden können Sie die Zugangsdaten für Ihr Benutzerkonto ändern.
						</div>
					</PageCard>
				</div>

				<PageCard title="E-Mail-Adresse neu setzen" class="w-96">
					<div class="text-sm font-bold mt-3 mb-2">Ihre bisherige E-Mail-Adresse</div>
					<div class="text-sm pb-3">
						<div class="pl-2 pb-1 pt-2 text-xs">E-Mail-Adresse</div>
						<div class="pl-2 text-black">
							{{ email }}
						</div>
					</div>

					<div class="text-sm font-bold mt-3 mb-2">Ihre neue E-Mail-Adresse</div>

					<div class="text-sm text-justify">
						Bitte geben Sie die neue E-Mail-Adresse für Ihr Benutzerkonto ein.
					</div>
					<div class="mt-3">
						<CustomForm ref="resetEmailForm">
							<CustomInput
								type="text"
								placeholder="E-Mail-Adresse"
								:value="emailObj"
								:rules="[$rules.required, $rules.isEmail, $rules.lengthBelow50]"
							></CustomInput>
							<CustomInput
								type="text"
								placeholder="E-Mail-Adresse wiederholen"
								class="mt-3"
								:value="emailBackupObj"
								:rules="[$rules.required]"
							></CustomInput>
						</CustomForm>
					</div>
					<div class="flex flex-row mt-10">
						<div class="flex-1"></div>
						<CustomButton
							text="E-Mail ändern"
							:loading="isLoading"
							@click="resetEmail"
						></CustomButton>
					</div>
				</PageCard>

				<div class="flex-1"></div>
			</div>

			<!-- PAGE NOT AVAILABLE -->
			<div v-else>
				<OnetimepageNotAvailable></OnetimepageNotAvailable>
			</div>

			<div class="flex flex-1"></div>

			<div class="text-white text-sm py-4">
				Copyright © 2022 NovaNode GmbH |
				<a class="hover:underline cursor-pointer" @click="switchToPrivacyPolicyPage">
					Datenschutzerklärung
				</a>
				|
				<a class="hover:underline cursor-pointer" @click="switchToLegalNoticePage">
					Impressum
				</a>
			</div>
		</PageBody>
	</div>
</template>

<script>
import PageBody from './components/PageBody'
import PageCard from './components/PageCard'

import CustomInput from './components/inputs/CustomInput'
import CustomButton from './components/inputs/CustomButton'
import OnetimepageNotAvailable from './components/onetimepages/OnetimepageNotAvailable'
import CustomForm from './components/inputs/CustomForm'

export default {
	name: 'ResetPasswordPage',
	components: {
		CustomForm,
		OnetimepageNotAvailable,
		PageBody,
		PageCard,
		CustomInput,
		CustomButton,
	},
	data() {
		return {
			isLoading: false,
			identifier: undefined,
			isValidState: 0,
			email: undefined,
			emailObj: {
				text: '',
				errorMessage: '',
			},
			emailBackupObj: {
				text: '',
				errorMessage: '',
			},
		}
	},
	created() {
		this.identifier = this.$route.params.identifier
		this.validateIdentifier(this.identifier)
	},
	methods: {
		resetErrors: function () {
			this.emailObj.errorMessage = ''
			this.emailBackupObj.errorMessage = ''
		},
		validateIdentifier: function (identifier) {
			let context = this
			this.$http
				.post('/panel/auth/validatePageIdentifier', {
					identifier: identifier,
				})
				.then((response) => {
					const status = response.data.status.value
					if (response.status === 200 && status === 100) {
						context.isValidState = 1
						context.email = response.data.body.email
					} else {
						context.isValidState = 2
					}
				})
				.catch((err) => {
					context.isValidState = 2
					console.log(err)
				})
		},
		resetEmail: function () {
			if (!this.$refs['resetEmailForm'].validate()) {
				return
			}

			if (this.emailObj.text !== this.emailBackupObj.text) {
				this.emailBackupObj.errorMessage = 'E-Mail-Adressen stimmen nicht überein'
				return
			}

			const context = this
			context.isLoading = true

			context.$http
				.post('/panel/auth/resetEmail', {
					identifier: this.identifier,
					email: this.email,
					emailNew: this.emailObj.text,
				})
				.then((response) => {
					const status = response.data.status.value
					if (response.status === 200 && status === 100) {
						context.$toast.success(
							'E-Mail-Adresse erfolgreich geändert',
							context.$toaststyle.successMid
						)

						context.$router.push('/login').catch(() => {
							// required for router to not throw exception
						})
						return
					}

					switch (status) {
						case 1001:
						case 1002:
							context.emailObj.errorMessage = 'Eingabe ungültig'
							break
						case 1003:
							context.emailObj.errorMessage = 'E-Mail-Adresse wird bereits verwendet'
							context.$toast.error(
								'Bitte überprüfen Sie die Eingaben',
								context.$toaststyle.errorShort
							)
							break
						case 1008:
							context.emailObj.errorMessage = 'Diese E-Mail-Adresse existiert nicht'
							context.$toast.error(
								'Bitte überprüfen Sie die Eingaben',
								context.$toaststyle.errorShort
							)
							break
						default:
							context.$toast.error(
								'Ein Fehler ist aufgetreten',
								context.$toaststyle.errorShort
							)
							break
					}
				})
				.catch((err) => {
					console.log(err)

					context.$toast.error(
						'Ein Fehler ist aufgetreten',
						context.$toaststyle.errorShort
					)
				})
				.finally(() => {
					context.isLoading = false
				})
		},
		switchToPrivacyPolicyPage: function () {
			const routeData = this.$router.resolve({
				name: 'privacyPolicyPage',
				params: {
					from: 'index',
				},
			})

			window.open(routeData.href, '_blank')
		},
		switchToLegalNoticePage: function () {
			const routeData = this.$router.resolve({
				name: 'legalNoticePage',
				params: {
					from: 'index',
				},
			})

			window.open(routeData.href, '_blank')
		},
	},
}
</script>

<style scoped></style>