<template>
	<div class="flex flex-col">
		<PageHeader headline="Developer Key erstellen"></PageHeader>

		<PageBody class="pt-4">
			<div class="flex flex-row">
				<div class="flex-1"></div>

				<PageCard v-if="state === 0" title="Neuen Developer Key erstellen">
					<div>Gewünschten Aktivierungsschlüssel eingeben</div>
					<div>(min. 5 Zeichen und nur a-z, 0-9)</div>

					<div class="w-96 mx-auto justify-center">
						<div class="py-3 my-3">
							<CustomInput
								v-model="keyObj"
								placeholder="Aktivierungsschlüssel"
								type="text"
								class="mx-auto mt-3"
							></CustomInput>
						</div>
					</div>

					<div class="flex flex-row mt-10">
						<div class="flex-1"></div>

						<CustomButton
							text="Erstellen"
							default-width
							:loading="isLoading"
							@click="addDeveloperKey"
						></CustomButton>
					</div>
				</PageCard>

				<PageCard v-else title="Hinweis">
					<div class="mt-2">
						<img :src="this.$icons.success" class="w-12 mx-auto" />
					</div>
					<div class="mt-4">Neuer Developer Key wurde erfolgreich angelegt.</div>

					<div class="my-auto mx-20 mt-20">
						<CustomButton
							v-if="state === 1"
							text="Zur Übersicht"
							default-width
							@click="switchToDeveloperKeysPage"
						></CustomButton>
					</div>
				</PageCard>

				<div class="flex-1"></div>
			</div>
		</PageBody>
	</div>
</template>

<script>
import PageHeader from '../components/PageHeader'
import PageBody from '../components/PageBody'
import PageCard from '../components/PageCard'

import CustomButton from '../components/inputs/CustomButton'
import CustomInput from '../components/inputs/CustomInput'

export default {
	name: 'AdminAddDeveloperKeyPage',
	components: {
		CustomInput,
		CustomButton,
		PageCard,
		PageBody,
		PageHeader,
	},
	data() {
		return {
			state: 0,
			isLoading: false,
			keyObj: {
				text: '',
				errorMessage: '',
			},
		}
	},
	methods: {
		addDeveloperKey: function () {
			this.keyObj.errorMessage = ''

			if (this.keyObj.text.length < 5) {
				this.keyObj.errorMessage = 'Zu kurz'
				return
			}

			this.isLoading = true

			let context = this

			this.$http
				.post('/panel/admin/addDeveloperKey', {
					key: context.keyObj.text,
				})
				.then((response) => {
					context.isLoading = false
					console.log(response)

					const status = response.data.status.value
					if (response.status === 200 && status === 100) {
						context.keyObj.text = ''
						context.keyObj.errorMessage = ''
						context.state = 1
					} else if (status === 407) {
						context.keyObj.errorMessage = 'Key bereits in Verwendung'
					}
				})
				.catch((err) => {
					context.isLoading = false
					console.log(err)
				})
		},
		switchToDeveloperKeysPage: function () {
			this.$router.push({
				path: '/admin/developerkeys',
			})
		},
	},
}
</script>

<style scoped></style>