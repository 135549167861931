<template>
	<PageCard title="" class="relative">
		<div class="text-center">
			<div v-if="boxList.length > 0" class="bg-listbg_dark rounded-3xl px-4 py-4">
				<div class="flex flex-row text-list_textheader_dark h-9 text-xs">
					<div class="w-48 h-full py-1 font-bold">Status</div>
					<div class="w-40 h-full py-1 text-left pl-4 font-bold">Boxnummer</div>
					<div class="w-40 h-full py-1 text-left pl-4 font-bold">Kundennummer</div>
					<div class="flex-1 h-full py-1 text-left pl-4 font-bold">E-Mail</div>
					<div
						class="w-24 h-full py-1 text-left pl-4 font-bold cursor-pointer hover:underline"
						@click="sortBy('assigned')"
					>
						Zeit
					</div>
					<div
						class="w-28 flex flex-row h-full py-1 font-bold text-left pl-4 cursor-pointer hover:underline"
						@click="sortBy('assigned')"
					>
						<div class="flex-1"></div>
						<div class="flex-1">Datum</div>
						<div v-if="sort.column === 'assigned'" class="flex-1 pl-4 pt-1">
							<img
								v-if="sort.ascending"
								:src="this.$icons.arrowUp"
								alt=""
								class="h-4"
							/>
							<img v-else :src="this.$icons.arrowDown" alt="" class="h-4" />
						</div>
					</div>
					<div class="flex-1 h-full py-1 font-bold"></div>
				</div>

				<div>
					<!-- click method is not on this elem so that the button click does not trigger it -->
					<div
						v-for="box in filteredBoxList"
						:key="box.id"
						class="flex flex-row text-list_text_dark rounded-3xl hover:bg-white text-xs"
					>
						<div class="w-48 h-full py-3 pl-4 border-t">
							<BadgePending text="nicht zugewiesen"></BadgePending>
						</div>
						<div class="w-40 h-full py-3 text-left pl-4 border-t">
							{{ box.boxId }}
						</div>
						<div class="w-40 h-full py-3 text-left pl-4 border-t">
							{{ box.customerId }}
						</div>
						<div class="flex-1 h-full py-3 text-left pl-4 border-t">
							{{ box.customerEmail }}
						</div>
						<div class="w-24 h-full py-3 text-left pl-4 border-t">
							{{ $utils.date2timeString(new Date(box.assigned)) }}
						</div>
						<div class="w-28 h-full py-3 text-left pl-4 border-t">
							{{ $utils.date2dateString(new Date(box.assigned)) }}
						</div>
						<div class="flex-1 h-full py-2 border-t">
							<CustomButton
								text="löschen"
								@click="initDeletePending(box.id)"
							></CustomButton>
						</div>
					</div>
				</div>
			</div>

			<div v-else class="bg-listbg_dark rounded-3xl px-4 py-4 text-list_text_dark text-xs">
				Aktuell gibt es keine Boxen mit ausstehender Geschäftszuweisung durch den Kunden
			</div>
		</div>

		<CustomPagination
			v-if="page.max > 1"
			:current-page-num="page.current"
			:max-page-num="page.max"
			class="mt-4"
			@previous="previousPage"
			@next="nextPage"
		></CustomPagination>

		<ConfirmPopup
			v-show="toDelete.toConfirm"
			title="Box löschen"
			text="Diese Boxzuweisung wirklich widerrufen?"
			:loading="toDelete.isLoading"
			@cancel="cancelDeletePending"
			@confirm="deletePending"
		></ConfirmPopup>
	</PageCard>
</template>

<script>
import PageCard from '../../components/PageCard'
import BadgePending from '../../components/badges/BadgePending'
import CustomButton from '../../components/inputs/CustomButton'
import ConfirmPopup from '../../components/popup/ConfirmPopup'
import CustomPagination from '../../components/CustomPagination'

export default {
	name: 'BoxPendingList',
	components: {
		CustomPagination,
		ConfirmPopup,
		CustomButton,
		PageCard,
		BadgePending,
	},
	props: {
		loaded: {
			type: Object,
			default() {
				return {
					boxPending: false,
				}
			},
		},
	},
	data() {
		return {
			toDelete: {
				toConfirm: false,
				boxId: undefined,
				isLoading: false,
			},
			sort: {
				column: 'assigned',
				ascending: false,
			},
			page: {
				entriesPerPage: 10,
				current: 1,
				max: 1,
			},
			toConfirmDelete: false,
			boxList: [],
		}
	},
	computed: {
		filteredBoxList: function () {
			let tmp = this.boxList

			let sortColumn = 'assigned'
			if (this.sort.column in tmp[0]) {
				sortColumn = this.sort.column
			}

			tmp.sort((a, b) => {
				if (a[sortColumn] < b[sortColumn]) {
					return -1
				} else if (a[sortColumn] > b[sortColumn]) {
					return 1
				}
				return 0
			})

			if (!this.sort.ascending) {
				tmp.reverse()
			}

			let numPages =
				Math.trunc(tmp.length / this.page.entriesPerPage) +
				(tmp.length % this.page.entriesPerPage === 0 ? 0 : 1)

			this.updatePages(numPages)

			const indexStart = (this.page.current - 1) * this.page.entriesPerPage
			let indexStop = indexStart + this.page.entriesPerPage
			if (indexStop > tmp.length) {
				indexStop = tmp.length
			}

			tmp = tmp.slice(indexStart, indexStop)

			return tmp
		},
	},
	created() {
		this.getValidCustomerList()
	},
	methods: {
		previousPage: function () {
			if (this.page.current <= 1) {
				return
			}
			this.page.current -= 1
		},
		nextPage: function () {
			if (this.page.current >= this.page.max) {
				return
			}
			this.page.current += 1
		},
		sortBy: function (column) {
			if (column === this.sort.column) {
				this.sort.ascending = !this.sort.ascending
			} else {
				this.sort.column = column
				this.sort.ascending = false
			}
		},
		getValidCustomerList: function () {
			this.loaded.boxPending = false

			let context = this
			this.$http
				.get('/panel/reseller/getPendingBoxList')
				.then((response) => {
					context.loaded.boxPending = true

					const status = response.data.status.value
					if (response.status === 200 && status === 100) {
						context.boxList = response.data.body
					}
				})
				.catch((err) => {
					console.log(err)
				})
		},
		initDeletePending: function (boxId) {
			this.toDelete.toConfirm = true
			this.toDelete.boxId = boxId
		},
		cancelDeletePending: function () {
			this.toDelete.toConfirm = false
			this.toDelete.boxId = undefined
			this.toDelete.isLoading = false
		},
		deletePending: function () {
			this.toDelete.isLoading = true

			let context = this
			this.$http
				.post('/panel/reseller/cancelPendingBoxAssignment', {
					boxId: this.toDelete.boxId,
				})
				.then((response) => {
					context.cancelDeletePending()

					const status = response.data.status.value
					if (response.status === 200 && status === 100) {
						context.getValidCustomerList()

						context.$toast.success(
							'Die cybo-box wurde entfernt',
							context.$toaststyle.successMid
						)
					} else {
						context.$toast.error(
							'Ein Fehler ist aufgetreten',
							context.$toaststyle.errorShort
						)
						console.log(response.data)
					}
				})
				.catch((err) => {
					console.log(err)

					context.$toast.error(err.message, context.$toaststyle.errorMid)
				})
				.finally(() => {
					context.cancelDeletePending()
				})
		},
		updatePages: function (maxPage) {
			this.page.max = maxPage
		},
	},
}
</script>

<style scoped></style>