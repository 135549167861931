<template>
	<div class="flex flex-1 flex-col h-screen">
		<PageBody no-margin class="flex flex-col">
			<!-- WAIT FOR IDENTIFIER VALIDATION -->
			<div v-if="isValidState === 0" class="flex flex-row">
				<div class="flex-1"></div>

				<PageCard title="Verifikation im Gange">
					<div>
						<img alt="loading" :src="this.$icons.loadingGif" class="w-10 mx-auto" />
					</div>
					<div class="text-center">Bitte warten..</div>
				</PageCard>

				<div class="flex-1"></div>
			</div>

			<!-- SET PASSWORD -->
			<div v-else-if="isValidState === 1" class="flex flex-col lg:flex-row">
				<div class="flex-1"></div>

				<div class="flex flex-col">
					<img
						alt="cybo-logo"
						:src="this.$icons.cyboLogoWhiteSlim"
						class="max-h-20 mt-5"
					/>
					<div class="mb-5 text-white font-bold">Serviceportal</div>

					<PageCard title="Herzlich willkommen bei cybo!" class="w-96 mx-auto">
						<div class="text-sm text-justify">
							Im Folgenden wird Ihre Kontoaktivierung abgeschlossen und Sie können das
							cybo-Serviceportal im vollen Umfang nutzen.
							<br />
							<br />
							Viele Grüße,
							<br />
							Ihr cybo-Team
						</div>
					</PageCard>
				</div>

				<PageCard title="Erstmalige Kontoeinrichtung" class="w-card_large mb-20 mx-auto">
					<div class="text-sm font-bold mt-3 mb-2">Ihre E-Mail-Adresse</div>

					<div class="text-sm text-justify">
						Wird zum Anmelden anstelle eines Benutzernamens benötigt. Bei Bedarf können
						Sie die E-Mail-Adresse später über Ihre Profilseite ändern.
					</div>

					<div class="text-sm pt-5 pb-3">
						<div class="pl-2 pb-1 text-xs">E-Mail-Adresse</div>
						<div class="pl-2 text-black">
							{{ emailObj.text }}
						</div>
					</div>

					<div class="flex flex-row text-sm font-bold mt-3 mb-2">Ihr Passwort</div>

					<div class="text-sm text-justify">
						Bitte vergeben Sie ein sicheres Passwort für Ihr neues Benutzerkonto. Es
						darf keine Leerzeichen enthalten und muss aus mindestens 10 Zeichen
						bestehen.
					</div>

					<div class="mt-4">
						<CustomForm ref="activateAccountPasswordForm">
							<CustomInput
								v-model="passwordObj"
								type="password"
								placeholder="Neues Passwort"
								class="mx-auto"
								:rules="[
									$rules.required,
									$rules.lengthAbove10,
									$rules.noSpaces,
									$rules.lengthBelow50,
								]"
							></CustomInput>
							<CustomInput
								v-model="passwordBackupObj"
								type="password"
								placeholder="Passwort wiederholen"
								class="mx-auto mt-4"
								:rules="[$rules.required]"
							></CustomInput>
						</CustomForm>
					</div>

					<div class="text-sm font-bold mt-6 mb-2">
						Zwei-Faktor-Authentifizierung (2FA)
					</div>

					<div class="text-sm text-justify">
						Als zusätzlichen Schutz für Ihr Konto bieten wir eine 2FA an. Es wird dann
						bei jeder Anmeldung im Serviceportal ein zusätzlicher Verifikationscode
						benötigt.
					</div>

					<div class="mt-3">
						<CustomDropdown
							class="mx-auto"
							:data="twoFactorOptionsObj"
						></CustomDropdown>

						<div v-if="twoFactorOptionsObj.selected === 'EMAIL'" class="text-sm">
							<div class="mt-4 text-sm text-justify">
								Bei jeder Anmeldung im Serviceportal wird Ihnen der
								Verifikationscode an Ihre hinterlegte E-Mail-Adresse gesendet.
							</div>
						</div>

						<div
							v-else-if="
								twoFactorOptionsObj.selected === 'GOOGLE_AUTHENTICATOR' ||
								twoFactorOptionsObj.selected === 'MICROSOFT_AUTHENTICATOR'
							"
							class="text-sm"
						>
							<div class="mt-4 text-sm text-justify">
								Scannen Sie diesen QR-Code mit der
								{{
									twoFactorOptionsObj.selected === 'GOOGLE_AUTHENTICATOR'
										? 'Google'
										: 'Microsoft'
								}}
								Authenticator App:
							</div>
							<div class="flex flex-row mt-4">
								<div class="flex-1"></div>

								<img v-if="qrCodeLink" :src="qrCodeLink" />
								<div v-else class="text-red-700">
									Fehler:<br />
									QR-Code konnte nicht geladen werden
								</div>

								<div class="flex-1"></div>
							</div>
							<div>
								<div class="mt-4 text-xs text-justify">
									(Wenden Sie sich bitte an info@cybo.de falls auch nach kurzer
									Zeit kein QR-Code angezeigt wird)
								</div>
								<CustomForm ref="accountActivation2faForm">
									<div class="mt-4 text-sm text-justify">
										Bitte geben Sie den Verifikationscode aus der App ein, um
										die Aktivierung abzuschließen:
									</div>
									<CustomInput
										v-model="twoFactorObj"
										type="text"
										placeholder="Verifikationscode"
										class="mx-auto mt-4"
										:rules="[$rules.required]"
									></CustomInput>
								</CustomForm>
							</div>
						</div>
					</div>

					<div class="flex flex-row mt-10">
						<div class="flex-1"></div>
						<CustomButton
							text="Konto aktivieren"
							:loading="isLoading"
							@click="setPassword"
						></CustomButton>
					</div>
				</PageCard>

				<div class="flex-1"></div>
			</div>

			<!-- PAGE NOT AVAILABLE -->
			<div v-else>
				<OnetimepageNotAvailable></OnetimepageNotAvailable>
			</div>

			<div class="flex flex-1"></div>

			<div class="text-white text-sm py-4">
				Copyright © 2022 NovaNode GmbH |
				<a class="hover:underline cursor-pointer" @click="switchToPrivacyPolicyPage">
					Datenschutzerklärung
				</a>
				|
				<a class="hover:underline cursor-pointer" @click="switchToLegalNoticePage">
					Impressum
				</a>
			</div>
		</PageBody>
	</div>
</template>

<script>
import PageBody from './components/PageBody'
import PageCard from './components/PageCard'

import CustomInput from './components/inputs/CustomInput'
import CustomButton from './components/inputs/CustomButton'
import CustomDropdown from './components/inputs/CustomDropdown'
import CustomForm from './components/inputs/CustomForm'
import OnetimepageNotAvailable from './components/onetimepages/OnetimepageNotAvailable'

export default {
	name: 'AccountActivationPage',
	components: {
		OnetimepageNotAvailable,
		CustomForm,
		CustomDropdown,
		PageBody,
		PageCard,
		CustomInput,
		CustomButton,
	},
	data() {
		return {
			isLoading: false,
			isValidState: 0,
			identifier: undefined,
			qrCodeLink: undefined,
			emailObj: {
				text: '',
				errorMessage: '',
				disabled: true,
			},
			passwordObj: {
				text: '',
				errorMessage: '',
			},
			passwordBackupObj: {
				text: '',
				errorMessage: '',
			},
			twoFactorObj: {
				text: '',
				errorMessage: '',
			},
			twoFactorOptionsObj: {
				selected: 'NONE',
				options: [
					{ text: 'Keine 2FA benutzen', value: 'NONE' },
					{ text: 'Google Authenticator', value: 'GOOGLE_AUTHENTICATOR' },
					{ text: 'Microsoft Authenticator', value: 'MICROSOFT_AUTHENTICATOR' },
					{ text: 'E-Mail', value: 'EMAIL' },
				],
			},
		}
	},
	created() {
		this.identifier = this.$route.params.identifier
		this.validateIdentifier(this.identifier)
	},
	methods: {
		validateIdentifier: function (identifier) {
			let context = this
			this.$http
				.post('/panel/auth/validatePageIdentifier', {
					identifier: identifier,
				})
				.then((response) => {
					const status = response.data.status.value
					if (response.status === 200 && status === 100) {
						context.emailObj.text = response.data.body.email
						context.qrCodeLink = response.data.body.qrCodeLink
						context.isValidState = 1
					} else {
						context.isValidState = 2
					}
				})
				.catch((err) => {
					context.isValidState = 2
					console.log(err)
				})
		},
		setPassword: async function () {
			const context = this

			context.isLoading = true

			// validates the password fields for input errors
			if (!context.$refs['activateAccountPasswordForm'].validate()) {
				context.$toast.error(
					'Bitte prüfen Sie Ihre Eingaben',
					context.$toaststyle.errorShort
				)
				context.isLoading = false
				return
			}

			// checks if the two entered passwords are the same
			if (context.passwordObj.text !== context.passwordBackupObj.text) {
				context.passwordBackupObj.errorMessage = 'Passwörter stimmen nicht überein'

				context.$toast.error(
					'Bitte prüfen Sie Ihre Eingaben',
					context.$toaststyle.errorShort
				)
				context.isLoading = false
				return
			}

			// validates the 2FA verification code form if google or microsoft authenticator was selected
			if (
				context.twoFactorOptionsObj.selected === 'GOOGLE_AUTHENTICATOR' ||
				context.twoFactorOptionsObj.selected === 'MICROSOFT_AUTHENTICATOR'
			) {
				if (!context.$refs['accountActivation2faForm'].validate()) {
					context.$toast.error(
						'Bitte prüfen Sie Ihre Eingaben',
						context.$toaststyle.errorShort
					)
					context.isLoading = false
					return
				}

				try {
					let response = await context.$http.post('/panel/auth/validateTwoFactorCode', {
						pageIdentifier: context.identifier,
						code: context.twoFactorObj.text,
					})

					const status = response.data.status.value

					switch (status) {
						case 100:
							// 2FA code was valid
							break
						case 909:
							context.isLoading = false
							context.$toast.error(
								'Bitte prüfen Sie Ihre Eingaben',
								context.$toaststyle.errorShort
							)
							context.twoFactorObj.errorMessage = 'Ungültige Eingabe'
							return
						default:
							context.isLoading = false
							context.$toast.error(
								'Ein Fehler ist aufgetreten',
								context.$toaststyle.errorShort
							)
							console.log(response.data)
							return
					}
				} catch (err) {
					console.log(err)

					context.isLoading = false
					context.$toast.error(
						'Ein Fehler ist aufgetreten',
						context.$toaststyle.errorShort
					)
					return
				}
			}

			// sends the entered data to the server and activates the account
			context.$http
				.post('/panel/auth/activateAccount', {
					identifier: context.identifier,
					password: context.passwordObj.text,
					twoFactor: context.twoFactorOptionsObj.selected,
				})
				.then((response) => {
					const status = response.data.status.value

					if (response.status === 200 && status === 100) {
						context.$router.push('/login').catch(() => {})

						context.$toast.success(
							'Konto erfolgreich aktiviert',
							context.$toaststyle.successMid
						)
						return
					}

					switch (status) {
						case 1004:
						case 1005:
							context.passwordObj.errorMessage = 'Passwort ungültig'
							context.passwordBackupObj.errorMessage = 'Passwort ungültig'
							break
						default:
							context.$toast.error(
								'Ein Fehler ist aufgetreten',
								context.$toaststyle.errorShort
							)
							console.log(response.data)
							break
					}
				})
				.catch((err) => {
					console.log(err)

					context.$toast.error(
						'Ein Fehler ist aufgetreten',
						context.$toaststyle.errorShort
					)
				})
				.finally(() => {
					context.isLoading = false
				})
		},
		switchToPrivacyPolicyPage: function () {
			const routeData = this.$router.resolve({
				name: 'privacyPolicyPage',
				params: {
					from: 'index',
				},
			})

			window.open(routeData.href, '_blank')
		},
		switchToLegalNoticePage: function () {
			const routeData = this.$router.resolve({
				name: 'legalNoticePage',
				params: {
					from: 'index',
				},
			})

			window.open(routeData.href, '_blank')
		},
	},
}
</script>

<style scoped></style>