<template>
	<div class="flex flex-1 flex-row">
		<div
			id="owner-navbar"
			class="fixed left-0 top-0 group z-30 flex flex-col bg-navbg_light h-full w-20 hover:w-80 rounded-br-nav rounded-tr-nav shadow-nav"
			@mouseover="hoverOn"
			@mouseleave="hoverOff"
		>
			<NavbarLogoItem></NavbarLogoItem>

			<NavbarItem
				:active="mainRoute === 'home'"
				text="Startseite"
				:image="this.$icons.home"
				:image-active="this.$icons.homeActive"
				@selected="changeToHomePage"
			></NavbarItem>

			<NavbarItem
				:active="mainRoute === 'bons'"
				text="Bonarchiv"
				:image="this.$icons.files"
				:image-active="this.$icons.filesActive"
				@selected="changeToBonListPage"
			></NavbarItem>

			<NavbarItem
				:active="mainRoute === 'boxes' || mainRoute === 'addBox'"
				text="Boxübersicht"
				:image="this.$icons.box"
				:image-active="this.$icons.boxActive"
				@selected="changeToBoxListPage"
			></NavbarItem>

			<!--
			<NavbarSubItem
				v-show="hoverItem"
				text="Neue Box zuweisen"
				:active="mainRoute === 'addBox'"
				:image="this.$icons.add"
				:image-active="this.$icons.addActive"
				@selected="changeToAddBoxPage"
			></NavbarSubItem>
			-->

			<NavbarItem
				:active="mainRoute === 'shops' || mainRoute === 'addShop'"
				text="Geschäfteübersicht"
				:image="this.$icons.shop2"
				:image-active="this.$icons.shop2Active"
				@selected="changeToShopPage"
			></NavbarItem>

			<NavbarSubItem
				v-show="hoverItem"
				text="Geschäft hinzufügen"
				:active="mainRoute === 'addShop'"
				:image="this.$icons.add"
				:image-active="this.$icons.addActive"
				@selected="changeToAddShopPage"
			></NavbarSubItem>

			<div class="flex-1"></div>

			<NavbarItem
				:active="mainRoute === 'profile'"
				text="Profil"
				:image="this.$icons.user"
				:image-active="this.$icons.userActive"
				@selected="changeToProfilePage"
			></NavbarItem>

			<NavbarItem
				:active="false"
				class="mb-5"
				text="Ausloggen"
				:image="this.$icons.logout"
				:image-active="this.$icons.logoutActive"
				@selected="logout"
			></NavbarItem>

			<div class="text-xs text-navfg_light w-80 h-16 overflow-hidden">
				<div v-if="isHovering">
					<div class="hover:underline cursor-pointer" @click="changeToImpressum">
						Impressum
					</div>
					<div class="hover:underline cursor-pointer" @click="changeToPrivacyPolicy">
						Datenschutzerklärung
					</div>
					<div>Copyright © 2022 NovaNode GmbH</div>
				</div>
			</div>
		</div>

		<router-view :key="millis" class="flex-1 min-h-screen"></router-view>
	</div>
</template>

<script>
import NavbarItem from '../components/NavbarItem'
import NavbarLogoItem from '../components/NavbarLogoItem'
import NavbarSubItem from '../components/NavbarSubItem'

export default {
	name: 'CustomerContainerPage',
	components: {
		NavbarSubItem,
		NavbarItem,
		NavbarLogoItem,
	},
	data() {
		return {
			millis: 0,
			mainRoute: '',
			hoverItem: undefined,
			isHovering: false,
		}
	},
	created() {
		this.updateRoutes()
	},
	beforeUpdate() {
		this.updateRoutes()
	},
	methods: {
		hoverOn: function (itemName) {
			this.isHovering = true
			this.hoverItem = itemName
		},
		hoverOff: function () {
			this.isHovering = false
			this.hoverItem = undefined
		},
		updateKey: function () {
			this.millis = new Date().getMilliseconds()
		},
		updateRoutes: function () {
			let route = this.$route.path
			this.mainRoute = route.replace('/customer/', '')
			const indextSlash = this.mainRoute.indexOf('/')
			if (indextSlash > -1) {
				this.mainRoute = this.mainRoute.substr(0, this.mainRoute.indexOf('/'))
			}
		},
		changeToHomePage: function () {
			this.updateKey()
			this.$breadcrumbs.clearHistory()
			this.route = '/customer/home'
			this.$router.push(this.route).catch(() => {})
		},
		changeToBonListPage: function () {
			this.updateKey()
			this.$breadcrumbs.clearHistory()
			this.route = '/customer/bons'
			this.$router.push(this.route).catch(() => {})
		},
		changeToAddBoxPage: function () {
			this.updateKey()
			this.$breadcrumbs.clearHistory()
			this.route = '/customer/addBox'
			this.$router.push(this.route).catch(() => {})
		},
		changeToShopPage: function () {
			this.updateKey()
			this.$breadcrumbs.clearHistory()
			this.route = '/customer/shops'
			this.$router.push(this.route).catch(() => {})
		},
		changeToAddShopPage: function () {
			this.updateKey()
			this.$breadcrumbs.clearHistory()
			this.route = '/customer/addShop'
			this.$router.push(this.route).catch(() => {})
		},
		changeToBoxListPage: function () {
			this.updateKey()
			this.$breadcrumbs.clearHistory()
			this.route = '/customer/boxes'
			this.$router.push(this.route).catch(() => {})
		},
		changeToProfilePage: function () {
			this.updateKey()
			this.$breadcrumbs.clearHistory()
			this.route = '/customer/profile'
			this.$router.push(this.route).catch(() => {})
		},
		logout: function () {
			this.$breadcrumbs.clearHistory()
			this.$store.dispatch('auth/logout', {
				targetRoute: '/login',
			})
		},
		changeToImpressum: function () {
			const currentPageName = this.$route.name

			this.$router.push({
				name: 'legalNoticePage',
				params: {
					from: currentPageName,
				},
			})
		},
		changeToPrivacyPolicy: function () {
			const currentPageName = this.$route.name

			this.$router.push({
				name: 'privacyPolicyPage',
				params: {
					from: currentPageName,
				},
			})
		},
	},
}
</script>

<style scoped>
#owner-navbar {
	transition: all 0.15s;
}
</style>