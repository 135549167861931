<template>
	<div class="flex flex-col">
		<PageHeader headline="Händler"></PageHeader>

		<PageBody class="pt-4">
			<ResellerList
				show-add-button
				:loaded="loaded"
				@clickOnReseller="openResellerPage"
			></ResellerList>
		</PageBody>

		<LoadingScreen :has-loaded="hasLoaded"></LoadingScreen>
	</div>
</template>

<script>
import PageHeader from '../components/PageHeader'
import PageBody from '../components/PageBody'

import ResellerList from './components/ResellerList'

import LoadingScreen from '../components/LoadingScreen'

export default {
	name: 'AdminResellerListPage',
	components: {
		ResellerList,
		LoadingScreen,
		PageHeader,
		PageBody,
	},
	data() {
		return {
			loaded: {
				resellerListActive: false,
			},
			resellerList: [],
			page: {
				entriesPerPage: 10,
				current: 1,
				max: 1,
			},
			sort: {
				column: 'created',
				ascending: false,
			},
		}
	},
	computed: {
		hasLoaded: function () {
			return this.loaded.resellerListActive
		},
	},
	methods: {
		openResellerPage: function (id) {
			this.$router.push({
				path: '/admin/reseller/' + id,
			})
		},
	},
}
</script>

<style scoped></style>