<template>
	<div class="flex flex-col">
		<PageHeader headline="Händler hinzufügen"></PageHeader>

		<PageBody class="pt-4">
			<div class="flex flex-row">
				<div class="flex-1"></div>

				<PageCard v-if="state === 0" title="Neuen cybo-Händler eintragen">
					<div>
						Der Händler erhält eine Email mit weiteren Anweisungen zur Kontoerstellung.
					</div>
					<div>Mit (*) gekennzeichnete Felder sind Pflichtfelder.</div>

					<div class="w-96 mx-auto justify-center">
						<div class="py-3 my-3">
							<CustomInput
								v-model="emailObj"
								placeholder="E-Mail-Adresse*"
								type="text"
								class="mx-auto mt-3"
							></CustomInput>

							<CustomInput
								v-model="phoneNumberObj"
								placeholder="Telefonnummer*"
								type="text"
								class="mx-auto mt-3"
							></CustomInput>

							<CustomInput
								v-model="ddsIdObj"
								placeholder="Interne DDS ID"
								type="text"
								class="mx-auto mt-3"
							></CustomInput>
						</div>

						<div class="pb-3 mb-3">
							<div class="p-2">Kontaktperson</div>
							<CustomInput
								v-model="contactPersonFirstNameObj"
								placeholder="Vorname"
								type="text"
								class="mx-auto mt-3"
							></CustomInput>

							<CustomInput
								v-model="contactPersonLastNameObj"
								placeholder="Nachname"
								type="text"
								class="mx-auto mt-3"
							></CustomInput>
						</div>

						<div class="pb-3 mb-3">
							<div class="p-2">Anschrift des Unternehmens</div>

							<CustomInput
								v-model="companyNameObj"
								placeholder="Firmenname"
								type="text"
								class="mx-auto mt-3"
							></CustomInput>

							<div class="flex flex-row">
								<div class="flex-1 mr-3">
									<CustomInput
										v-model="companyStreetNameObj"
										placeholder="Straße"
										type="text"
										class="mx-auto mt-3"
									></CustomInput>
								</div>
								<div class="w-1/4">
									<CustomInput
										v-model="companyStreetNumberObj"
										placeholder="Nr."
										type="text"
										class="mx-auto mt-3"
									></CustomInput>
								</div>
							</div>

							<div class="flex flex-row">
								<div class="w-1/3">
									<CustomInput
										v-model="companyZipCodeObj"
										placeholder="PLZ"
										type="text"
										class="mt-3"
									></CustomInput>
								</div>
								<div class="flex-1 ml-3">
									<CustomInput
										v-model="companyCityNameObj"
										placeholder="Ort"
										type="text"
										class="mt-3"
									></CustomInput>
								</div>
							</div>
						</div>
					</div>

					<div class="flex flex-row mt-10">
						<div class="flex-1"></div>

						<CustomButton
							text="Hinzufügen"
							default-width
							:loading="isLoading"
							@click="addNewReseller"
						></CustomButton>
					</div>
				</PageCard>

				<PageCard v-else title="Händler erfolgreich hinzugefügt">
					<div>Der Händler erhält eine Email mit weiteren Anweisungen.</div>
					<div class="mt-4">
						<img :src="this.$icons.success" class="w-12 mx-auto" />
					</div>
				</PageCard>

				<div class="flex-1"></div>
			</div>
		</PageBody>

		<PageFooter>
			<div class="my-auto mx-20">
				<CustomButton
					v-if="state === 1"
					text="Neuen Händler hinzufügen"
					default-width
					@click="resetAll"
				></CustomButton>
			</div>
			<div class="flex-1"></div>
			<div class="my-auto mx-20">
				<CustomButton
					v-if="state === 1"
					text="Händlerübersicht"
					default-width
					@click="toResellerList"
				></CustomButton>
			</div>
		</PageFooter>

		<LoadingScreen :has-loaded="hasLoaded"></LoadingScreen>
	</div>
</template>

<script>
import PageHeader from '../components/PageHeader'
import PageBody from '../components/PageBody'
import PageCard from '../components/PageCard'
import PageFooter from '../components/PageFooter'

import CustomInput from '../components/inputs/CustomInput'
import CustomButton from '../components/inputs/CustomButton'
import LoadingScreen from '../components/LoadingScreen'

export default {
	name: 'AdminResellerAddPage',
	components: {
		LoadingScreen,
		PageHeader,
		PageBody,
		PageCard,
		PageFooter,
		CustomInput,
		CustomButton,
	},
	data() {
		return {
			isLoading: false,
			state: 0,
			emailObj: {
				text: '',
				errorMessage: '',
			},
			contactPersonFirstNameObj: {
				text: '',
				errorMessage: '',
			},
			contactPersonLastNameObj: {
				text: '',
				errorMessage: '',
			},
			phoneNumberObj: {
				text: '',
				errorMessage: '',
			},
			companyNameObj: {
				text: '',
				errorMessage: '',
			},
			companyStreetNameObj: {
				text: '',
				errorMessage: '',
			},
			companyStreetNumberObj: {
				text: '',
				errorMessage: '',
			},
			companyZipCodeObj: {
				text: '',
				errorMessage: '',
			},
			companyCityNameObj: {
				text: '',
				errorMessage: '',
			},
			ddsIdObj: {
				text: '',
				errorMessage: '',
			},
		}
	},
	computed: {
		hasLoaded: function () {
			return true
		},
	},
	methods: {
		resetAll: function () {
			this.state = 0
			this.resetErrors()
		},
		resetErrors: function () {
			this.emailObj.errorMessage = ''
			this.phoneNumberObj.errorMessage = ''
		},
		toResellerList: function () {
			this.$router.push({
				path: '/admin/reseller',
			})
		},
		addNewReseller: function () {
			this.isLoading = true
			this.resetErrors()

			let hasError = false

			if (!this.emailObj.text) {
				this.emailObj.errorMessage = 'Eingabe benötigt'
				hasError = true
			}
			if (!this.phoneNumberObj.text) {
				this.phoneNumberObj.errorMessage = 'Eingabe benötigt'
				hasError = true
			}

			if (hasError) {
				this.isLoading = false
				return
			}

			let context = this
			this.$http
				.post('/panel/admin/addNewReseller', {
					email: this.emailObj.text,
					phoneNumber: this.phoneNumberObj.text,
					companyName: this.companyNameObj.text,
					ddsId: this.ddsIdObj.text,
					contactPersonFirstName: this.contactPersonFirstNameObj.text,
					contactPersonLastName: this.contactPersonLastNameObj.text,
					streetName: this.companyStreetNameObj.text,
					streetNumber: this.companyStreetNumberObj.text,
					zipCode: this.companyZipCodeObj.text,
					cityName: this.companyCityNameObj.text,
				})
				.then((response) => {
					context.isLoading = false

					const status = response.data.status.value
					if (response.status === 200 && status === 100) {
						context.state = 1
						return
					}

					switch (status) {
						case 1002:
							context.emailObj.errorMessage = 'Email ist ungültig'
							break
						case 1003:
							context.emailObj.errorMessage = 'Email ist bereits vergeben'
							break
						case 1008:
							context.emailObj.errorMessage = 'Email existiert nicht'
							break
						case 1031:
						case 1032:
							context.phoneNumberObj.errorMessage = 'Eingabe nicht gültig'
							break
						case 1041:
						case 1042:
							context.phoneNumberObj.errorMessage = 'Eingabe nicht gültig'
							break
					}
				})
				.catch((err) => {
					context.isLoading = false
					console.log(err)
				})
		},
	},
}
</script>

<style scoped></style>