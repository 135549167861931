<template>
	<div
		class="relative z-20 text-dd_text_inactive bg-dd_bg_inactive border rounded-3xl h-11 max-w-sm"
	>
		<select
			v-model="data.selected"
			class="absolute top-0 left-0 px-4 h-full w-full z-10 appearance-none focus:outline-none bg-transparent text-black cursor-pointer"
		>
			<option
				v-for="option in data.options"
				:key="option.value"
				:value="option.value"
				class="bg-white"
			>
				{{ option.text }}
			</option>
		</select>
	</div>
</template>

<script>
export default {
	name: 'CustomDropdown',
	props: {
		title: {
			type: String,
			default: '',
		},
		data: {
			type: Object,
			required: true,
		},
	},
}
</script>

<style scoped></style>