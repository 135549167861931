<template>
	<div class="flex flex-col">
		<PageHeader headline="Geschäfteübersicht"></PageHeader>

		<PageBody>
			<PageCard title="Ihre Geschäfte" class="relative z-9 mt-10">
				<div class="absolute top-2 right-4">
					<CustomButtonIcon
						:icon="this.$icons.addActive"
						tooltip="Neues Geschäft anlegen"
						@click="changeToAddShopPage"
					></CustomButtonIcon>
				</div>
				<div>
					<ShopList :key="listKey" :loaded="loaded" @openShop="openShopPage"></ShopList>
				</div>
			</PageCard>
		</PageBody>

		<LoadingScreen :has-loaded="hasLoaded"></LoadingScreen>
	</div>
</template>

<script>
import PageHeader from '../components/PageHeader'
import PageBody from '../components/PageBody'
import PageCard from '../components/PageCard'

import ShopList from './components/ShopList'
import CustomButtonIcon from '../components/inputs/CustomButtonIcon'
import LoadingScreen from '../components/LoadingScreen'

export default {
	name: 'CustomerShopListPage',
	components: {
		LoadingScreen,
		CustomButtonIcon,
		PageHeader,
		PageBody,
		PageCard,
		ShopList,
	},
	data() {
		return {
			loaded: {
				shops: false,
			},
			listKey: new Date().getMilliseconds(),
		}
	},
	computed: {
		hasLoaded: function () {
			return this.loaded.shops
		},
	},
	methods: {
		changeToAddShopPage: function () {
			this.$router.push({
				path: '/customer/addShop',
			})
		},
		openShopPage: function (shopId) {
			this.$router.push({
				path: '/customer/shops/' + shopId,
			})
		},
	},
}
</script>

<style scoped></style>