<template>
	<div class="flex flex-col">
		<PageHeader headline="Ihr Profil"></PageHeader>

		<PageBody>
			<div class="flex flex-row pt-10">
				<div class="flex-1"></div>

				<div class="flex flex-col xl:flex-row">
					<div class="flex flex-col">
						<PageCard title="" class="align-top">
							<div class="flex-col text-left min-w-card">
								<div class="pl-2 pb-1 text-xs">Ihre Kundennummer</div>
								<div class="pl-2 text-black">
									{{ customerId }}
								</div>
							</div>
						</PageCard>

						<ResellerInformationCard :loaded="loaded"></ResellerInformationCard>
					</div>

					<AccountInformationCard :loaded="loaded"></AccountInformationCard>

					<ProfileInformationCard :loaded="loaded"></ProfileInformationCard>
				</div>

				<div class="flex-1"></div>
			</div>
		</PageBody>

		<LoadingScreen :has-loaded="hasLoaded"></LoadingScreen>
	</div>
</template>

<script>
import PageHeader from '../components/PageHeader'
import PageBody from '../components/PageBody'
import PageCard from '../components/PageCard'

import AccountInformationCard from '../components/profile/AccountInformationCard'
import ProfileInformationCard from '../components/profile/ProfileInformationCard'
import LoadingScreen from '../components/LoadingScreen'
import ResellerInformationCard from './components/ResellerInformationCard'

export default {
	name: 'CustomerProfilePage',
	components: {
		ResellerInformationCard,
		LoadingScreen,
		PageHeader,
		PageBody,
		PageCard,
		AccountInformationCard,
		ProfileInformationCard,
	},
	data() {
		return {
			loaded: {
				profileInfo: false,
				accountInfo: false,
				metaInfo: false,
				resellerInfo: false,
			},
			customerId: '',
		}
	},
	computed: {
		hasLoaded: function () {
			return (
				this.loaded.accountInfo &&
				this.loaded.profileInfo &&
				this.loaded.metaInfo &&
				this.loaded.resellerInfo
			)
		},
	},
	created() {
		this.getMetaInfo()
	},
	methods: {
		getMetaInfo: function () {
			let context = this
			this.$http
				.get('/panel/customer/getMetaInfo')
				.then((response) => {
					context.loaded.metaInfo = true

					const status = response.data.status.value
					if ((response.status === 200) & (status === 100)) {
						context.customerId = response.data.body.customerId
					}
				})
				.catch((err) => {
					console.log(err)
				})
		},
	},
}
</script>

<style scoped></style>