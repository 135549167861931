<template>
	<div class="flex flex-col">
		<PageHeader headline="Startseite"></PageHeader>

		<PageBody>
			<div v-if="customerInfo" class="pt-10">
				<div class="flex flex-col">
					<div class="flex flex-row">
						<div class="flex-1"></div>
						<PageCard title="Eingespartes Bonpapier">
							<div class="text-xl text-black">
								{{
									mm2humanReadable(customerInfo.savedBonLength)
										? mm2humanReadable(customerInfo.savedBonLength)
										: '0cm'
								}}
							</div>
						</PageCard>
						<PageCard title="Anzahl Boxen">
							<div class="text-xl text-black">
								{{ customerInfo.numActiveBoxes }}
							</div>
						</PageCard>
						<PageCard title="Archivierte Bons">
							<div class="text-xl text-black">
								{{ customerInfo.numBons }}
							</div>
						</PageCard>
						<div class="flex-1"></div>
					</div>

					<div class="flex flex-row">
						<div class="flex-1"></div>

						<PageCard title="Archivierte Bons in den letzten 30 Tagen">
							<LineChart
								:chartdata="chartBon30days"
								:options="lineChartOptions"
							></LineChart>
						</PageCard>

						<PageCard title="Verhalten der Kunden">
							<div
								v-if="
									chartUserActions.datasets[0].data.filter((item) => item !== 0)
										.length === 0
								"
								class="text-xs"
							>
								Keine Daten vorhanden
							</div>
							<DoughnutChart
								v-else
								:chartdata="chartUserActions"
								:options="pieChartOptions"
							></DoughnutChart>
						</PageCard>

						<div class="flex-1"></div>
					</div>
				</div>
			</div>
		</PageBody>

		<LoadingScreen :has-loaded="hasLoaded"></LoadingScreen>
	</div>
</template>

<script>
import PageHeader from '../components/PageHeader'
import PageBody from '../components/PageBody'

import PageCard from '../components/PageCard'

import LineChart from '../components/charts/LineChart'
import DoughnutChart from '../components/charts/DoughnutChart'
import LoadingScreen from '../components/LoadingScreen'

export default {
	name: 'CustomerIndexPage',
	components: {
		LoadingScreen,
		PageCard,
		PageHeader,
		PageBody,
		LineChart,
		DoughnutChart,
	},
	data() {
		return {
			loaded: {
				customerInfo: false,
			},
			customerInfo: undefined,
			lineChartOptions: {
				responsive: true,
				maintainAspectRatio: true,
				title: {
					display: false,
				},
				legend: {
					display: false,
				},
				scales: {
					yAxes: [
						{
							display: true,
							ticks: {
								beginAtZero: true,
								precision: 0,
							},
						},
					],
				},
			},
			pieChartOptions: {
				responsive: true,
				maintainAspectRatio: true,
				title: {
					display: false,
				},
				legend: {
					display: true,
				},
			},
			chartBon30days: {
				labels: undefined,
				datasets: [
					{
						data: undefined,
						borderWidth: 5,
						borderColor: '#2a6dcb',
						pointBackgroundColor: '#2a6dcb',
						pointBorderWidth: 0,
						pointBorderColor: '#ffffff',
						backgroundColor: '#2a6dcb',
					},
				],
			},
			chartUserActions: {
				labels: undefined,
				datasets: [
					{
						data: undefined,
						backgroundColor: ['#97DFFC', '#2a6dcb', '#545E75'],
					},
				],
			},
		}
	},
	computed: {
		hasLoaded: function () {
			return this.loaded.customerInfo
		},
	},
	created() {
		this.getIndexInfo()
	},
	methods: {
		switchToAddShopPage: function () {
			this.$router.push({
				path: '/customer/addShop',
			})
		},
		switchToAddBoxPage: function () {
			this.$router.push({
				path: '/customer/addBox',
			})
		},
		getIndexInfo: function () {
			let context = this
			this.$http
				.get('/panel/customer/getIndexInfo')
				.then((response) => {
					context.loaded.customerInfo = true

					const status = response.data.status.value
					if (response.status === 200 && status === 100) {
						context.customerInfo = response.data.body

						context.chartBon30days.labels =
							response.data.body.numBonsLast30days.last30days
						context.chartBon30days.datasets[0].data =
							response.data.body.numBonsLast30days.numBonsPerDay

						context.chartUserActions.labels = response.data.body.bonUserActions.labels
						context.chartUserActions.datasets[0].data =
							response.data.body.bonUserActions.values
					}
				})
				.catch((err) => {
					context.loaded.customerInfo = true
					console.log(err)
				})
		},
		mm2humanReadable: function (lengthInMillimeter) {
			let lengthString = ''

			let tmp = lengthInMillimeter

			let km = Math.trunc(tmp / 1000000.0)
			if (km > 0) {
				tmp = tmp - km * 1000000
				lengthString += km + 'km '
			}
			let m = Math.trunc(tmp / 1000.0)
			if (m > 0) {
				tmp = tmp - m * 1000
				lengthString += m + 'm '
			}
			let cm = Math.trunc(tmp / 10.0)
			if (cm > 0) {
				lengthString += cm + 'cm'
			}

			return lengthString
		},
	},
}
</script>

<style scoped></style>