<template>
	<button
		class="px-6 bg-btnbg text-white h-7 rounded-3xl duration-200 hover:shadow-button_hover focus:shadow-button_hover active:shadow-button_active"
		:class="width"
		:disabled="loading"
		:title="tooltip"
		@click="$emit('click')"
	>
		<div v-if="loading">
			<svg
				class="animate-spin h-5 w-5 text-white mx-auto"
				xmlns="http://www.w3.org/2000/svg"
				fill="none"
				viewBox="0 0 24 24"
			>
				<circle
					class="opacity-25"
					cx="12"
					cy="12"
					r="10"
					stroke="currentColor"
					stroke-width="4"
				></circle>
				<path
					class="opacity-75"
					fill="currentColor"
					d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
				></path>
			</svg>
		</div>

		<div v-else class="text-xs">
			{{ text }}
		</div>
	</button>
</template>

<script>
export default {
	name: 'CustomButton',
	props: {
		text: {
			type: String,
			required: true,
			default: '',
		},
		defaultWidth: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		tooltip: {
			type: String,
			default: '',
		},
	},
	computed: {
		width: function () {
			return this.defaultWidth ? 'max-w-10' : 'max-w-lg'
		},
	},
}
</script>

<style scoped></style>