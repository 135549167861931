<template>
	<div>
		<div
			class="bg-white rounded-3xl text-left py-4 px-6 m-2 text-page_card_text_light shadow-page_card min-w-card"
		>
			<div v-if="title" class="font-bold text-page_card_header_text text-card_header pb-2">
				{{ title }}
			</div>

			<slot></slot>
		</div>
	</div>
</template>

<script>
export default {
	name: 'PageCard',
	props: {
		title: {
			type: String,
			required: true,
		},
	},
}
</script>

<style scoped></style>