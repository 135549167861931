import Vue from 'vue'
import App from './App.vue'

import { CONFIG } from '../config'
Vue.prototype.VERSION = CONFIG.VERSION

Vue.config.productionTip = false

import './assets/css/tailwind.css'
import './assets/css/daterange-picker.css'

import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
Vue.use(Toast, {
	transition: 'Vue-Toastification__fade',
	maxToasts: 5,
	newestOnTop: true,
})

import { ToastStyleService } from './services/toaststyle.service'
Vue.prototype.$toaststyle = ToastStyleService

import { LoggerService } from './services/logger.service'
LoggerService.setLevel('debug')
Vue.prototype.$logger = LoggerService

import { IconService } from './services/icon.service'
Vue.prototype.$icons = IconService

import { RulesService } from './services/rules.service'
Vue.prototype.$rules = RulesService

import { BreadcrumbService } from './services/breadcrumb.service'
BreadcrumbService.loadHistory()
Vue.prototype.$breadcrumbs = BreadcrumbService

import { UtilsService } from './services/utils.service'
Vue.prototype.$utils = UtilsService

import { CustomStore } from './store/store'
import { CustomRouter } from './router'

const dynamicHost = location.protocol + '//' + location.hostname

import { ApiService } from './services/api.service'

let API_BASEURL = CONFIG.API_URL
if (process.env.NODE_ENV === 'development') {
	API_BASEURL = dynamicHost + ':8080'
}
ApiService.init(API_BASEURL)
Vue.prototype.$http = ApiService

Vue.prototype.$API_BASEURL = API_BASEURL

import { TokenService } from './services/token.service'
TokenService.loadToken()

new Promise((resolve) => {
	if (TokenService.hasToken()) {
		LoggerService.debug('main.js', 'found token in local storage')

		ApiService.setAuthHeader(TokenService.getToken())

		CustomStore.dispatch('auth/verifyToken', {
			token: TokenService.getToken(),
		})
			.then((data) => {
				resolve({ hasToken: true, identity: data })
			})
			.catch(() => {
				resolve({ hasToken: true, identity: undefined })
			})
	} else {
		resolve(resolve({ hasToken: false, identity: undefined }))
	}
})
	.then((payload) => {
		if (payload.hasToken) {
			if (payload.identity) {
				LoggerService.debug('main.js', 'token is valid -> ' + payload.identity)

				CustomStore.commit('auth/loginSuccess')
			} else {
				LoggerService.debug('main.js', 'token is not valid')

				TokenService.removeToken()
				TokenService.removeIdentity()
				ApiService.removeAuthHeader()

				CustomStore.commit('auth/loginFailed')
				CustomStore.commit('auth/removeIdentity')
			}
		}
	})
	.then(() => {
		new Vue({
			router: CustomRouter,
			store: CustomStore,
			render: (h) => h(App),
		}).$mount('#app')
	})
