const RulesService = {
	required: (value) => (!!value && value.length > 0) || 'Eingabe benötigt',

	lengthAbove3: (value) => value.length >= 3 || 'Mindestens 3 Zeichen',
	lengthAbove5: (value) => value.length >= 5 || 'Mindestens 5 Zeichen',
	lengthAbove10: (value) => value.length >= 10 || 'Mindestens 10 Zeichen',

	lengthBelow20: (value) => value.length <= 20 || 'Maximal 20 Zeichen',
	lengthBelow30: (value) => value.length <= 30 || 'Maximal 30 Zeichen',
	lengthBelow40: (value) => value.length <= 40 || 'Maximal 40 Zeichen',
	lengthBelow50: (value) => value.length <= 50 || 'Maximal 50 Zeichen',

	onlyLettersAndDigits: (value) =>
		/[^A-Za-z0-9]/.test(value) || 'Nur Buchstaben und Zahlen erlaubt',
	noSpaces: (value) => !/\s/.test(value) || 'Keine Leerzeichen erlaubt',

	isEmail: (value) => /^\S+@\S+\.\S+$/.test(value) || 'Keine gütlige E-Mail-Adresse',
}

export { RulesService }
