<template>
	<div></div>
</template>

<script>
export default {
	name: 'LogoutPage',
	created() {
		const redirect = this.$router.history.current.query.redirect
		this.$store.dispatch('auth/logout', {
			targetRoute: redirect,
		})
	},
}
</script>

<style scoped></style>