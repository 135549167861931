<template>
	<div class="flex flex-col">
		<PageHeader
			headline="Kundendetails"
			description="Informationen zu diesem Kunden"
		></PageHeader>

		<PageBody>
			<div class="flex container flex-col xl:flex-row pt-10">
				<div class="flex-1"></div>

				<div class="mx-auto">
					<PageCard title="Zugewiesene Boxen:">
						<div class="text-2xl font-bold text-black">
							{{ customer.numBoxes }}
						</div>
					</PageCard>

					<PageCard title="Geschäfte:">
						<div class="text-2xl font-bold text-black">
							{{ customer.numShops }}
						</div>
					</PageCard>
				</div>

				<PageCard title="Kundendaten" class="mx-auto">
					<div class="flex flex-row">
						<div class="flex-col text-left px-6 py-5 min-w-card">
							<div class="border-b border-gray-300 pb-3 mb-3 text-sm">
								<div class="pl-2 pb-1 text-xs">Kontostatus</div>
								<div class="pl-2 text-black">
									<BadgeFatGreen
										v-if="state === 'ACTIVE'"
										text="AKTIV"
									></BadgeFatGreen>
									<BadgeFatPurple
										v-else-if="state === 'VERIFICATION_PENDING'"
										text="NOCH NICHT AKTIVIERT"
									></BadgeFatPurple>
									<BadgeFatRed
										v-else-if="state === 'BLOCKED' || state === 'DELETED'"
										text="DEAKTIVIERT"
									></BadgeFatRed>
									<BadgeFatGray v-else text="UNBEKANNT"></BadgeFatGray>
								</div>
							</div>

							<div class="border-b border-gray-300 pb-3 mb-3 text-sm">
								<div class="pl-2 pb-1 text-xs">Kundennummer</div>
								<div class="pl-2 text-black">
									{{ customer.userId }}
								</div>
							</div>

							<div class="border-b border-gray-300 pb-3 mb-3 text-sm">
								<div class="pl-2 pb-1 text-xs">E-Mail-Addresse</div>
								<div class="pl-2 text-black">
									{{ customer.email }}
								</div>
							</div>

							<div class="border-b border-gray-300 pb-3 mb-3 text-sm">
								<div class="pl-2 pb-1 text-xs">Telefonnummer</div>
								<div class="pl-2 text-black">
									{{ customer.phoneNumber }}
								</div>
							</div>

							<div class="border-b border-gray-300 pb-3 mb-3 text-sm">
								<div class="pl-2 pb-1 text-xs">Registriert seit</div>
								<div class="pl-2 text-black">
									{{
										$utils.date2timeString(new Date(customer.verified)) +
										' ' +
										$utils.date2dateString(new Date(customer.verified))
									}}
								</div>
							</div>

							<div class="text-sm">
								<div class="pl-2 pb-1 text-xs">Anschrift</div>
								<div class="pl-2 text-black">
									{{ customer.companyName }}
									<br />
									{{
										customer.address.streetName +
										' ' +
										customer.address.streetNumber
									}}
									<br />
									{{ customer.address.zipCode + ' ' + customer.address.cityName }}
									<br />
									{{ customer.address.countryName }}
								</div>
							</div>
						</div>
					</div>
				</PageCard>

				<div class="mx-auto">
					<PageCard
						title="Dem Kunden eine neue cybo-box zuweisen"
						class="max-w-card_small"
					>
						<div class="text-xs text-justify">
							Es wird ein neuer Aktivierungsschlüssel erzeugt, mit dem eine cybo-box
							aktiviert werden kann.
						</div>

						<div class="flex flex-row mt-4">
							<div class="flex-1"></div>
							<CustomButton
								text="Zuweisen"
								class=""
								@click="switchToAddBoxPage"
							></CustomButton>
							<div class="flex-1"></div>
						</div>
					</PageCard>

					<!--
					<PageCard
						title="Einem Geschäft dieses Kunden eine neue cybo-box zuweisen"
						class="max-w-card_small"
					>
						<div class="text-xs text-justify">
							Der Kunde besitzt diese cybo-box, welche direkt einem Geschäft
							zugewiesen wird und sofort aktiviert werden kann.
						</div>

						<div class="flex flex-row mt-4">
							<div class="flex-1"></div>
							<CustomButton
								text="Zuweisen"
								class=""
								@click="initAssignBoxToShop"
							></CustomButton>
							<div class="flex-1"></div>
						</div>
					</PageCard>

					<PageCard title="Neues Geschäft anlegen" class="max-w-card_small">
						<div class="text-xs text-justify">
							Ein neues Geschäft für diesen Kunden anlegen.
						</div>
						<div class="flex flex-row mt-4">
							<div class="flex-1"></div>
							<CustomButton
								text="Hinzufügen"
								class=""
								@click="initAddShop"
							></CustomButton>
							<div class="flex-1"></div>
						</div>
					</PageCard>
					-->

					<!--
                    <PageCard title="Konto deaktivieren">
                        <div class="flex flex-row">
                            <div class="flex-1"></div>
                            <CustomButton text="Deaktivieren"></CustomButton>
                            <div class="flex-1"></div>
                        </div>
                    </PageCard>
                    -->
				</div>

				<div class="flex-1"></div>
			</div>
		</PageBody>

		<ConfirmPopup
			v-show="assign.toConfirm"
			title="Bestätigung"
			text="Diesem Kunden eine neue Box zuweisen?"
			:loading="assign.isLoading"
			@cancel="cancelAssignBox"
			@confirm="assignBox"
		></ConfirmPopup>

		<ResellerCustomerAddShopPopup
			ref="addShopPopup"
			:customer-id="customerId"
			@success="addShopSuccess"
			@error="addShopError"
		></ResellerCustomerAddShopPopup>

		<ResellerCustomerAssignBoxToShopPopup
			ref="assignBoxToShopPopup"
			:customer-id="customerId"
			@success="assignBoxToShopSuccess"
			@error="assignBoxToShopError"
		></ResellerCustomerAssignBoxToShopPopup>

		<LoadingScreen :has-loaded="hasLoaded"></LoadingScreen>
	</div>
</template>

<script>
import PageHeader from '../components/PageHeader'
import PageBody from '../components/PageBody'
import PageCard from '../components/PageCard'

import CustomButton from '../components/inputs/CustomButton'

import ConfirmPopup from '../components/popup/ConfirmPopup'
import LoadingScreen from '../components/LoadingScreen'

import ResellerCustomerAddShopPopup from './components/ResellerCustomerAddShopPopup'
import ResellerCustomerAssignBoxToShopPopup from './components/ResellerCustomerAssignBoxToShopPopup'
import BadgeFatGreen from '../components/badges/BadgeFatGreen'
import BadgeFatGray from '../components/badges/BadgeFatGray'
import BadgeFatPurple from '../components/badges/BadgeFatPurple'
import BadgeFatRed from '../components/badges/BadgeFatRed'

export default {
	name: 'ResellerCustomerPage',
	components: {
		BadgeFatRed,
		BadgeFatPurple,
		BadgeFatGray,
		BadgeFatGreen,
		ResellerCustomerAssignBoxToShopPopup,
		ResellerCustomerAddShopPopup,
		LoadingScreen,
		ConfirmPopup,
		PageCard,
		PageHeader,
		PageBody,
		CustomButton,
	},
	data() {
		return {
			testObj: {
				text: '',
				error: '',
			},
			hasLoaded: false,
			assign: {
				toConfirm: false,
				isLoading: false,
				customerId: undefined,
			},
			isLoading: false,
			customerId: null,
			state: 'UNKNOWN',
			customer: {
				userId: 'unbekannt',
				email: 'unbekannt',
				phoneNumber: 'unbekannt',
				verified: 'unbekannt',
				address: {
					streetName: undefined,
					streetNumber: undefined,
					zipCode: undefined,
					cityName: undefined,
					countryName: undefined,
				},
				numBoxes: 0,
				numBoxesUnassigned: 0,
				numShops: 0,
			},
			addShop: {
				show: false,
			},
			rules: {
				required: (value) => !!value || 'Required.',
				onlyDigits: (value) => /^\d+$/.test(value) || 'Only digits allowed.',
			},
		}
	},
	created() {
		this.customerId = this.$route.params.identifier
		this.getCustomerInfo()
	},
	methods: {
		goBackToOverview: function () {
			this.$router.push({
				path: '/reseller/customers',
			})
		},
		switchToAddBoxPage: function () {
			this.$router.push({
				name: 'resellerBoxAddPage',
				query: {
					userId: this.customer.userId,
				},
			})
		},
		initAssignBox: function () {
			this.assign.toConfirm = true
		},
		cancelAssignBox: function () {
			this.assign.isLoading = false
			this.assign.toConfirm = false
		},
		assignBox: function () {
			this.assign.isLoading = true

			let context = this
			this.$http
				.post('/panel/reseller/assignBoxToCustomer', {
					customerId: this.customerId,
				})
				.then((response) => {
					const status = response.data.status.value
					if (response.status === 200 && status === 100) {
						context.getCustomerInfo()

						context.$toast.success(
							'Box erfolgreich zugewiesen',
							context.$toaststyle.successMid()
						)
					}
					context.cancelAssignBox()
				})
				.catch((err) => {
					console.log(err)
					context.cancelAssignBox()
				})
		},
		initAssignBoxToShop: function () {
			this.$refs['assignBoxToShopPopup'].open()
		},
		assignBoxToShopSuccess: function () {
			this.getCustomerInfo()

			this.$toast.success('Box erfolgreich zugewiesen', this.$toaststyle.successMid())
		},
		assignBoxToShopError: function (errorMessage) {
			this.$toast.error(errorMessage, this.$toaststyle.errorMid())
		},
		initAddShop: function () {
			this.$refs['addShopPopup'].open()
		},
		addShopSuccess: function () {
			this.getCustomerInfo()

			this.$toast.success('Geschäft erfolgreich hinzugefügt', this.$toaststyle.successMid())
		},
		addShopError: function (errorMessage) {
			this.$toast.error(errorMessage, this.$toaststyle.errorMid())
		},
		getCustomerInfo: function () {
			let context = this
			context.$http
				.post('/panel/reseller/getCustomerInfo', {
					customerId: context.customerId,
				})
				.then((response) => {
					const status = response.data.status.value
					if (response.status === 200 && status === 100) {
						const customer = response.data.body

						context.state = customer.accountState
						context.customer.userId = customer.userId
						context.customer.email = customer.email
						context.customer.phoneNumber = customer.phoneNumber
						context.customer.verified = customer.registrationDate

						context.customer.numBoxes = customer.numBoxes
						context.customer.numBoxesUnassigned = customer.numBoxesUnassigned
						context.customer.numShops = customer.numShops

						context.customer.companyName = customer.companyName
							? customer.companyName
							: 'unbekannt'

						context.customer.address.streetName = customer.streetName
							? customer.streetName
							: ''
						context.customer.address.streetNumber = customer.streetNumber
							? customer.streetNumber
							: ''
						context.customer.address.zipCode = customer.companyName
							? customer.zipCode
							: ''
						context.customer.address.cityName = customer.cityName
							? customer.cityName
							: ''
						context.customer.address.countryName = customer.countryName
							? customer.countryName
							: ''

						context.hasLoaded = true
					}
				})
				.catch((err) => {
					console.log(err)
				})
		},
	},
}
</script>

<style scoped></style>