<template>
	<div class="flex flex-row fixed top-0 left-0 w-full h-full bg-loader_bg z-front pl-16 pt-32">
		<div class="flex-1"></div>

		<slot name="content"></slot>

		<div class="flex-1"></div>
	</div>
</template>

<script>
export default {
	name: 'GenericPopup',
}
</script>

<style scoped></style>