<template>
	<GenericPopup v-if="show">
		<template v-slot:content>
			<PageCard
				title="Eine neue cybo-box direkt einem Geschäft zuweisen"
				class="max-w-card_large"
			>
				<div class="text-sm text-justify">
					Wählen Sie ein bestehendes Geschäft des Kunden aus. Eine neue cybo-box wird ohne
					weiteren Handlungsbedarf durch den Kunden diesem Geschäft direkt zugewiesen. Der
					Aktivierungsschlüssel wird Ihnen per E-Mail zugesendet und kann sofort benutzt
					werden.
				</div>

				<div class="text-center mt-4">
					<div
						v-if="shopList && shopList.length > 0"
						class="bg-listbg_dark rounded-3xl px-4 py-4"
					>
						<div class="flex flex-row text-list_textheader_dark h-9 text-xs">
							<div class="flex-1 h-full py-1 font-bold text-left pl-4">
								Geschäftsname
							</div>
							<div class="w-40 h-full py-1 font-bold text-left pl-4">Straße</div>
							<div class="w-40 h-full py-1 text-left pl-4 font-bold">Ort</div>
						</div>

						<div>
							<!-- click method is not on this elem so that the button click does not trigger it -->
							<div
								v-for="shop in sortedShopList"
								:key="shop.id"
								class="flex flex-row text-list_text_dark cursor-pointer rounded-3xl hover:bg-white text-xs"
								:class="selectedRowClass(shop.id)"
							>
								<div
									class="flex-1 h-full py-3 text-left pl-4 border-t"
									@click="selectShop(shop.id)"
								>
									{{ shop.name }}
								</div>
								<div
									class="w-40 h-full py-3 text-left pl-4 border-t"
									@click="selectShop(shop.id)"
								>
									{{ shop.address.streetName + ' ' + shop.address.streetNumber }}
								</div>
								<div
									class="w-40 h-full py-3 text-left pl-4 border-t"
									@click="selectShop(shop.id)"
								>
									{{ shop.address.zipCode + ' ' + shop.address.cityName }}
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="flex flex-row mt-7">
					<div class="flex flex-1"></div>
					<CustomButton
						text="Abbrechen"
						default-width
						class="mr-2"
						@click="close"
					></CustomButton>
					<CustomButton
						text="Zuweisen"
						default-width
						:loading="isLoading"
						@click="assignBoxToShop"
					></CustomButton>
				</div>
			</PageCard>
		</template>
	</GenericPopup>
</template>

<script>
import GenericPopup from '../../components/popup/GenericPopup'
import PageCard from '../../components/PageCard'
import CustomButton from '../../components/inputs/CustomButton'

export default {
	name: 'ResellerCustomerAssignBoxToShopPopup',
	components: {
		CustomButton,
		PageCard,
		GenericPopup,
	},
	props: {
		customerId: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			show: false,
			isLoading: false,
			shopList: undefined,
			selectedId: undefined,
		}
	},
	computed: {
		sortedShopList: function () {
			let tmp = this.shopList

			tmp.sort((a, b) => {
				if (a.name < b.name) {
					return -1
				} else if (a.name > b.name) {
					return 1
				}
				return 0
			})

			return tmp
		},
	},
	created() {
		this.getCustomerShopList()
	},
	methods: {
		open: function () {
			this.show = true
		},
		close: function () {
			if (this.isLoading) {
				return
			}

			this.show = false
			this.isLoading = false
			this.selectedId = false
		},
		getCustomerShopList: function () {
			const context = this

			context.$http
				.post('/panel/reseller/getCustomerShopList', {
					customerId: context.customerId,
				})
				.then((response) => {
					const status = response.data.status.value

					if (response.status === 200 && status === 100) {
						context.shopList = response.data.body
					} else {
						console.log(response.data.body)
					}
				})
				.catch((err) => {
					console.log(err)
				})
		},
		selectShop: function (id) {
			if (this.isLoading) {
				return
			}
			this.selectedId = id
		},
		assignBoxToShop: function () {
			if (this.isLoading) {
				return
			}

			if (!this.selectedId) {
				this.$toast.error('Kein Geschäft ausgewählt!', this.$toaststyle.errorShort())
				return
			}

			this.isLoading = true

			const context = this
			context.$http
				.post('/panel/reseller/assignBoxToShop', {
					customerId: context.customerId,
					shopId: context.selectedId,
				})
				.then((response) => {
					const status = response.data.status.value

					context.isLoading = false

					if (response.status === 200 && status === 100) {
						context.close()
						context.$emit('success')
					} else {
						context.close()
						context.$emit('error', response.data.body)
					}
				})
				.catch((err) => {
					console.log(err)

					context.isLoading = false
					context.close()
					context.$emit('error', response.data.body)
				})
		},
		selectedRowClass: function (id) {
			if (id === this.selectedId) {
				return 'bg-white '
			} else {
				return ''
			}
		},
	},
}
</script>

<style scoped></style>