<template>
	<span
		class="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-indigo-100 bg-green-600 rounded"
	>
		{{ text }}
	</span>
</template>

<script>
export default {
	name: 'BadgeFatGreen',
	props: {
		text: {
			type: String,
			required: true,
		},
	},
}
</script>

<style scoped></style>