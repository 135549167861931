import { render, staticRenderFns } from "./BadgeFatRed.vue?vue&type=template&id=406b2de3&scoped=true&"
import script from "./BadgeFatRed.vue?vue&type=script&lang=js&"
export * from "./BadgeFatRed.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "406b2de3",
  null
  
)

export default component.exports