<template>
	<div class="flex flex-col">
		<PageHeader
			headline="Geschäft hinzufügen"
			description="Ein neues Geschäft anlegen"
		></PageHeader>

		<PageBody>
			<div class="flex flex-row pt-10">
				<div class="flex-1"></div>

				<ShopInputCard class="w-96"></ShopInputCard>

				<div class="flex-1"></div>
			</div>
		</PageBody>
	</div>
</template>

<script>
import PageHeader from '../components/PageHeader'
import PageBody from '../components/PageBody'

import ShopInputCard from './components/ShopInputCard'

export default {
	name: 'CustomerAddShopPage',
	components: {
		PageHeader,
		PageBody,
		ShopInputCard,
	},
	methods: {
		goBack: function () {
			this.$router.push({
				path: '/customer/shops',
			})
		},
	},
}
</script>

<style scoped></style>