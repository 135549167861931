<template>
	<PageCard :title="title" class="relative z-9">
		<CustomButtonIcon
			v-if="showAddButton"
			:icon="this.$icons.addActive"
			class="absolute top-2 right-4"
			tooltip="Einen neuen Kunden hinzufügen"
			@click="openCustomerAddPage"
		></CustomButtonIcon>

		<div class="text-center">
			<div class="bg-listbg_dark rounded-3xl px-4 mb-4 transition delay-150">
				<div class="flex flex-row">
					<div
						class="flex flex-row text-left pt-2 pb-2 text-xs font-bold text-page_card_header_text cursor-pointer hover:underline transition duration-700"
						@click="toggleFilters"
					>
						<img
							:src="showFilters ? this.$icons.mdiMinus : this.$icons.mdiPlus"
							alt=""
							class="h-4"
						/>
						<div class="ml-1 select-none">
							Listenfilter {{ showFilters ? 'ausblenden' : 'einblenden' }}
						</div>
					</div>
					<div class="flex flex-1"></div>
				</div>
				<div v-if="showFilters" class="flex flex-row pb-3 pt-2">
					<div class="mx-1">
						<CustomInputFilter
							v-model="filter.userIdObj"
							class="w-36"
							title="Kundennummer"
						></CustomInputFilter>
					</div>
					<div class="mx-1">
						<CustomInputFilter
							v-model="filter.emailObj"
							class="w-48"
							title="E-Mail-Adresse"
						></CustomInputFilter>
					</div>
					<div class="mx-1">
						<CustomInputFilter
							v-model="filter.companyNameObj"
							class="w-48"
							title="Unternehmensname"
						></CustomInputFilter>
					</div>
					<div class="mx-1">
						<CustomInputFilter
							v-model="filter.contactPersonObj"
							class="w-48"
							title="Ansprechpartner"
						></CustomInputFilter>
					</div>
					<div class="">
						<CustomButtonIcon
							:icon="this.$icons.cancel"
							tooltip="Alle Filter zurücksetzen"
							bg-color="bg-red-300"
							class="ml-2 mt-1"
							@click="resetAllFilters"
						></CustomButtonIcon>
					</div>
				</div>
			</div>

			<div v-if="customerList.length > 0" class="bg-listbg_dark rounded-3xl px-4 py-4">
				<div class="flex flex-row text-list_textheader_dark min-h-12 text-xs select-none">
					<div class="w-40 h-full py-1 font-bold text-left pl-4">Kundennummer</div>
					<div class="w-40 h-full py-1 font-bold text-left pl-4">E-Mail-Adresse</div>
					<div class="flex-1 h-full py-1 font-bold text-left pl-4">Unternehmensname</div>
					<div class="w-40 h-full py-1 font-bold text-left pl-4">Ansprechpartner</div>
					<div class="w-20 h-full py-1 font-bold text-right pr-4">Boxen</div>
					<div
						class="w-48 flex flex-row h-full py-1 font-bold text-left pl-4 cursor-pointer hover:underline"
						@click="sortBy('created')"
					>
						<div class="flex-1"></div>
						<div class="flex-1">Registrierungsdatum</div>
						<div v-if="sort.column === 'created'" class="flex-1 pl-4">
							<img
								v-if="sort.ascending"
								:src="this.$icons.arrowUp"
								alt=""
								class="h-4"
							/>
							<img v-else :src="this.$icons.arrowDown" alt="" class="h-4" />
						</div>
					</div>
				</div>

				<div>
					<!-- click method is not on this elem so that the button click does not trigger it -->
					<div
						v-for="customer in customerList"
						:key="customer.id"
						class="flex flex-row text-new_fg cursor-pointer rounded-3xl hover:bg-white text-xs border-t-2 border-white"
					>
						<div
							class="w-40 h-full py-3 text-left pl-4"
							@click="$emit('clickOnCustomer', customer.id)"
						>
							{{ customer.userID }}
						</div>
						<div
							class="w-40 h-full py-3 text-left pl-4"
							@click="$emit('clickOnCustomer', customer.id)"
						>
							{{ customer.email }}
						</div>
						<div
							class="flex-1 h-full py-3 text-left pl-4"
							@click="$emit('clickOnCustomer', customer.id)"
						>
							{{ customer.companyName }}
						</div>
						<div
							class="w-40 h-full py-3 text-left pl-4"
							@click="$emit('clickOnCustomer', customer.id)"
						>
							{{ customer.contactPerson }}
						</div>
						<div
							class="w-20 h-full py-3 text-right pr-4"
							@click="$emit('clickOnCustomer', customer.id)"
						>
							{{ customer.numBoxes }}
						</div>
						<div
							class="w-48 h-full py-3 text-right pr-4"
							@click="$emit('clickOnCustomer', customer.id)"
						>
							{{ $utils.date2dateString(new Date(customer.created)) }}
						</div>
					</div>
				</div>
			</div>

			<div v-else class="bg-listbg_dark rounded-3xl px-4 py-4 text-new_fg">
				<div>Keine Kunden gefunden</div>
				<div class="mt-2">
					<CustomButton
						text="Neuen Kunden anlegen"
						@click="openCustomerAddPage"
					></CustomButton>
				</div>
			</div>

			<CustomPagination
				:current-page-num="page.current"
				:max-page-num="page.max"
				:total-elements="page.totalElements"
				class="mt-4"
				@previous="previousPage"
				@next="nextPage"
			></CustomPagination>
		</div>
	</PageCard>
</template>

<script>
import PageCard from '../../components/PageCard'

import CustomPagination from '../../components/CustomPagination'
import CustomButtonIcon from '../../components/inputs/CustomButtonIcon'
import CustomInputFilter from '../../components/inputs/CustomInputFilter'
import CustomButton from '../../components/inputs/CustomButton'

export default {
	name: 'CustomerList',
	components: {
		CustomButton,
		CustomInputFilter,
		CustomButtonIcon,
		CustomPagination,
		PageCard,
	},
	props: {
		loaded: {
			type: Object,
			default() {
				return {
					customerActive: false,
				}
			},
		},
		showAddButton: {
			type: Boolean,
			default: false,
		},
		title: {
			type: String,
			default: 'Kunden mit aktivem Konto',
		},
	},
	data() {
		return {
			customerList: [],
			filter: {
				userIdObj: {
					text: '',
				},
				emailObj: {
					text: '',
				},
				companyNameObj: {
					text: '',
				},
				contactPersonObj: {
					text: '',
				},
			},
			page: {
				entriesPerPage: 10,
				current: 1,
				max: 1,
				totalElements: 0,
			},
			sort: {
				column: 'created',
				ascending: false,
			},
			showFilters: false,
		}
	},
	watch: {
		'filter.userIdObj.text': function (e) {
			let query = Object.assign({}, this.$route.query)

			if (!e || e.length === 0) {
				delete query.userId
			} else {
				query.userId = e
			}

			this.$router.push({ query }).catch(() => {
				/* catches the redundant navigation error on page load */
			})

			this.getValidCustomerList()
		},
		'filter.emailObj.text': function (e) {
			let query = Object.assign({}, this.$route.query)

			if (!e || e.length === 0) {
				delete query.email
			} else {
				query.email = e
			}

			this.$router.push({ query }).catch(() => {
				/* catches the redundant navigation error on page load */
			})

			this.getValidCustomerList()
		},
		'filter.companyNameObj.text': function (e) {
			let query = Object.assign({}, this.$route.query)

			if (!e || e.length === 0) {
				delete query.company
			} else {
				query.company = e
			}

			this.$router.push({ query }).catch(() => {
				/* catches the redundant navigation error on page load */
			})

			this.getValidCustomerList()
		},
		'filter.contactPersonObj.text': function (e) {
			let query = Object.assign({}, this.$route.query)

			if (!e || e.length === 0) {
				delete query.contact
			} else {
				query.contact = e
			}

			this.$router.push({ query }).catch(() => {
				/* catches the redundant navigation error on page load */
			})

			this.getValidCustomerList()
		},
	},
	async created() {
		const queryParams = this.$route.query

		if (queryParams.page) {
			this.updateTargetPage(parseInt(queryParams.page))
			this.updateCurrentPage(parseInt(queryParams.page))
		}

		let hasParam = false

		if (queryParams.userId) {
			this.filter.userIdObj.text = queryParams.userId
			hasParam = true
		}
		if (queryParams.email) {
			this.filter.emailObj.text = queryParams.email
			hasParam = true
		}
		if (queryParams.company) {
			this.filter.companyNameObj.text = queryParams.company
			hasParam = true
		}
		if (queryParams.contact) {
			this.filter.contactPersonObj.text = queryParams.contact
			hasParam = true
		}

		if (hasParam) {
			this.showFilters = true
		}

		this.getValidCustomerList()
	},
	methods: {
		previousPage: function () {
			this.page.current = Math.max(1, this.page.current - 1)

			this.getValidCustomerList()
		},
		nextPage: function () {
			this.page.current = Math.min(this.page.max, this.page.current + 1)

			this.getValidCustomerList()
		},
		sortBy: function (column) {
			if (column === this.sort.column) {
				this.sort.ascending = !this.sort.ascending
			} else {
				this.sort.column = column
				this.sort.ascending = false
			}

			this.getValidCustomerList()
		},
		toggleFilters: function () {
			if (this.showFilters) {
				// do not hide filters if filters are set
				if (
					this.filter.emailObj.text.length === 0 &&
					this.filter.userIdObj.text.length === 0 &&
					this.filter.companyNameObj.text.length === 0 &&
					this.filter.contactPersonObj.text.length === 0
				) {
					this.showFilters = false
				} else {
					this.$toast.info('Es sind noch Filter ausgewählt', this.$toaststyle.errorMid)
				}
			} else {
				this.showFilters = true
			}
		},
		resetAllFilters: function () {
			this.filter.userIdObj.text = ''
			this.filter.emailObj.text = ''
			this.filter.companyNameObj.text = ''
			this.filter.contactPersonObj.text = ''

			this.$toast.success('Listenfilter zurückgesetzt', this.$toaststyle.successMid)
		},
		getValidCustomerList: function () {
			let context = this
			this.$http
				.post('/panel/reseller/getValidCustomerList', {
					page: context.page.current - 1,
					sortAscending: context.sort.ascending,
					customerId: context.filter.userIdObj.text,
					email: context.filter.emailObj.text,
					companyName: context.filter.companyNameObj.text,
					contactPerson: context.filter.contactPersonObj.text,
				})
				.then((response) => {
					context.loaded.customerActive = true

					const status = response.data.status.value
					if (response.status === 200 && status === 100) {
						context.page.max = response.data.body.pageInfo.numPages
						context.page.totalElements = response.data.body.pageInfo.numEntries

						context.customerList = response.data.body.customerList
					}
				})
				.catch((err) => {
					console.log(err)
				})
		},
		openCustomerAddPage: function () {
			this.$router.push({
				path: '/reseller/addCustomer',
			})
		},
		updatePages: function (maxPage) {
			this.page.max = maxPage
		},
	},
}
</script>

<style scoped></style>