<template>
	<div
		class="relative z-20 text-dd_text_inactive bg-dd_bg_inactive border border-new_fg rounded-t-3xl h-9 max-w-sm"
		:class="mainStyle"
	>
		<div class="absolute left-6 -top-3 text-xs text-new_fg px-2 bg-dd_bg_inactive">
			{{ title }}
		</div>

		<div
			class="absolute top-0 left-0 z-20 w-full h-full px-4 pt-2 cursor-pointer text-left text-xs"
			:class="data.selected.value !== 'NONE' ? 'text-black' : ''"
			@click="toggle"
		>
			{{ data.selected.text }}
		</div>

		<div
			v-if="isOpen"
			class="absolute -left-dd_option z-20 px-2 mt-8 pb-1 pt-2 w-dd_option min-h-22 max-h-96 overflow-auto bg-dd_bg_inactive rounded-b-3xl cursor-pointer border-new_fg border-b border-l border-r"
		>
			<div
				v-for="option in data.options"
				:key="option.value"
				:value="option.value"
				class="h-9 text-left text-new_fg rounded-3xl px-4 pt-3 my-1 bg-dd_bg_inactive hover:bg-dd_bg_active text-xs"
				@click="setSelected(option.text, option.value)"
			>
				{{ option.text }}
			</div>
		</div>

		<div
			v-show="isOpen"
			class="fixed z-19 top-0 left-0 w-full h-full cursor-default"
			@click="close"
		></div>
	</div>
</template>

<script>
export default {
	name: 'CustomDropdownFilter',
	props: {
		title: {
			type: String,
			default: '',
		},
		data: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			isOpen: false,
		}
	},
	computed: {
		mainStyle: function () {
			if (this.isOpen) {
				return ''
			} else {
				return 'rounded-b-3xl'
			}
		},
	},
	methods: {
		toggle: function () {
			this.isOpen = !this.isOpen
		},
		close: function () {
			this.isOpen = false
		},
		setSelected: function (text, value) {
			this.data.selected.text = text
			this.data.selected.value = value
			this.close()

			this.$emit('select')
		},
	},
}
</script>

<style scoped></style>