<template>
	<div id="app" class="flex">
		<router-view class="flex-content"></router-view>
	</div>
</template>

<script>
export default {
	name: 'App',
}
</script>

<style>
@font-face {
	font-family: 'NunitoSans';
	src: url('./assets/fonts/NunitoSans-Regular.ttf');
	font-style: normal;
	font-weight: normal;
}
@font-face {
	font-family: 'NunitoSans';
	src: url('./assets/fonts/NunitoSans-Light.ttf');
	font-style: normal;
	font-weight: 200;
}
@font-face {
	font-family: 'NunitoSans';
	src: url('./assets/fonts/NunitoSans-Bold.ttf');
	font-style: normal;
	font-weight: bold;
}

body {
	width: 100vw;
	height: 100vh;
	background-color: #edf6ff;
}
#app {
	font-family: NunitoSans, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
}

.red-outline {
	border: 1px solid red !important;
}
.wiggle {
	animation: wiggle 0.1s linear 5;
}
@keyframes wiggle {
	0% {
		transform: translateX(0px);
	}
	25% {
		transform: translateX(10px);
	}
	50% {
		transform: translateX(0px);
	}
	75% {
		transform: translateX(-10px);
	}
	100% {
		transform: translateX(0px);
	}
}
</style>
