<template>
	<div class="flex flex-col">
		<PageHeader headline="Details zu diesem Geschäft"></PageHeader>

		<PageBody>
			<div class="flex flex-row pt-10">
				<div class="flex-1"></div>

				<PageCard title="">
					<div class="flex-col text-left px-6 py-5 min-w-card">
						<div class="border-b border-gray-300 pb-3 mb-3">
							<div class="pl-2 pb-1 text-xs">Boxen in diesem Geschäft</div>
							<div class="pl-2 text-black">
								{{ meta.numBoxes }}
							</div>
						</div>

						<div class="border-b border-gray-300 pb-3 mb-3">
							<div class="pl-2 pb-1 text-xs">Archivierte Kassenbons</div>
							<div class="pl-2 text-black">
								{{ meta.numStoredBons }}
							</div>
						</div>

						<div class="">
							<div class="pl-2 pb-1 text-xs">Gedruckte Kassenbons</div>
							<div class="pl-2 text-black">
								{{ meta.numPrintedBons }}
							</div>
						</div>

						<div class="">
							<div class="p-2"></div>
							<div class="p-2 text-black">
								<CustomButton
									text="Zur Boxübersicht"
									@click="changeToBoxListPage"
								></CustomButton>
							</div>
						</div>
					</div>
				</PageCard>

				<PageCard title="Informationen zum Geschäft">
					<div class="flex-col text-left px-6 py-5 min-w-card">
						<CustomForm ref="customerChangeShopInfoForm">
							<div class="border-b border-gray-300 pb-3 mb-3">
								<div class="pl-2 pb-1 text-xs">Name des Geschäfts</div>
								<div v-if="state === 0" class="pl-2 text-black">
									{{ shopNameObj.text }}
								</div>
								<div v-else>
									<CustomInput
										:value="shopNameObj"
										:rules="[
											$rules.required,
											$rules.lengthAbove3,
											$rules.lengthBelow50,
										]"
									></CustomInput>
								</div>
							</div>

							<div>
								<div class="pl-2 pb-1 text-xs">Anschrift</div>
								<div v-if="state === 0" class="pl-2 text-black">
									{{ shopStreetNameObj.text + ' ' + shopStreetNumberObj.text }}
									<br />
									{{ shopZipCodeObj.text + ' ' + shopCityNameObj.text }}
								</div>
								<div v-else>
									<div class="flex flex-row">
										<div class="flex-1 mr-3">
											<CustomInput
												v-model="shopStreetNameObj"
												placeholder="Straße"
												type="text"
												class="mx-auto mt-3"
												:rules="[
													$rules.required,
													$rules.lengthAbove3,
													$rules.lengthBelow30,
												]"
											></CustomInput>
										</div>
										<div class="w-1/4">
											<CustomInput
												v-model="shopStreetNumberObj"
												placeholder="Nr."
												type="text"
												class="mx-auto mt-3"
												:rules="[$rules.required, $rules.lengthBelow20]"
											></CustomInput>
										</div>
									</div>

									<div class="flex flex-row">
										<div class="w-1/3">
											<CustomInput
												v-model="shopZipCodeObj"
												placeholder="PLZ"
												type="text"
												class="mt-3"
												:rules="[
													$rules.required,
													$rules.lengthAbove3,
													$rules.lengthBelow20,
												]"
											></CustomInput>
										</div>
										<div class="flex-1 ml-3">
											<CustomInput
												v-model="shopCityNameObj"
												placeholder="Ort"
												type="text"
												class="mt-3"
												:rules="[
													$rules.required,
													$rules.lengthAbove3,
													$rules.lengthBelow30,
												]"
											></CustomInput>
										</div>
									</div>
								</div>
							</div>
						</CustomForm>
					</div>

					<div v-if="state === 0" class="flex flex-row mt-4">
						<div class="flex-1"></div>
						<CustomButtonIcon
							:icon="this.$icons.edit"
							tooltip="Bearbeiten"
							@click="editShopInfo"
						></CustomButtonIcon>
						<div class="flex-1"></div>
					</div>
					<div v-else class="flex flex-row mt-4">
						<div class="flex-1"></div>
						<CustomButtonIcon
							:icon="this.$icons.cancel"
							tooltip="Abbrechen"
							class="mx-2 bg-btnbg_secondary_light"
							@click="cancelEditShopInfo"
						></CustomButtonIcon>
						<CustomButtonIcon
							:icon="this.$icons.confirm"
							tooltip="Speichern"
							class="mx-2"
							:loading="editShop.isLoading"
							@click="saveEditShopInfo"
						></CustomButtonIcon>
						<div class="flex-1"></div>
					</div>
				</PageCard>

				<div class="flex-1"></div>
			</div>
		</PageBody>

		<LoadingScreen :has-loaded="hasLoaded"></LoadingScreen>
	</div>
</template>

<script>
import PageHeader from '../components/PageHeader'
import PageBody from '../components/PageBody'
import PageCard from '../components/PageCard'

import CustomButton from '../components/inputs/CustomButton'
import CustomInput from '../components/inputs/CustomInput'
import CustomButtonIcon from '../components/inputs/CustomButtonIcon'
import LoadingScreen from '../components/LoadingScreen'
import CustomForm from '../components/inputs/CustomForm'

export default {
	name: 'CustomerSingleShopPage',
	components: {
		CustomForm,
		LoadingScreen,
		CustomButtonIcon,
		CustomInput,
		PageHeader,
		PageBody,
		PageCard,
		CustomButton,
	},
	data() {
		return {
			loaded: {
				shopInfo: false,
				shopData: false,
			},
			editShop: {
				isLoading: false,
			},
			identifier: undefined,
			state: 0,
			shopNameObj: {
				text: '',
				errorMessage: '',
			},
			shopStreetNameObj: {
				text: '',
				errorMessage: '',
			},
			shopStreetNumberObj: {
				text: '',
				errorMessage: '',
			},
			shopZipCodeObj: {
				text: '',
				errorMessage: '',
			},
			shopCityNameObj: {
				text: '',
				errorMessage: '',
			},
			meta: {
				numBoxes: 0,
				numPrintedBons: 0,
				numStoredBons: 0,
			},
		}
	},
	computed: {
		hasLoaded: function () {
			return this.loaded.shopData
		},
	},
	created() {
		this.identifier = this.$route.params.identifier
		this.getShopInfo(this.identifier)
	},
	methods: {
		getShopInfo: function (identifier) {
			let context = this
			this.$http
				.get('/panel/customer/getSingleShopInfo?identifier=' + identifier)
				.then((response) => {
					context.loaded.shopData = true

					const status = response.data.status.value
					if (response.status === 200 && status === 100) {
						const payload = response.data.body
						context.shopNameObj.text = payload.name
						context.shopStreetNameObj.text = payload.streetName
						context.shopStreetNumberObj.text = payload.streetNumber
						context.shopZipCodeObj.text = payload.zipCode
						context.shopCityNameObj.text = payload.cityName

						context.meta.numBoxes = payload.numBoxes
						context.meta.numStoredBons = payload.numStoredBons
						context.meta.numPrintedBons = payload.numPrintedBons
					} else {
						console.log(response)
					}
				})
				.catch((err) => {
					console.log(err)
				})
		},
		editShopInfo: function () {
			this.state = 1
		},
		cancelEditShopInfo: function () {
			this.state = 0
			this.editShop.isLoading = false
		},
		saveEditShopInfo: function () {
			if (this.$refs['customerChangeShopInfoForm'].validate()) {
				this.editShop.isLoading = true

				let context = this
				this.$http
					.post('/panel/customer/addShop', {
						identifier: context.identifier,
						shopName: context.shopNameObj.text,
						streetName: context.shopStreetNameObj.text,
						streetNumber: context.shopStreetNumberObj.text,
						zipCode: context.shopZipCodeObj.text,
						city: context.shopCityNameObj.text,
					})
					.then((response) => {
						const status = response.data.status.value
						if (response.status === 200 && status === 100) {
							context.cancelEditShopInfo()

							context.$toast.success(
								'Informationen zu diesem Geschäft geändert',
								context.$toaststyle.successMid
							)
						} else {
							switch (status) {
								case 1053:
									context.$toast.error(
										'Name bereits vergeben',
										context.$toast.errorShort
									)
									context.shopNameObj.errorMessage = 'Name bereits vergeben'
									break
								default:
									context.$toast.error(
										'Ein Fehler ist aufgetreten',
										context.$toaststyle.errorShort
									)
									console.log(response.data)
									break
							}
						}
					})
					.catch((err) => {
						console.log(err)
						context.cancelEditShopInfo()

						context.$toast.error(err.message, context.$toaststyle.errorMid)
					})
					.finally(() => {
						context.editShop.isLoading = false
						context.getShopInfo(context.identifier)
					})
			}
		},
		changeToBoxListPage: function () {
			this.$router.push({
				path: '/customer/boxes',
				query: {
					shopName: this.shopNameObj.text,
				},
			})
		},
	},
}
</script>

<style scoped></style>