import Vue from 'vue'
import Vuex from 'vuex'

import { AuthModule } from './modules/auth.module'
import { StatusModule } from './modules/status.module'

Vue.use(Vuex)

const CustomStore = new Vuex.Store({
	modules: {
		auth: AuthModule,
		status: StatusModule,
	},
})

export { CustomStore }
