<template>
	<div
		class="flex flex-row pt-2 pl-36 bg-new_nav text-page_header_fg_light text-left border-b border-page_header_border_light h-16 fixed top-0 left-0 w-full"
	>
		<div>
			<div class="text-xl font-bold text-white">
				{{ headline }}
			</div>

			<div v-if="breadcrumbs.length > 0" class="flex flex-row pb-1 text-sm">
				<div v-for="bc in breadcrumbs" :key="bc.name" class="mr-2 flex flex-row text-white">
					<div>
						<img :src="arrowIcon" class="w-3 mt-1 mr-2" alt="" />
					</div>

					<div
						:class="bc.current ? '' : 'cursor-pointer hover:underline'"
						@click="openPage(bc.path, bc.current)"
					>
						{{ bc.name }}
					</div>
				</div>
			</div>
		</div>

		<div class="flex flex-1"></div>

		<div class="text-sm text-white mr-5 mt-2 ml-3">
			<div class="text-xs select-none">
				<div>Rolle:</div>
			</div>
			<div>
				<div v-if="identity === 'CYBO_RESELLER'">Händler</div>
				<div v-else-if="identity === 'CYBO_ADMIN'">Admin</div>
				<div v-else>Kunde</div>
			</div>
		</div>

		<div class="text-sm text-white mr-5 mt-2 ml-3">
			<div class="text-xs select-none">
				<div>Angemeldet als:</div>
			</div>
			<div class="hover:underline cursor-pointer" @click="goToProfilePage">
				{{ email }}
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'PageHeader',
	props: {
		headline: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			email: '',
			identity: '',
			breadcrumbs: [],
			// this needs to be here, otherwise $icons is undefined ?
			arrowIcon: this.$icons.arrowRightWhite,
		}
	},
	created() {
		this.email = this.$store.getters['auth/getEmail']
		this.identity = this.$store.getters['auth/getIdentity']

		this.breadcrumbs = this.$breadcrumbs.getHistory()
	},
	methods: {
		openPage: function (path, isCurrent) {
			if (!path || isCurrent) {
				return
			}
			this.$router.push({
				path: path,
			})
		},
		goToProfilePage: function () {
			if (this.identity === 'CYBO_ADMIN') {
				this.$router.push({
					name: 'adminProfilePage',
				})
			} else if (this.identity === 'CYBO_RESELLER') {
				this.$router.push({
					name: 'resellerProfilePage',
				})
			} else if (this.identity === 'CYBO_CUSTOMER') {
				this.$router.push({
					name: 'customerProfilePage',
				})
			} else {
				this.$router.push({
					path: '/',
				})
			}
		},
	},
}
</script>

<style scoped></style>