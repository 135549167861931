<template>
	<div class="flex flex-col">
		<PageHeader
			headline="Kundenübersicht"
			description="Informationen zu Ihren Kunden"
		></PageHeader>

		<PageBody>
			<div class="container mx-auto pt-4">
				<div>
					<CustomerList
						show-add-button
						:loaded="loaded"
						@clickOnCustomer="openCustomerPage"
					></CustomerList>
				</div>
			</div>
		</PageBody>

		<LoadingScreen :has-loaded="hasLoaded"></LoadingScreen>
	</div>
</template>

<script>
import PageHeader from '../components/PageHeader'
import PageBody from '../components/PageBody'

import CustomerList from './components/CustomerList'
import LoadingScreen from '../components/LoadingScreen'

export default {
	name: 'ResellerCustomerListPage',
	components: {
		LoadingScreen,
		PageHeader,
		PageBody,
		CustomerList,
	},
	data() {
		return {
			loaded: {
				customerActive: false,
			},
		}
	},
	computed: {
		hasLoaded: function () {
			return this.loaded.customerActive
		},
	},
	methods: {
		openCustomerPage: function (customerId) {
			this.$router.push({
				path: '/reseller/customers/' + customerId,
			})
		},
	},
}
</script>

<style scoped></style>