<template>
	<PageCard title="Informationen zum Geschäft">
		<div class="text-xs text-justify">
			Diese Informationen sind für die Übersicht und Identifizierung der cybo-boxen hilfreich.
		</div>

		<div class="mx-auto justify-center">
			<CustomForm ref="customerAddNewShopForm">
				<CustomInput
					v-model="nameObj"
					placeholder="Name des Geschäfts"
					type="text"
					class="mx-auto mt-4"
					:rules="[$rules.required, $rules.lengthAbove3, $rules.lengthBelow50]"
				></CustomInput>

				<div class="flex flex-row">
					<div class="flex-1 mr-3">
						<CustomInput
							v-model="streetNameObj"
							placeholder="Straße"
							type="text"
							class="mx-auto mt-3"
							:rules="[$rules.required, $rules.lengthAbove3, $rules.lengthBelow30]"
						></CustomInput>
					</div>
					<div class="w-1/4">
						<CustomInput
							v-model="streetNumberObj"
							placeholder="Nr."
							type="text"
							class="mx-auto mt-3"
							:rules="[$rules.required, $rules.lengthBelow20]"
						></CustomInput>
					</div>
				</div>

				<div class="flex flex-row">
					<div class="w-1/3">
						<CustomInput
							v-model="zipCodeObj"
							placeholder="PLZ"
							type="text"
							class="mt-3"
							:rules="[$rules.required, $rules.lengthAbove3, $rules.lengthBelow20]"
						></CustomInput>
					</div>
					<div class="flex-1 ml-3">
						<CustomInput
							v-model="cityNameObj"
							placeholder="Ort"
							type="text"
							class="mt-3"
							:rules="[$rules.required, $rules.lengthAbove3, $rules.lengthBelow50]"
						></CustomInput>
					</div>
				</div>
			</CustomForm>
		</div>

		<div class="mt-10 flex flex-row">
			<div class="flex-1"></div>
			<CustomButton
				:text="buttonText"
				default-width
				:loading="isLoading"
				@click="addShop"
			></CustomButton>
		</div>
	</PageCard>
</template>

<script>
import PageCard from '../../components/PageCard'
import CustomInput from '../../components/inputs/CustomInput'
import CustomButton from '../../components/inputs/CustomButton'
import CustomForm from '../../components/inputs/CustomForm'

export default {
	name: 'ShopInputCard',
	components: {
		CustomForm,
		PageCard,
		CustomButton,
		CustomInput,
	},
	props: {
		identifier: {
			type: String,
			default: '',
		},
		buttonText: {
			type: String,
			default: 'Hinzufügen',
		},
	},
	data() {
		return {
			isLoading: false,
			nameObj: {
				text: '',
				errorMessage: '',
			},
			streetNameObj: {
				text: '',
				errorMessage: '',
			},
			streetNumberObj: {
				text: '',
				errorMessage: '',
			},
			zipCodeObj: {
				text: '',
				errorMessage: '',
			},
			cityNameObj: {
				text: '',
				errorMessage: '',
			},
		}
	},
	created() {
		if (this.identifier) {
			this.getShopInfo(this.identifier)
		}
	},
	methods: {
		getShopInfo: function (identifier) {
			let context = this
			this.$http
				.get('/panel/customer/getSingleShopInfo?identifier=' + identifier)
				.then((response) => {
					const status = response.data.status.value
					if (response.status === 200 && status === 100) {
						const payload = response.data.body
						context.nameObj.text = payload.name
						context.streetNameObj.text = payload.address.streetName
						context.streetNumberObj.text = payload.address.streetNumber
						context.zipCodeObj.text = payload.address.zipCode
						context.cityNameObj.text = payload.address.cityName
					} else {
						console.log(response)
					}
				})
				.catch((err) => {
					console.log(err)
				})
		},
		addShop: function () {
			if (this.$refs['customerAddNewShopForm'].validate()) {
				this.isLoading = true

				let context = this
				this.$http
					.post('/panel/customer/addShop', {
						identifier: context.identifier,
						shopName: context.nameObj.text,
						streetName: context.streetNameObj.text,
						streetNumber: context.streetNumberObj.text,
						zipCode: context.zipCodeObj.text,
						city: context.cityNameObj.text,
					})
					.then((response) => {
						const status = response.data.status.value
						if (response.status === 200 && status === 100) {
							context.$toast.success(
								'Neues Geschäft hinzugefügt',
								context.$toaststyle.successMid
							)

							context.$router.push({
								name: 'customerShopListPage',
							})
							return
						}

						switch (status) {
							case 1051:
							case 1052:
								context.nameObj.errorMessage = 'Eingabe ungültig'
								break
							case 1053:
								context.$toast.error(
									'Name bereits vergeben',
									context.$toast.errorShort
								)
								context.nameObj.errorMessage = 'Name bereits vergeben'
								break
							case 1061:
							case 1062:
								context.streetNameObj.errorMessage = 'Eingabe ungültig'
								break
							case 1071:
							case 1072:
								context.streetNumberObj.errorMessage = 'Eingabe ungültig'
								break
							case 1063:
							case 1064:
								context.zipCodeObj.errorMessage = 'Eingabe ungültig'
								break
							case 1065:
							case 1066:
								context.cityObj.errorMessage = 'Eingabe ungültig'
								break
						}
					})
					.catch((err) => {
						console.log(err)

						context.$toast.error(err.message, context.$toaststyle.errorMid)
					})
					.finally(() => {
						context.isLoading = false
					})
			}
		},
	},
}
</script>

<style scoped></style>