<template>
	<div class="flex flex-1 flex-row">
		<div
			id="reseller-navbar"
			class="fixed top-0 left-0 z-30 group flex flex-col bg-navbg_light h-full w-20 hover:w-80 rounded-br-nav rounded-tr-nav shadow-nav"
			@mouseover="hoverOn"
			@mouseleave="hoverOff"
		>
			<NavbarLogoItem></NavbarLogoItem>

			<NavbarItem
				:active="mainRoute === 'home'"
				text="Startseite"
				:image="this.$icons.home"
				:image-active="this.$icons.homeActive"
				@selected="changeToHomePage"
			></NavbarItem>

			<NavbarItem
				:active="
					mainRoute === 'customers' ||
					mainRoute === 'addCustomer' ||
					mainRoute === 'customersPending'
				"
				text="Kundenübersicht"
				:image="this.$icons.users"
				:image-active="this.$icons.usersActive"
				@selected="changeToCustomerListPage"
			></NavbarItem>

			<NavbarSubItem
				v-show="hoverItem"
				text="Ausstehende Kontoaktivierungen"
				:active="mainRoute === 'customersPending'"
				:image="this.$icons.clock"
				:image-active="this.$icons.clockActive"
				@selected="changeToCustomerPendingListPage"
			></NavbarSubItem>

			<NavbarSubItem
				v-show="hoverItem"
				text="Kunden hinzufügen"
				:active="mainRoute === 'addCustomer'"
				:image="this.$icons.add"
				:image-active="this.$icons.addActive"
				@selected="changeToCustomerAddPage"
			></NavbarSubItem>

			<NavbarItem
				:active="
					mainRoute === 'boxes' || mainRoute === 'addBox' || mainRoute === 'pendingBoxes'
				"
				text="Boxübersicht"
				:image="this.$icons.box"
				:image-active="this.$icons.boxActive"
				@selected="changeToBoxListPage"
			></NavbarItem>

			<!--
			<NavbarSubItem
				v-show="hoverItem"
				text="Ausstehende Zuweisungen"
				:active="mainRoute === 'pendingBoxes'"
				:image="this.$icons.clock"
				:image-active="this.$icons.clockActive"
				@selected="changeToBoxPendingListPage"
			></NavbarSubItem>
			-->

			<NavbarSubItem
				v-show="hoverItem"
				text="Neue cybo-box zuweisen"
				:active="mainRoute === 'addBox'"
				:image="this.$icons.add"
				:image-active="this.$icons.addActive"
				@selected="changeToAddBoxPage"
			></NavbarSubItem>

			<div class="flex-1"></div>

			<NavbarItem
				:active="mainRoute === 'profile'"
				text="Profil"
				:image="this.$icons.user"
				:image-active="this.$icons.userActive"
				@selected="changeToProfilePage"
			></NavbarItem>

			<NavbarItem
				:active="false"
				class="mb-5"
				text="Logout"
				:image="this.$icons.logout"
				:image-active="this.$icons.logoutActive"
				@selected="logout"
			></NavbarItem>

			<div class="text-xs text-navfg_light w-80 h-16 overflow-hidden">
				<div v-if="isHovering">
					<div class="hover:underline cursor-pointer" @click="changeToImpressum">
						Impressum
					</div>
					<div class="hover:underline cursor-pointer" @click="changeToPrivacyPolicy">
						Datenschutzerklärung
					</div>
					<div>Copyright © 2022 NovaNode GmbH</div>
				</div>
			</div>
		</div>

		<router-view :key="millis" class="flex-1 min-h-screen"></router-view>
	</div>
</template>

<script>
import NavbarItem from '../components/NavbarItem'
import NavbarLogoItem from '../components/NavbarLogoItem'
import NavbarSubItem from '../components/NavbarSubItem'

export default {
	name: 'ResellerContainerPage',
	components: {
		NavbarSubItem,
		NavbarItem,
		NavbarLogoItem,
	},
	data() {
		return {
			millis: 0,
			mainRoute: '',
			hoverItem: undefined,
			isHovering: false,
		}
	},
	created() {
		this.updateRoutes()
	},
	beforeUpdate() {
		this.updateRoutes()
	},
	methods: {
		hoverOn: function (itemName) {
			this.isHovering = true
			this.hoverItem = itemName
		},
		hoverOff: function () {
			this.isHovering = false
			this.hoverItem = undefined
		},
		updateKey: function () {
			this.millis = new Date().getMilliseconds()
		},
		updateRoutes: function () {
			let route = this.$route.path
			this.mainRoute = route.replace('/reseller/', '')
			const indextSlash = this.mainRoute.indexOf('/')
			if (indextSlash > -1) {
				this.mainRoute = this.mainRoute.substr(0, this.mainRoute.indexOf('/'))
			}
		},
		changeToHomePage: function () {
			this.updateKey()
			this.$breadcrumbs.clearHistory()
			this.route = '/reseller/home'
			this.$router.push(this.route).catch(() => {})
		},
		changeToCustomerListPage: function () {
			this.updateKey()
			this.$breadcrumbs.clearHistory()
			this.route = '/reseller/customers'
			this.$router.push(this.route).catch(() => {})
		},
		changeToCustomerPendingListPage: function () {
			this.updateKey()
			this.$breadcrumbs.clearHistory()
			this.route = '/reseller/customersPending'
			this.$router.push(this.route).catch(() => {})
		},
		changeToCustomerAddPage: function () {
			this.updateKey()
			this.$breadcrumbs.clearHistory()
			this.route = '/reseller/addCustomer'
			this.$router.push(this.route).catch(() => {})
		},
		changeToBoxPendingListPage: function () {
			this.updateKey()
			this.$breadcrumbs.clearHistory()
			this.route = '/reseller/pendingBoxes'
			this.$router.push(this.route).catch(() => {})
		},
		changeToBoxListPage: function () {
			this.updateKey()
			this.$breadcrumbs.clearHistory()
			this.route = '/reseller/boxes'
			this.$router.push(this.route).catch(() => {})
		},
		changeToAddBoxPage: function () {
			this.updateKey()
			this.$breadcrumbs.clearHistory()
			this.route = '/reseller/addBox'
			this.$router.push(this.route).catch(() => {})
		},
		changeToProfilePage: function () {
			this.updateKey()
			this.$breadcrumbs.clearHistory()
			this.route = '/reseller/profile'
			this.$router.push(this.route).catch(() => {})
		},
		logout: function () {
			this.$breadcrumbs.clearHistory()
			this.$store.dispatch('auth/logout', {
				targetRoute: '/login',
			})
		},
		changeToImpressum: function () {
			const currentPageName = this.$route.name

			this.$router.push({
				name: 'legalNoticePage',
				params: {
					from: currentPageName,
				},
			})
		},
		changeToPrivacyPolicy: function () {
			const currentPageName = this.$route.name

			this.$router.push({
				name: 'privacyPolicyPage',
				params: {
					from: currentPageName,
				},
			})
		},
	},
}
</script>

<style scoped>
#reseller-navbar {
	transition: all 0.15s;
}
</style>