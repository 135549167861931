<template>
	<div :key="key" class="flex flex-col">
		<PageHeader headline="Bonarchiv"></PageHeader>

		<PageBody>
			<div class="container mx-auto pt-4">
				<PageCard title="Bonarchiv">
					<div class="text-center">
						<div class="bg-listbg_dark rounded-3xl px-4 mb-4">
							<div class="flex flex-row">
								<div
									class="flex flex-row text-left pt-2 pb-2 text-xs font-bold text-page_card_header_text cursor-pointer hover:underline"
									@click="toggleFilters"
								>
									<img
										:src="
											showFilters ? this.$icons.mdiMinus : this.$icons.mdiPlus
										"
										alt=""
										class="h-4"
									/>
									<div class="ml-1 select-none">
										Listenfilter {{ showFilters ? 'ausblenden' : 'einblenden' }}
									</div>
								</div>
								<div class="flex flex-1"></div>
							</div>
							<div v-if="showFilters" class="flex flex-row pb-3 pt-2">
								<div class="flex flex-row">
									<div class="mx-1">
										<!-- TODO: REMOVED UNTIL FIXED -->
										<div
											v-if="false"
											class="relative border border-new_fg rounded-3xl px-4 py-2 text-xs"
										>
											<div
												class="absolute left-3 -top-3 text-new_fg text-xs px-2 bg-dd_bg_inactive select-none"
											>
												Datum
											</div>
											<date-range-picker
												ref="picker"
												v-model="filter.date.dateRange"
												opens="right"
												:locale-data="{
													firstDay: 1,
													format: 'dd-mm-yyyy HH:mm:ss',
												}"
												:min-date="filter.date.minDate"
												:max-date="filter.date.maxDate"
												:time-picker="true"
												:time-picker24hour="true"
												:show-week-numbers="true"
												:show-dropdowns="true"
												:auto-apply="false"
												:show-ranges="true"
												:linked-calendars="false"
												@update="setDateFilter"
											>
												<template
													v-slot:input="picker"
													style="min-width: 350px"
												>
													{{
														picker.startDate.toLocaleDateString('de-DE')
													}}
													-
													{{ picker.endDate.toLocaleDateString('de-DE') }}
												</template>
											</date-range-picker>
										</div>
									</div>
									<div class="mx-1">
										<CustomInputFilter
											v-model="filter.bonIdObj"
											class="w-36"
											title="Bon ID"
										></CustomInputFilter>
									</div>
									<div class="mx-1">
										<CustomInputFilter
											v-model="filter.boxUserIdObj"
											class="w-36"
											title="Boxnummer"
										></CustomInputFilter>
									</div>
									<div class="mx-1">
										<CustomInputFilter
											v-model="filter.boxNameObj"
											class="w-44"
											title="Bezeichnung der Box"
										></CustomInputFilter>
									</div>
									<div class="mx-1">
										<CustomInputFilter
											v-model="filter.shopNameObj"
											class="w-44"
											title="Geschäftsname"
										></CustomInputFilter>
									</div>
									<div class="">
										<CustomButtonIcon
											:icon="this.$icons.cancel"
											tooltip="Alle Filter zurücksetzen"
											bg-color="bg-red-300"
											class="ml-2 mt-1"
											@click="resetAllFilters"
										></CustomButtonIcon>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="text-center">
						<div class="bg-listbg_dark rounded-3xl px-4 py-4">
							<div class="flex flex-row text-list_textheader_dark h-9 text-xs">
								<div class="flex-1 h-full py-1 font-bold text-left pl-4">
									Bon ID
								</div>
								<div class="flex-1 h-full py-1 font-bold text-left pl-4">
									Boxnummer
								</div>
								<div class="flex-1 h-full py-1 font-bold text-left pl-4">
									Bezeichnung der Box
								</div>
								<div class="flex-1 h-full py-1 font-bold text-left pl-4">
									Geschäftsname
								</div>
								<div
									class="w-44 flex flex-row h-full py-1 font-bold text-left pl-4 cursor-pointer hover:underline"
									@click="sortBy('createdDate')"
								>
									<div class="flex-1"></div>
									<div class="flex-1">Erstellungszeitpunkt</div>
									<div v-if="sort.column === 'createdDate'" class="flex-1 pl-4">
										<img
											v-if="sort.ascending"
											:src="this.$icons.arrowUp"
											alt=""
											class="h-4"
										/>
										<img
											v-if="!sort.ascending"
											:src="this.$icons.arrowDown"
											alt=""
											class="h-4"
										/>
									</div>
								</div>
							</div>

							<div>
								<!-- click method is not on this elem so that the button click does not trigger it -->
								<div
									v-for="bon in bonList"
									:key="bon.id"
									class="flex flex-row text-new_fg cursor-pointer rounded-3xl hover:bg-white text-xs border-t-2 border-white"
								>
									<div
										class="flex-1 h-full py-3 text-left pl-4"
										@click="openBonPage(bon.id)"
									>
										{{ bon.hashcode }}
									</div>
									<div
										class="flex-1 h-full py-3 text-left pl-4"
										@click="openBonPage(bon.id)"
									>
										{{ bon.boxUserId }}
									</div>
									<div
										class="flex-1 h-full py-3 text-left pl-4"
										@click="openBonPage(bon.id)"
									>
										{{ bon.boxName }}
									</div>
									<div
										class="flex-1 h-full py-3 text-left pl-4"
										@click="openBonPage(bon.id)"
									>
										{{ bon.shopName }}
									</div>
									<div
										class="w-44 h-full py-3 text-right pr-4"
										@click="openBonPage(bon.id)"
									>
										{{
											$utils.date2timeString(new Date(bon.used)) +
											' ' +
											$utils.date2dateString(new Date(bon.used))
										}}
									</div>
								</div>
							</div>

							<div v-if="bonList.length === 0" class="text-xs">
								Keine Bons verfügbar
							</div>
						</div>
					</div>

					<CustomPagination
						:current-page-num="page.current"
						:max-page-num="page.max"
						:total-elements="page.totalElements"
						class="mt-4"
						@previous="previousPage"
						@next="nextPage"
					></CustomPagination>
				</PageCard>
			</div>
		</PageBody>

		<LoadingScreen :has-loaded="hasLoaded"></LoadingScreen>
	</div>
</template>

<script>
import PageHeader from '../components/PageHeader'
import PageBody from '../components/PageBody'
import PageCard from '../components/PageCard'

import LoadingScreen from '../components/LoadingScreen'
import CustomPagination from '../components/CustomPagination'

import DateRangePicker from 'vue2-daterange-picker'
import CustomInputFilter from '../components/inputs/CustomInputFilter'
import moment from 'moment'
import CustomButtonIcon from '../components/inputs/CustomButtonIcon'

export default {
	name: 'CustomerBonListPage',
	components: {
		CustomButtonIcon,
		CustomInputFilter,
		CustomPagination,
		LoadingScreen,
		PageCard,
		PageBody,
		PageHeader,
		DateRangePicker,
	},
	data() {
		return {
			key: new Date().getMilliseconds(),
			loaded: {
				filters: false,
				bons: false,
			},
			bonList: [],
			showFilters: false,
			filter: {
				bonIdObj: {
					text: '',
				},
				boxUserIdObj: {
					text: '',
				},
				boxNameObj: {
					text: '',
				},
				shopNameObj: {
					text: '',
				},
				date: {
					minDate: new Date('2020-01-01T10:00:00.000Z'),
					maxDate: this.getMidnightOfCurrentDay(),
					dateRange: {
						startDate: new Date('2020-01-01T10:00:00.000Z'),
						endDate: this.getMidnightOfCurrentDay(),
					},
				},
			},
			sort: {
				column: 'createdDate',
				ascending: false,
			},
			page: {
				entriesPerPage: 10,
				current: 1,
				target: 1,
				max: 1,
				totalElements: 0,
			},
		}
	},
	computed: {
		hasLoaded: function () {
			return this.loaded.bons
		},
	},
	watch: {
		'page.current': function (e) {
			let query = Object.assign({}, this.$route.query)

			query.page = parseInt(e)

			this.$router.push({ query }).catch(() => {
				/* catches the redundant navigation error on page load */
			})
		},
		'filter.bonIdObj.text': function (e) {
			let query = Object.assign({}, this.$route.query)

			if (!e || e.length === 0) {
				delete query.bonId
			} else {
				query.bonId = e
			}

			this.$router.push({ query }).catch(() => {
				/* catches the redundant navigation error on page load */
			})

			this.getBonList()
		},
		'filter.boxNameObj.text': function (e) {
			let query = Object.assign({}, this.$route.query)

			if (!e || e.length === 0) {
				delete query.boxName
			} else {
				query.boxName = e
			}

			this.$router.push({ query }).catch(() => {
				/* catches the redundant navigation error on page load */
			})

			this.getBonList()
		},
		'filter.boxUserIdObj.text': function (e) {
			let query = Object.assign({}, this.$route.query)

			if (!e || e.length === 0) {
				delete query.boxId
			} else {
				query.boxId = e
			}

			this.$router.push({ query }).catch(() => {
				/* catches the redundant navigation error on page load */
			})

			this.getBonList()
		},
		'filter.shopNameObj.text': function (e) {
			let query = Object.assign({}, this.$route.query)

			if (!e || e.length === 0) {
				delete query.shopName
			} else {
				query.shopName = e
			}

			this.$router.push({ query }).catch(() => {
				/* catches the redundant navigation error on page load */
			})

			this.getBonList()
		},
	},
	created() {
		const queryParams = this.$route.query

		if (queryParams.page) {
			this.updateTargetPage(parseInt(queryParams.page))
			this.updateCurrentPage(parseInt(queryParams.page))
		}

		let hasParam = false

		if (queryParams.bonId) {
			this.filter.bonIdObj.text = queryParams.bonId
			hasParam = true
		}
		if (queryParams.boxId) {
			this.filter.boxUserIdObj.text = queryParams.boxId
			hasParam = true
		}
		if (queryParams.boxName) {
			this.filter.boxNameObj.text = queryParams.boxName
			hasParam = true
		}
		if (queryParams.shopName) {
			this.filter.shopNameObj.text = queryParams.shopName
			hasParam = true
		}

		if (hasParam) {
			this.showFilters = true
		}

		this.getBonList()
	},
	methods: {
		previousPage: function () {
			this.page.current = Math.max(0, this.page.current - 1)

			this.getBonList()
		},
		nextPage: function () {
			this.page.current = Math.min(this.page.max, this.page.current + 1)

			this.getBonList()
		},
		sortBy: function (column) {
			if (column === this.sort.column) {
				this.sort.ascending = !this.sort.ascending
			} else {
				this.sort.column = column
				this.sort.ascending = false
			}

			this.getBonList()
		},
		toggleFilters: function () {
			if (this.showFilters) {
				// do not hide filters if filters are set
				if (
					this.filter.bonIdObj.text.length === 0 &&
					this.filter.boxUserIdObj.text.length === 0 &&
					this.filter.boxNameObj.text.length === 0 &&
					this.filter.shopNameObj.text.length === 0
				) {
					this.showFilters = false
				} else {
					this.$toast.info('Es sind noch Filter ausgewählt', this.$toaststyle.errorMid)
				}
			} else {
				this.showFilters = true
			}
		},
		resetAllFilters: function () {
			this.filter.bonIdObj.text = ''
			this.filter.boxUserIdObj.text = ''
			this.filter.boxNameObj.text = ''
			this.filter.shopNameObj.text = ''

			this.$toast.success('Listenfilter zurückgesetzt', this.$toaststyle.successMid)
		},
		getMidnightOfCurrentDay: function () {
			let current = new Date()
			current.setHours(23, 59, 59)
			return current
		},
		updateCurrentPage: function (currentPage) {
			this.page.current = currentPage
		},
		updateTargetPage: function (targetPage) {
			this.page.target = targetPage
		},
		updatePages: function (maxPage) {
			this.page.max = maxPage
		},
		updateFilteredBonIds: function (filteredBonIds) {
			this.filteredBonIds = filteredBonIds
		},
		setDateFilter: function () {
			console.log('set date filter')
		},
		getBonList: function () {
			const context = this

			context.$http
				.post('/panel/customer/getBonList', {
					page: context.page.current - 1,
					sortAscending: context.sort.ascending,
					bonId: context.filter.bonIdObj.text,
					boxUserId: context.filter.boxUserIdObj.text,
					boxName: context.filter.boxNameObj.text,
					shopName: context.filter.shopNameObj.text,
				})
				.then((response) => {
					const status = response.data.status.value
					if (response.status === 200 && status === 100) {
						context.bonList = response.data.body.bonList
						context.page.max = response.data.body.pageInfo.numPages
						context.page.totalElements = response.data.body.pageInfo.numEntries

						context.bonList.forEach((item) => {
							item.createdDate = moment(item.created, 'HH:mm:ss DD.MM.YYYY').toDate()
						})
					}
				})
				.catch((err) => {
					console.log(err)
				})
				.finally(() => {
					context.loaded.bons = true
				})
		},
		openBonPage: function (bonId) {
			// must be named route in order to pass props, does not work with path
			this.$router.push({
				name: 'customerBonPage',
				params: {
					identifier: bonId,
					bonIds: this.filteredBonIds,
					bonListPath: this.$route.fullPath,
				},
			})
		},
	},
}
</script>

<style scoped></style>