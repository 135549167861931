const url = require('url')
const SockJS = require('sockjs-client')
const StompJS = require('@stomp/stompjs')

import Vue from 'vue'

import { TokenService } from '../../services/token.service'

const StatusModule = {
	namespaced: true,
	state: {
		client: undefined,
		boxList: {},
	},
	getter: {},
	mutations: {
		initClient: function (state, API_BASEURL) {
			console.log('status websocket init client')

			state.client = new StompJS.Client({
				reconnectDelay: 5000,
				heartbeatIncoming: 4000,
				heartbeatOutgoing: 4000,
			})

			const authHeader = {
				role: 'ADMIN_PANEL',
				payload: {
					token: TokenService.getToken(),
				},
			}

			state.client.connectHeaders = {
				login: JSON.stringify(authHeader),
			}

			state.client.webSocketFactory = function () {
				return new SockJS(url.resolve(API_BASEURL, '/socket'))
			}

			state.client.onConnect = function () {
				console.log('connected to websocket')

				let sub = state.client.subscribe('/topic/login', function (message) {
					const payload = JSON.parse(message.body)

					for (const box of payload) {
						Vue.set(state.boxList, box['activationKey'], box)
					}

					sub.unsubscribe()
				})

				state.client.publish({
					destination: '/socket/login',
					body: JSON.stringify({ message: 'Hello message to queue' }),
				})

				state.client.subscribe('/topic/boxAdd', function (message) {
					const payload = JSON.parse(message.body)

					if (
						payload === undefined ||
						payload['activationKey'] === undefined ||
						payload['activationKey'].length === 0
					) {
						console.log('box without activation key connected')
						return
					}

					let box = state.boxList[payload['activationKey']]
					box['versionNumber'] = payload['versionNumber']
					box['serialNumber'] = payload['serialNumber']
					box['status'] = payload['status']
					box['online'] = true
					box['hardwareType'] = payload['hardwareType']
					box['theme'] = payload['theme']
				})

				state.client.subscribe('/topic/boxRemove', function (message) {
					const payload = JSON.parse(message.body)

					if (
						payload === undefined ||
						payload['activationKey'] === undefined ||
						payload['activationKey'].length === 0
					) {
						console.log('box without activation key disconnected')
						return
					}

					let box = state.boxList[payload['activationKey']]
					box['versionNumber'] = undefined
					box['serialNumber'] = undefined
					box['status'] = undefined
					box['online'] = false
					box['hardwareType'] = undefined
					box['theme'] = undefined
				})

				state.client.subscribe('/topic/boxStatus', function (message) {
					const payload = JSON.parse(message.body)

					let box = state.boxList[payload.activationKey]
					box['status'] = payload.status
					box['latestActivity'] = new Date(payload.timestamp)
				})
			}

			state.client.onWebSocketClose = function (message) {
				console.log('on websocket close')

				if (message.code === 1002 && message.reason === '') {
					console.log('no access!')
					state.client.deactivate()
				} else {
					console.log('connection lost..')
				}
			}
			state.client.onWebSocketError = function (err) {
				console.log('on websocket error')
				console.log(err)
			}
		},
		activateClient: function (state) {
			state.client.activate()
		},
		deactivateClient: function (state) {
			state.client.deactivate()
		},
	},
	actions: {
		init: function ({ commit }, API_BASEURL) {
			commit('initClient', API_BASEURL)
		},
		connect: function ({ commit }) {
			commit('activateClient')
		},
		disconnect: function ({ commit }) {
			commit('deactivateClient')
		},
	},
}

export { StatusModule }
