<template>
	<PageCard title="Übersicht der von Ihnen verwalteten cybo-boxen" class="relative">
		<CustomButtonIcon
			:icon="this.$icons.addActive"
			tooltip="Neue Box zuweisen"
			class="absolute top-2 right-4"
			@click="openBoxAddPage"
		></CustomButtonIcon>

		<div class="text-center">
			<div class="bg-listbg_dark rounded-3xl px-4 mb-4 transition delay-150">
				<div class="flex flex-row">
					<div
						class="flex flex-row text-left pt-2 pb-2 text-xs font-bold text-page_card_header_text cursor-pointer hover:underline"
						@click="toggleFilters"
					>
						<img
							:src="showFilters ? this.$icons.mdiMinus : this.$icons.mdiPlus"
							alt=""
							class="h-4"
						/>
						<div class="ml-1 select-none">
							Listenfilter {{ showFilters ? 'ausblenden' : 'einblenden' }}
						</div>
					</div>
					<div class="flex flex-1"></div>
				</div>
				<div v-if="showFilters" class="flex flex-row pb-3 pt-2">
					<div class="mx-1">
						<CustomDropdownFilterSmall
							:data="filter.status"
							class="w-52"
							title="Status"
						></CustomDropdownFilterSmall>
					</div>
					<div class="mx-1">
						<CustomInputFilter
							v-model="filter.boxUserIdObj"
							class="w-36"
							title="Boxnummer"
						></CustomInputFilter>
					</div>
					<div class="mx-1">
						<CustomInputFilter
							v-model="filter.customerUserIdObj"
							class="w-36"
							title="Kundennummer"
						></CustomInputFilter>
					</div>
					<div class="mx-1">
						<CustomInputFilter
							v-model="filter.customerEmailObj"
							class="w-48"
							title="E-Mail-Adresse"
						></CustomInputFilter>
					</div>
					<div class="">
						<CustomButtonIcon
							:icon="this.$icons.cancel"
							tooltip="Alle Filter zurücksetzen"
							bg-color="bg-red-300"
							class="ml-2 mt-1"
							@click="resetAllFilters"
						></CustomButtonIcon>
					</div>
				</div>
			</div>

			<div class="bg-listbg_dark rounded-3xl px-4 py-4">
				<div class="flex flex-row text-list_textheader_dark h-9 text-xs">
					<div class="w-48 h-full py-1 font-bold">Status</div>
					<div class="w-32 h-full py-1 font-bold text-left pl-4">Boxnummer</div>
					<div class="w-32 h-full py-1 font-bold text-left pl-4">Kundennummer</div>
					<div class="flex-1 h-full py-1 text-left pl-4 font-bold">E-Mail</div>
					<div
						class="w-48 flex flex-row h-full py-1 font-bold text-left pl-4 cursor-pointer hover:underline"
						@click="sortBy('assigned')"
					>
						<div class="flex-1"></div>
						<div class="flex-1">Zuweisungsdatum</div>
						<div v-if="sort.column === 'assigned'" class="flex-1 pl-4">
							<img
								v-if="sort.ascending"
								:src="this.$icons.arrowUp"
								alt=""
								class="h-4"
							/>
							<img v-else :src="this.$icons.arrowDown" alt="" class="h-4" />
						</div>
					</div>
				</div>

				<div v-if="boxList.length > 0">
					<!-- click method is not on this elem so that the button click does not trigger it -->
					<div
						v-for="box in boxList"
						:key="box.id"
						class="flex flex-row text-new_fg cursor-pointer rounded-3xl hover:bg-white text-xs border-t-2 border-white"
					>
						<div
							class="h-full w-48 py-3 pl-4 text-left"
							@click="$emit('openBoxPage', box.id)"
						>
							<BadgeFatGreen
								v-if="box.status === 'ACTIVE'"
								text="IN BETRIEB"
							></BadgeFatGreen>
							<BadgeFatPurple
								v-else-if="box.status === 'NOT_ACTIVATED'"
								text="NOCH NICHT AKTIVIERT"
							></BadgeFatPurple>
							<BadgeFatRed v-else text="DEAKTIVIERT"></BadgeFatRed>
						</div>
						<div
							class="w-32 h-full py-3 text-left pl-4"
							@click="$emit('openBoxPage', box.id)"
						>
							{{ box['boxId'] }}
						</div>
						<div
							class="w-32 h-full py-3 text-left pl-4"
							@click="$emit('openBoxPage', box.id)"
						>
							{{ box.customerId }}
						</div>
						<div
							class="flex-1 h-full py-3 text-left pl-4"
							@click="$emit('openBoxPage', box.id)"
						>
							{{ box.customerEmail }}
						</div>
						<div
							class="w-48 h-full py-3 text-right pr-4"
							@click="$emit('openBoxPage', box.id)"
						>
							{{
								$utils.date2timeString(new Date(box.assigned)) +
								' ' +
								$utils.date2dateString(new Date(box.assigned))
							}}
						</div>
					</div>
				</div>

				<div v-else class="bg-listbg_dark rounded-3xl px-4 py-4 text-list_text_dark">
					Keine cybo-box gefunden
				</div>
			</div>

			<CustomPagination
				:current-page-num="page.current"
				:max-page-num="page.max"
				:total-elements="page.totalElements"
				class="mt-4"
				@previous="previousPage"
				@next="nextPage"
			></CustomPagination>
		</div>
	</PageCard>
</template>

<script>
import PageCard from '../../components/PageCard'

import CustomButtonIcon from '../../components/inputs/CustomButtonIcon'
import CustomPagination from '../../components/CustomPagination'
import BadgeFatGreen from '../../components/badges/BadgeFatGreen'
import BadgeFatPurple from '../../components/badges/BadgeFatPurple'
import BadgeFatRed from '../../components/badges/BadgeFatRed'
import CustomInputFilter from '../../components/inputs/CustomInputFilter'
import CustomDropdownFilterSmall from '../../components/inputs/CustomDropdownFilterSmall'

export default {
	name: 'BoxList',
	components: {
		CustomDropdownFilterSmall,
		CustomInputFilter,
		BadgeFatRed,
		BadgeFatPurple,
		BadgeFatGreen,
		CustomPagination,
		CustomButtonIcon,
		PageCard,
	},
	props: {
		loaded: {
			type: Object,
			default() {
				return {
					boxActive: false,
				}
			},
		},
	},
	data() {
		return {
			boxList: [],
			page: {
				entriesPerPage: 10,
				current: 1,
				max: 1,
				totalElements: 0,
			},
			sort: {
				column: 'assigned',
				ascending: false,
			},
			filter: {
				status: {
					selected: { text: 'Keine Auswahl', value: 'NONE' },
					options: [
						{ text: 'Keine Auswahl', value: 'NONE' },
						{ text: 'NOCH NICHT AKTIVIERT', value: 'NOT_ACTIVATED' },
						{ text: 'AKTIVIERT', value: 'ACTIVE' },
						{ text: 'DEAKTIVIERT', value: 'DEACTIVATED' },
					],
				},
				boxUserIdObj: {
					text: '',
				},
				customerUserIdObj: {
					text: '',
				},
				customerEmailObj: {
					text: '',
				},
			},
			showFilters: false,
		}
	},
	watch: {
		'filter.status.selected.value': function (e) {
			let query = Object.assign({}, this.$route.query)

			if (!e || e.length === 0 || e === 'NONE') {
				delete query.status
			} else {
				query.status = e
			}

			this.$router.push({ query }).catch(() => {
				/* catches the redundant navigation error on page load */
			})

			this.getBoxList()
		},
		'filter.boxUserIdObj.text': function (e) {
			let query = Object.assign({}, this.$route.query)

			if (!e || e.length === 0) {
				delete query.boxId
			} else {
				query.boxId = e
			}

			this.$router.push({ query }).catch(() => {
				/* catches the redundant navigation error on page load */
			})

			this.getBoxList()
		},
		'filter.customerUserIdObj.text': function (e) {
			let query = Object.assign({}, this.$route.query)

			if (!e || e.length === 0) {
				delete query.customer
			} else {
				query.customer = e
			}

			this.$router.push({ query }).catch(() => {
				/* catches the redundant navigation error on page load */
			})

			this.getBoxList()
		},
		'filter.customerEmailObj.text': function (e) {
			let query = Object.assign({}, this.$route.query)

			if (!e || e.length === 0) {
				delete query.email
			} else {
				query.email = e
			}

			this.$router.push({ query }).catch(() => {
				/* catches the redundant navigation error on page load */
			})

			this.getBoxList()
		},
	},
	async created() {
		const queryParams = this.$route.query

		if (queryParams.page) {
			this.updateTargetPage(parseInt(queryParams.page))
			this.updateCurrentPage(parseInt(queryParams.page))
		}

		let hasParam = false

		if (queryParams.status) {
			if (queryParams.status === 'ACTIVE') {
				this.filter.status.selected = { text: 'AKTIVIERT', value: 'ACTIVE' }
				hasParam = true
			} else if (queryParams.status === 'NOT_ACTIVATED') {
				this.filter.status.selected = {
					text: 'NOCH NICHT AKTIVIERT',
					value: 'NOT_ACTIVATED',
				}
				hasParam = true
			} else if (queryParams.status === 'DEACTIVATED') {
				this.filter.status.selected = { text: 'DEAKTIVIERT', value: 'DEACTIVATED' }
				hasParam = true
			}
		}
		if (queryParams.boxId) {
			this.filter.boxUserIdObj.text = queryParams.boxId
			hasParam = true
		}
		if (queryParams.customer) {
			this.filter.customerUserIdObj.text = queryParams.customer
			hasParam = true
		}
		if (queryParams.email) {
			this.filter.customerEmailObj.text = queryParams.email
			hasParam = true
		}

		if (hasParam) {
			this.showFilters = true
		}

		this.getBoxList()
	},
	methods: {
		previousPage: function () {
			this.page.current = Math.max(0, this.page.current - 1)

			this.getBoxList()
		},
		nextPage: function () {
			this.page.current = Math.min(this.page.max, this.page.current + 1)

			this.getBoxList()
		},
		toggleFilters: function () {
			if (this.showFilters) {
				// do not hide filters if filters are set
				if (
					this.filter.boxUserIdObj.text.length === 0 &&
					this.filter.customerUserIdObj.text.length === 0 &&
					this.filter.customerEmailObj.text.length === 0 &&
					this.filter.status.selected.value === 'NONE'
				) {
					this.showFilters = false
				} else {
					this.$toast.info('Es sind noch Filter ausgewählt', this.$toaststyle.errorMid)
				}
			} else {
				this.showFilters = true
			}
		},
		resetAllFilters: function () {
			this.filter.status.selected = Object.assign({}, this.filter.status.options[0])
			this.filter.boxUserIdObj.text = ''
			this.filter.customerUserIdObj.text = ''
			this.filter.customerEmailObj.text = ''

			this.$toast.success('Listenfilter zurückgesetzt', this.$toaststyle.successMid)
		},
		sortBy: function (column) {
			if (column === this.sort.column) {
				this.sort.ascending = !this.sort.ascending
			} else {
				this.sort.column = column
				this.sort.ascending = false
			}

			this.getBoxList()
		},
		getBoxList: function () {
			const context = this

			let boxStatus = context.filter.status.selected.value
			if (boxStatus === 'NONE') {
				boxStatus = ''
			}

			context.$http
				.post('/panel/reseller/getBoxList', {
					page: context.page.current - 1,
					sortAscending: context.sort.ascending,
					boxStatus: boxStatus,
					boxUserId: context.filter.boxUserIdObj.text,
					customerUserId: context.filter.customerUserIdObj.text,
					customerEmail: context.filter.customerEmailObj.text,
				})
				.then((response) => {
					context.loaded.boxActive = true

					const status = response.data.status.value
					if (response.status === 200 && status === 100) {
						context.page.max = response.data.body.pageInfo.numPages
						context.page.totalElements = response.data.body.pageInfo.numEntries
						context.boxList = response.data.body.boxList
					}
				})
				.catch((err) => {
					console.log(err)
				})
		},
		openBoxAddPage: function () {
			this.$router.push({
				path: '/reseller/addBox',
			})
		},
		updatePages: function (maxPage) {
			this.page.max = maxPage
		},
	},
}
</script>

<style scoped></style>