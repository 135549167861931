<template>
	<div class="flex flex-col">
		<PageHeader headline="Details zu dieser Box"></PageHeader>

		<PageBody v-if="box !== undefined">
			<div class="container mx-auto flex flex-col xl:flex-row pt-10">
				<div class="flex flex-1"></div>

				<PageCard title="" class="mx-auto">
					<div class="flex-col text-left px-6 py-5 min-w-card">
						<div class="border-b border-gray-300 pb-3 mb-3">
							<div class="pl-2 pb-1 text-xs">Archivierte Bons</div>
							<div class="pl-2 text-black">
								{{ box.numStoredBons }}
							</div>
						</div>
						<div class="">
							<div class="pl-2 pb-1 text-xs">Gedruckte Bons</div>
							<div class="pl-2 text-black">
								{{ box.numPrintedBons }}
							</div>
						</div>
						<div class="">
							<div class="p-2"></div>
							<div class="pl-2 pt-2 text-black">
								<CustomButton
									text="Zum Bonarchiv"
									@click="changeToBonArchive"
								></CustomButton>
							</div>
						</div>
					</div>
				</PageCard>

				<div class="mx-auto">
					<PageCard title="">
						<div class="flex-col text-left px-6 pt-5 pb-2 min-w-card">
							<div class="">
								<CustomForm ref="customerChangeBoxNameForm">
									<div class="pl-2 pb-1 text-xs">Bezeichnung der Box</div>
									<div v-if="nameObj.state === 0" class="pl-2 text-black">
										<div v-if="nameObj.text">
											{{ nameObj.text }}
										</div>
										<div v-else class="text-list_text_dark">
											Noch keine Bezeichnung vergeben
										</div>
									</div>
									<div v-else>
										<CustomInput
											:value="nameObj"
											:rules="[
												$rules.required,
												$rules.lengthAbove3,
												$rules.lengthBelow50,
											]"
										></CustomInput>
									</div>
								</CustomForm>
							</div>
						</div>

						<div v-if="nameObj.state === 0" class="flex flex-row">
							<div class="flex-1"></div>
							<CustomButtonIcon
								:icon="this.$icons.edit"
								tooltip="Bearbeiten"
								@click="initEditBoxName"
							></CustomButtonIcon>
							<div class="flex-1"></div>
						</div>
						<div v-else class="flex flex-row">
							<div class="flex-1"></div>
							<CustomButtonIcon
								:icon="this.$icons.cancel"
								tooltip="Abbrechen"
								class="mx-2 bg-btnbg_secondary_light"
								@click="cancelEditBoxName"
							></CustomButtonIcon>
							<CustomButtonIcon
								:icon="this.$icons.confirm"
								tooltip="Speichern"
								class="mx-2"
								@click="editBoxName"
							></CustomButtonIcon>
							<div class="flex-1"></div>
						</div>
					</PageCard>

					<PageCard title="Details zur Box">
						<div class="flex-col text-left px-6 py-5 min-w-card">
							<div class="border-b border-gray-300 pb-3 mb-3">
								<div class="pl-2 pb-1 text-xs">Status</div>
								<div class="pl-2 text-black">
									<BadgeFatGreen
										v-if="box.status === 'ACTIVE'"
										text="IN BETRIEB"
									></BadgeFatGreen>
									<BadgeFatPurple
										v-else-if="box.status === 'NOT_ACTIVATED'"
										text="NOCH NICHT AKTIVIERT"
									></BadgeFatPurple>
									<BadgeFatRed
										v-else-if="box.status === 'DEACTIVATED'"
										text="DEAKTIVIERT"
									></BadgeFatRed>
									<BadgeFatGray v-else text="UNBEKANNT"></BadgeFatGray>
								</div>
							</div>
							<div class="border-b border-gray-300 pb-3 mb-3">
								<div class="pl-2 pb-1 text-xs">Boxnummer</div>
								<div class="pl-2 text-black">
									{{ box.boxId }}
								</div>
							</div>
							<div class="border-b border-gray-300 pb-3 mb-3">
								<div class="pl-2 pb-1 text-xs">Aktivierungsschlüssel</div>
								<div class="pl-2 text-black">
									{{ box.activationKey }}
								</div>
							</div>
							<div class="">
								<div class="pl-2 pb-1 text-xs">Aktivierungsdatum</div>
								<div class="pl-2 text-black">
									{{ box.activated }}
								</div>
							</div>
						</div>
					</PageCard>
				</div>

				<div class="mx-auto">
					<PageCard title="Geschäft">
						<div class="flex-col text-left px-6 py-5 min-w-card">
							<div class="border-b border-gray-300 pb-3 mb-3">
								<div class="pl-2 pb-1 text-xs">Name</div>
								<div class="pl-2 text-black">
									{{ box.shopName }}
								</div>
							</div>
							<div class="border-b border-gray-300 pb-3 mb-3">
								<div class="pl-2 pb-1 text-xs">Anschrift</div>
								<div class="pl-2 text-black">
									{{ box.shopStreetName + ' ' + box.shopStreetNumber }}
									<br />
									{{ box.shopZipCode + ' ' + box.shopCityName }}
								</div>
							</div>
							<div class="">
								<div class="pl-2 pb-1 text-xs">Zuweisungsdatum</div>
								<div class="pl-2 text-black">
									{{ box.assigned }}
								</div>
							</div>

							<div class="">
								<div class="p-2"></div>
								<div class="p-2 text-black">
									<CustomButton
										text="Zum Geschäft"
										@click="changeToShopPage"
									></CustomButton>
								</div>
							</div>
						</div>
					</PageCard>
				</div>

				<div class="flex flex-1"></div>
			</div>
		</PageBody>

		<PageBody v-else class="text-white pt-20">
			<div class="text-xl">404</div>
			Kein Inhalt gefunden
		</PageBody>

		<LoadingScreen :has-loaded="hasLoaded"></LoadingScreen>
	</div>
</template>

<script>
import PageHeader from '../components/PageHeader'
import PageBody from '../components/PageBody'

import CustomButton from '../components/inputs/CustomButton'
import PageCard from '../components/PageCard'
import LoadingScreen from '../components/LoadingScreen'
import CustomButtonIcon from '../components/inputs/CustomButtonIcon'
import CustomInput from '../components/inputs/CustomInput'

import CustomForm from '../components/inputs/CustomForm'
import BadgeFatGreen from '../components/badges/BadgeFatGreen'
import BadgeFatPurple from '../components/badges/BadgeFatPurple'
import BadgeFatRed from '../components/badges/BadgeFatRed'
import BadgeFatGray from '../components/badges/BadgeFatGray'

export default {
	name: 'CustomerBoxPage',
	components: {
		BadgeFatGray,
		BadgeFatRed,
		BadgeFatPurple,
		BadgeFatGreen,
		CustomForm,
		CustomInput,
		CustomButtonIcon,
		LoadingScreen,
		PageCard,
		CustomButton,
		PageBody,
		PageHeader,
	},
	data() {
		return {
			boxId: undefined,
			loaded: {
				boxInfo: false,
			},
			box: undefined,
			nameObj: {
				text: '',
				errorMessage: '',
				state: 0,
				isLoading: false,
			},
		}
	},
	computed: {
		hasLoaded: function () {
			return this.loaded.boxInfo
		},
	},
	created() {
		this.boxId = this.$route.params.identifier

		this.getBoxInfo()
	},
	methods: {
		initEditBoxName: function () {
			this.nameObj.state = 1
		},
		cancelEditBoxName: function () {
			this.nameObj.state = 0
			this.nameObj.isLoading = false
			this.getBoxInfo()
		},
		editBoxName: function () {
			if (this.$refs['customerChangeBoxNameForm'].validate()) {
				this.nameObj.isLoading = true

				const context = this
				this.$http
					.post('/panel/customer/setBoxName', {
						boxId: this.boxId,
						boxName: this.nameObj.text,
					})
					.then((response) => {
						const status = response.data.status.value
						if (response.status === 200 && status === 100) {
							context.cancelEditBoxName()

							context.$toast.success(
								'Name der cybo-box geändert',
								context.$toaststyle.successMid
							)
						} else {
							switch (status) {
								case 423:
									context.nameObj.errorMessage = 'Name bereits vergeben'
									context.$toast.error(
										'Name bereits vergeben',
										context.$toaststyle.errorShort
									)
									break
								default:
									context.$toast.error(
										'Ein Fehler ist aufgetreten',
										context.$toaststyle.errorShort
									)
									console.log(response.data)
									break
							}
						}
					})
					.catch((err) => {
						console.log(err)

						context.cancelEditBoxName()
						context.$toast.error(err.message, context.$toaststyle.errorShort)
					})
					.finally(() => {
						context.nameObj.isLoading = false
						context.getBoxInfo()
					})
			}
		},
		getBoxInfo: function () {
			let context = this
			this.$http
				.post('/panel/customer/getSingleBoxInfo', {
					boxId: this.boxId,
				})
				.then((response) => {
					context.loaded.boxInfo = true

					const status = response.data.status.value
					if (response.status === 200 && status === 100) {
						context.box = response.data.body

						context.nameObj.text = context.box.boxName
					}
				})
				.catch((err) => {
					context.loaded.boxInfo = true
					console.log(err)
				})
		},
		changeToBonArchive: function () {
			this.$router.push({
				path: '/customer/bons?boxId=' + this.box.boxId,
			})
		},
		changeToShopPage: function () {
			this.$router.push({
				path: '/customer/shops/' + this.box.shopId,
			})
		},
	},
}
</script>

<style scoped></style>