<template>
	<div
		ref="test-input-ref"
		class="relative text-in_text_default bg-in_bg_default border rounded-3xl h-9 max-w-sm outline focus-within:border-in_border_active focus-within:bg-in_bg_active focus-within:text-in_text_active"
		:class="customClass"
	>
		<input
			id="input-field"
			v-model="value.text"
			placeholder=" "
			:type="type"
			:disabled="value.disabled"
			class="absolute top-0 left-0 px-4 h-full w-full z-10 appearance-none focus:outline-none bg-transparent text-sm"
			@keyup.enter="$emit('enter')"
			@keypress="value.errorMessage = ''"
		/>

		<label
			for="input-field"
			class="absolute top-0 left-0 origin-0 z-9 ml-6 py-2 bg-in_bg_default duration-200 text-sm leading-4"
			:class="customClassLabel"
			@keyup.enter="$emit('enter')"
		>
			{{ placeholder }}
		</label>

		<div v-if="value.errorMessage" class="absolute top-8 left-5 mb-10 text-red-700 text-xs">
			{{ value.errorMessage }}
		</div>
	</div>
</template>

<script>
export default {
	name: 'CustomInput',
	props: {
		placeholder: {
			type: String,
			default: '',
		},
		value: {
			type: Object,
			required: true,
			default() {
				return {
					text: '',
					errorMessage: '',
					disabled: false,
				}
			},
		},
		type: {
			type: String,
			default: 'text',
		},
		rules: {
			type: Array,
			default() {
				return []
			},
		},
	},
	data() {
		return {
			text: '',
			isError: false,
		}
	},
	computed: {
		customClass: function () {
			let classText = ''

			classText += this.isError ? 'border-red-700 mb-6 ' : 'border-in_border_default '

			if (this.value.text) {
				classText += 'text-in_text_active '
			}
			return classText
		},
		customClassLabel: function () {
			let classText = ''
			if (this.value.text) {
				classText += ''
			} else {
				//
			}
			return classText
		},
	},
	watch: {
		'value.text'() {
			this.$emit('input', this.value)
		},
		'value.errorMessage'(value) {
			this.isError = !!value
		},
	},
	methods: {
		setError: function (errorMessage) {
			this.isError = true
			this.value.errorMessage = errorMessage
		},
		resetError: function () {
			this.isError = false
			this.value.errorMessage = ''
		},
		resetValue: function () {
			this.value.text = ''
		},
	},
}
</script>

<style scoped>
#input-field:-webkit-autofill,
#input-field:-webkit-autofill:hover,
#input-field:-webkit-autofill:focus,
#input-field:-webkit-autofill:active {
	/*-webkit-box-shadow: 0 0 0 30px white inset !important;*/
	border-radius: 30px;
}
</style>