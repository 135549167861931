<template>
	<div class="flex flex-col">
		<PageHeader headline="Developer Keys"></PageHeader>

		<PageBody class="pt-4">
			<PageCard title="Developer Key Liste" class="relative">
				<CustomButtonIcon
					:icon="this.$icons.addActive"
					tooltip="Neuen Key erstellen"
					class="absolute top-2 right-4"
					@click="switchToAddDeveloperKeyPage"
				></CustomButtonIcon>

				<div class="text-center">
					<div
						v-if="keyList.length > 0"
						class="bg-listbg_dark rounded-3xl px-4 py-4 text-xs"
					>
						<div class="flex flex-row text-list_textheader_dark h-12">
							<div class="w-48 h-full py-1 font-bold">Status</div>
							<div class="flex-1 h-full py-1 font-bold text-left pl-4">
								Aktivierungsschlüssel
							</div>
							<div class="w-48 h-full py-1 font-bold text-left pl-4">
								Erstellungsdatum
							</div>
						</div>

						<div>
							<!-- click method is not on this elem so that the button click does not trigger it -->
							<div
								v-for="key in keyList"
								:key="key.id"
								class="flex flex-row text-list_text_dark cursor-pointer rounded-3xl hover:bg-white"
							>
								<div class="h-full w-48 py-3 text-left pl-4 border-t">
									<BadgeSuccess
										v-if="key.status === 'ACTIVE'"
										text="aktiv"
									></BadgeSuccess>
									<BadgeError v-else text="inaktiv"></BadgeError>
								</div>
								<div class="flex-1 h-full py-3 text-left pl-4 border-t">
									{{ key.key }}
								</div>
								<div class="w-48 h-full py-3 text-left pl-4 border-t">
									{{
										$utils.date2dateString(new Date(key.created)) +
										' ' +
										$utils.date2timeString(new Date(key.created))
									}}
								</div>
							</div>
						</div>
					</div>

					<div v-else class="bg-listbg_dark rounded-3xl px-4 py-4 text-list_text_dark">
						Aktuell gibt es keine Developer Keys
					</div>
				</div>
			</PageCard>
		</PageBody>

		<LoadingScreen :has-loaded="hasLoaded"></LoadingScreen>
	</div>
</template>

<script>
import PageHeader from '../components/PageHeader'
import PageBody from '../components/PageBody'
import PageCard from '../components/PageCard'

import CustomButtonIcon from '../components/inputs/CustomButtonIcon'

import BadgeSuccess from '../components/badges/BadgeSuccess'
import BadgeError from '../components/badges/BadgeError'
import LoadingScreen from '../components/LoadingScreen'

export default {
	name: 'AdminDeveloperKeysPage',
	components: {
		LoadingScreen,
		CustomButtonIcon,
		PageCard,
		PageBody,
		PageHeader,
		BadgeError,
		BadgeSuccess,
	},
	data() {
		return {
			loaded: {
				keyList: false,
			},
			keyList: [],
		}
	},
	computed: {
		hasLoaded: function () {
			return this.loaded.keyList
		},
	},
	created() {
		this.getDeveloperKeyList()
	},
	methods: {
		getDeveloperKeyList: function () {
			let context = this

			context.loaded.keyList = false

			this.$http
				.get('/panel/admin/getDeveloperKeyList')
				.then((response) => {
					context.loaded.keyList = true

					const status = response.data.status.value
					if (response.status === 200 && status === 100) {
						context.keyList = response.data.body
					}
				})
				.catch((err) => {
					context.loaded.keyList = true
					console.log(err)
				})
		},
		switchToAddDeveloperKeyPage: function () {
			this.$router.push({
				path: '/admin/addDeveloperKey',
			})
		},
	},
}
</script>

<style scoped></style>