const UtilsService = {
	date2timeString: function (date) {
		let hours = date.getUTCHours() + 1
		if (hours < 10) {
			hours = '0' + hours
		}
		let minutes = date.getUTCMinutes()
		if (minutes < 10) {
			minutes = '0' + minutes
		}
		let seconds = date.getUTCSeconds()
		if (seconds < 10) {
			seconds = '0' + seconds
		}
		return hours + ':' + minutes + ':' + seconds
	},
	date2dateString: function (date) {
		let day = date.getUTCDate()
		if (day < 10) {
			day = '0' + day
		}
		let month = date.getUTCMonth() + 1
		if (month < 10) {
			month = '0' + month
		}
		let year = date.getUTCFullYear()
		return day + '.' + month + '.' + year
	},
}

export { UtilsService }
