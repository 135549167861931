<template>
	<div>Not found</div>
</template>

<script>
export default {
	name: 'NotFoundPage',
}
</script>

<style scoped></style>