<template>
	<div class="flex flex-col">
		<PageHeader headline="Neue Box einem Geschäft zuweisen"></PageHeader>

		<PageBody>
			<div class="flex flex-row pt-10">
				<PageCard title="Verfügbare Boxen">
					<div>
						<div class="text-3xl text-right mb-2 text-new_fg font-bold">
							{{ unassignedBoxes.length }}
						</div>

						<div
							v-if="unassignedBoxes.length === 0"
							class="flex-1 align-middle mr-2 text-sm"
						>
							Aktuell haben Sie leider keine freien Boxen.
							<br />
							Für mehr Informationen kontaktieren Sie Ihren Händler oder
							<a class="text-blue-700" href="mailto:info@cybo.de">info@cybo.de</a>
						</div>
					</div>
				</PageCard>

				<PageCard title="Informationen" class="text-sm">
					<div>
						Eine cybo-box muss immer einem Geschäft zugeordnet werden, bevor diese
						aktiviert werden kann. Nach der Zuweisung erhalten Sie den entsprechenden
						Aktivierungsschlüssel von Ihrem Händler. Zum Zuweisen einer cybo-box das
						entsprechende Geschäft anwählen und die Auswahl bestätigen.
					</div>
				</PageCard>
			</div>

			<PageCard title="Ihre Geschäfte" class="relative z-9">
				<div class="absolute top-2 right-4">
					<CustomButtonIcon
						:icon="this.$icons.addActive"
						tooltip="Neues Geschäft anlegen"
						@click="changeToAddShopPage"
					></CustomButtonIcon>
				</div>
				<div>
					<ShopList :key="listKey" :loaded="loaded" @openShop="initAssignBox"></ShopList>
				</div>
			</PageCard>
		</PageBody>

		<ConfirmPopup
			v-show="assign.toConfirm"
			title="Bestätigung"
			text="Diesem Geschäft eine neue cybo-box zuweisen?"
			:loading="assign.isLoading"
			@cancel="cancelAssignBox"
			@confirm="assignBox"
		></ConfirmPopup>

		<InfoPopup
			v-show="info.toShow"
			title="Keine cybo-box verfügbar"
			text="Sie haben aktuell leider keine freie Box zur Verfügung.."
			@confirm="closeInfoPopup"
		></InfoPopup>

		<LoadingScreen :has-loaded="hasLoaded"></LoadingScreen>
	</div>
</template>

<script>
import PageHeader from '../components/PageHeader'
import PageBody from '../components/PageBody'
import PageCard from '../components/PageCard'

import CustomButtonIcon from '../components/inputs/CustomButtonIcon'
import ShopList from './components/ShopList'
import ConfirmPopup from '../components/popup/ConfirmPopup'
import InfoPopup from '../components/popup/InfoPopup'
import LoadingScreen from '../components/LoadingScreen'

export default {
	name: 'CustomerAddBoxPage',
	components: {
		LoadingScreen,
		InfoPopup,
		ConfirmPopup,
		ShopList,
		CustomButtonIcon,
		PageCard,
		PageBody,
		PageHeader,
	},
	data() {
		return {
			loaded: {
				unassigned: false,
				shops: false,
			},
			unassignedBoxes: [],
			listKey: new Date().getMilliseconds(),
			assign: {
				toConfirm: false,
				isLoading: false,
				shopId: undefined,
			},
			info: {
				toShow: false,
			},
		}
	},
	computed: {
		hasLoaded: function () {
			return this.loaded.unassigned && this.loaded.shops
		},
	},
	created() {
		this.getUnassignedBoxes()
	},
	methods: {
		getUnassignedBoxes: function () {
			let context = this
			this.$http
				.get('/panel/customer/unassignedBoxes')
				.then((response) => {
					context.loaded.unassigned = true

					const status = response.data.status.value
					if (response.status === 200 && status === 100) {
						context.unassignedBoxes = response.data.body
					}
				})
				.catch((err) => {
					console.log(err)
					context.loaded.unassigned = true
				})
		},
		changeToAddShopPage: function () {
			this.$router.push({
				path: '/customer/addShop',
			})
		},
		openInfoPopup: function () {
			this.info.toShow = true
		},
		closeInfoPopup: function () {
			this.info.toShow = false
		},
		initAssignBox: function (shopId) {
			if (this.unassignedBoxes.length === 0) {
				// do not try to assign a box if no unassigned box exists
				this.openInfoPopup()
				return
			}
			this.assign.shopId = shopId
			this.assign.toConfirm = true
		},
		cancelAssignBox: function () {
			this.assign.shopId = undefined
			this.assign.toConfirm = false
			this.assign.isLoading = false
		},
		assignBox: function () {
			this.assign.isLoading = true

			let boxId = this.unassignedBoxes.pop()

			let context = this
			this.$http
				.post('/panel/customer/assignBoxToShop', {
					boxId: boxId,
					shopId: this.assign.shopId,
				})
				.then((response) => {
					const status = response.data.status.value
					if (response.status === 200 && status === 100) {
						context.listKey = new Date().getMilliseconds()

						context.$toast.success(
							'Eine cybo-box wurde dem Geschäft zugewiesen',
							context.$toaststyle.successMid
						)
					} else {
						context.$toast.error(
							'Ein Fehler ist aufgetreten',
							context.$toaststyle.errorShort
						)

						// adds the box id back to the list if the request failed
						context.unassignedBoxes.append(boxId)
					}
				})
				.catch((err) => {
					console.log(err)

					context.$toast.error(err.message, context.$toaststyle.errorShort)

					// adds the box id back to the list if the request failed
					context.unassignedBoxes.append(boxId)
				})
				.finally(() => {
					context.cancelAssignBox()
				})
		},
	},
}
</script>

<style scoped></style>