<template>
	<div class="text-center">
		<div class="bg-listbg_dark rounded-3xl px-4 py-4">
			<div class="flex flex-row text-new_fg h-9 text-xs">
				<div class="flex-1 h-full py-1 pl-2 text-left font-bold">Name</div>
				<div class="flex-1 h-full py-1 text-left font-bold">Straße</div>
				<div class="w-24 h-full py-1 text-left font-bold">PLZ</div>
				<div class="flex-1 h-full py-1 text-left font-bold">Ort</div>
				<div class="w-24 h-full py-1 text-left font-bold">Anzahl Boxen</div>
			</div>

			<div v-if="shops.length > 0">
				<!-- click method is not on this elem so that the button click does not trigger it -->
				<div
					v-for="shop in shops"
					:key="shop.id"
					class="flex flex-row text-new_fg cursor-pointer rounded-3xl hover:bg-white text-xs border-t border-white"
				>
					<div
						class="flex-1 h-full py-3 pl-2 text-left"
						@click="$emit('openShop', shop.id)"
					>
						{{ shop.name }}
					</div>
					<div class="flex-1 h-full py-3 text-left" @click="$emit('openShop', shop.id)">
						{{ shop.address.streetName + ' ' + shop.address.streetNumber }}
					</div>
					<div class="w-24 h-full py-3 text-left" @click="$emit('openShop', shop.id)">
						{{ shop.address.zipCode }}
					</div>
					<div class="flex-1 h-full py-3 text-left" @click="$emit('openShop', shop.id)">
						{{ shop.address.cityName }}
					</div>
					<div class="w-24 h-full py-3 text-left" @click="$emit('openShop', shop.id)">
						{{ shop.numBoxes }}
					</div>
				</div>
			</div>

			<div v-else>
				<div>Es sind noch keine Geschäfte vorhanden</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ShopList',
	props: {
		loaded: {
			type: Object,
			default() {
				return {
					shops: false,
				}
			},
		},
	},
	data() {
		return {
			shops: [],
		}
	},
	created() {
		this.getShops()
	},
	methods: {
		getShops: function () {
			let context = this
			this.$http
				.get('/panel/customer/getShopList')
				.then((response) => {
					context.loaded.shops = true

					const status = response.data.status.value
					if (response.status === 200 && status === 100) {
						context.shops = response.data.body
					}
				})
				.catch((err) => {
					console.log(err)
				})
		},
	},
}
</script>

<style scoped></style>