<template>
	<div>Index Page where user is logged in</div>
</template>

<script>
export default {
	name: 'IndexPage',
}
</script>

<style scoped></style>