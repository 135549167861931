<template>
	<div class="flex flex-col">
		<PageHeader headline="Details zu diesem Kassenbon"></PageHeader>

		<PageBody>
			<div class="flex flex-row pt-10">
				<div class="flex-1"></div>

				<div class="flex flex-col">
					<!--
					<PageCard v-if="bonIds" title="Navigation">
						<div class="flex flex-row pb-3 border-b border-gray-300">
							<div class="flex flex-1"></div>
							<CustomButton
								text="Zurück zum Bon-Archiv"
								@click="goBackToBonListPage"
							></CustomButton>
							<div class="flex flex-1"></div>
						</div>
						<div class="flex flex-row pt-3">
							<CustomButton
								text="Vorheriger Bon"
								@click="openPreviousBon"
							></CustomButton>
							<div class="flex flex-1"></div>
							<CustomButton text="Nächster Bon" @click="openNextBon"></CustomButton>
						</div>
					</PageCard>
					-->

					<PageCard title="Informationen zum Bon">
						<div v-if="bon" class="flex-col text-left px-6 py-5 min-w-card">
							<div class="border-b border-gray-300 pb-3 mb-3">
								<div class="pl-2 pb-1 text-xs">Aktion des Kunden</div>
								<div class="pl-2 text-black">
									<BadgeFatPurple
										v-if="!bon.action || bon.action === 'NONE'"
										text="GESPEICHERT"
									></BadgeFatPurple>
									<BadgeFatPurple
										v-else
										:text="bon.action.toUpperCase()"
									></BadgeFatPurple>
								</div>
							</div>
							<div class="border-b border-gray-300 pb-3 mb-3">
								<div class="pl-2 pb-1 text-xs">Bon ID</div>
								<div class="pl-2 text-black">
									{{ bon.hashcode }}
								</div>
							</div>
							<div class="border-b border-gray-300 pb-3 mb-3">
								<div class="pl-2 pb-1 text-xs">Erstellungsdatum</div>
								<div class="pl-2 text-black">
									{{ bon.used }}
								</div>
							</div>
							<div class="border-b border-gray-300 pb-3 mb-3">
								<div class="pl-2 pb-1 text-xs">Bezeichnung der Box</div>
								<div class="pl-2 text-black">
									{{
										bon.boxName
											? bon.boxName
											: '(noch keine Bezeichnung vergeben)'
									}}
								</div>
								<div class="mt-3 pl-2 pb-1 text-xs">Boxnummer</div>
								<div class="pl-2 text-black">
									{{ bon.boxNumber }}
								</div>
								<div class="p-2 text-black">
									<CustomButton
										text="Zur Box"
										@click="openBoxPage(bon.boxId)"
									></CustomButton>
								</div>
							</div>
							<div class="">
								<div class="pl-2 pb-1 text-xs">Geschäft</div>
								<div class="pl-2 text-black">
									{{ bon.shopName ? bon.shopName : '(kein Geschäft vorhanden)' }}
								</div>
								<div class="p-2 text-black">
									<CustomButton
										text="Zum Geschäft"
										@click="openShopPage(bon.shopId)"
									></CustomButton>
								</div>
							</div>
						</div>
					</PageCard>
				</div>

				<PageCard title="" class="">
					<div v-if="bon && bon.hashcode" class="w-72 mt-4 ml-2 h-bon overflow-auto">
						<img
							alt=""
							class="border border-gray-800"
							:src="this.$http.getBaseUrl() + '/download/png/' + bon.hashcode"
						/>
					</div>
					<div class="flex flex-row mt-4">
						<div class="flex-1"></div>

						<CustomButtonIcon
							:icon="this.$icons.download"
							tooltip="Herunterladen"
							text="PDF"
							class="mx-2"
							@click="downloadBonPdf"
						></CustomButtonIcon>

						<CustomButtonIcon
							:icon="this.$icons.download"
							tooltip="Herunterladen"
							text="PNG"
							class="mx-2"
							@click="downloadBonPng"
						></CustomButtonIcon>

						<div class="flex-1"></div>
					</div>
				</PageCard>

				<div class="flex-1"></div>
			</div>
		</PageBody>

		<LoadingScreen :has-loaded="hasLoaded"></LoadingScreen>
	</div>
</template>

<script>
import PageHeader from '../components/PageHeader'
import PageBody from '../components/PageBody'
import PageCard from '../components/PageCard'

import CustomButton from '../components/inputs/CustomButton'
import CustomButtonIcon from '../components/inputs/CustomButtonIcon'
import LoadingScreen from '../components/LoadingScreen'
import BadgeFatPurple from '../components/badges/BadgeFatPurple'

export default {
	name: 'CustomerBonPage',
	components: {
		BadgeFatPurple,
		LoadingScreen,
		CustomButtonIcon,
		CustomButton,
		PageCard,
		PageBody,
		PageHeader,
	},
	props: {
		bonIds: {
			type: Array,
			default() {
				return []
			},
		},
		bonListPath: {
			type: String,
			default() {
				return ''
			},
		},
	},
	data() {
		return {
			loaded: {
				bonInfo: false,
			},
			// the bon id
			identifier: undefined,
			urlPrefix: '',
			bon: undefined,
			// keeps track of how many times the user swiped the bons and is
			// used to jump back to the bon list page
			jumpCounter: 1,
		}
	},
	computed: {
		hasLoaded: function () {
			return this.loaded.bonInfo
		},
	},
	created() {
		this.identifier = this.$route.params.identifier

		this.getBonInfo()
	},
	methods: {
		// go back to the bon list page
		goBackToBonListPage: function () {
			this.$router.go(-1 * this.jumpCounter)
		},
		// swipe to the "next" bon in the prop passed bon list
		openNextBon: function () {
			const index = this.bonIds.indexOf(this.identifier)

			if (index >= this.bonIds.length - 1) {
				this.$toast.error('Kein nächster Bon', this.$toaststyle.errorShort)
				return
			}

			this.jumpCounter += 1

			this.$router.push({
				name: 'customerBonPage',
				params: {
					identifier: this.bonIds[index + 1],
					bonIds: this.bonIds,
				},
			})
		},
		// swipe to the "previous" bon in the prop passed bon list
		openPreviousBon: function () {
			const index = this.bonIds.indexOf(this.identifier)

			if (index === 0) {
				this.$toast.error('Kein vorheriger Bon', this.$toaststyle.errorShort)
				return
			}

			this.jumpCounter += 1

			this.$router.push({
				name: 'customerBonPage',
				params: {
					identifier: this.bonIds[index - 1],
					bonIds: this.bonIds,
				},
			})
		},
		openBoxPage: function (boxId) {
			this.$router.push({
				path: '/customer/boxes/' + boxId,
			})
		},
		openShopPage: function (shopId) {
			this.$router.push({
				path: '/customer/shops/' + shopId,
			})
		},
		downloadBonPdf: function () {
			window.open(this.$http.getBaseUrl() + '/download/pdf/' + this.bon.hashcode, '_blank')
		},
		downloadBonPng: function () {
			window.open(this.$http.getBaseUrl() + '/download/png/' + this.bon.hashcode, '_blank')
		},
		getBonInfo: function () {
			let context = this
			this.$http
				.post('/panel/customer/getBonInfo', {
					bonId: this.identifier,
				})
				.then((response) => {
					context.loaded.bonInfo = true

					const status = response.data.status.value
					if (response.status === 200 && status === 100) {
						context.bon = response.data.body
					}
				})
				.catch((err) => {
					context.loaded.bonInfo = true
					console.log(err)
				})
		},
	},
	// required because the url does not change on only params change
	beforeRouteUpdate: function (to, from, next) {
		this.identifier = to.params.identifier
		this.getBonInfo()

		next()
	},
}
</script>

<style scoped></style>