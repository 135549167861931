<template>
	<div class="flex flex-1 flex-col h-screen">
		<PageBody no-margin class="flex flex-col">
			<!-- WAIT FOR IDENTIFIER VALIDATION -->
			<div v-if="isValidState === 0" class="flex flex-row">
				<div class="flex-1"></div>

				<PageCard title="Verifikation im Gange">
					<div>
						<img alt="loading" :src="this.$icons.loadingGif" class="w-10 mx-auto" />
					</div>
					<div class="text-center">Bitte warten..</div>
				</PageCard>

				<div class="flex-1"></div>
			</div>

			<!-- SET PASSWORD -->
			<div v-else-if="isValidState === 1" class="flex flex-col lg:flex-row">
				<div class="flex-1"></div>

				<div class="flex flex-col">
					<img
						alt="cybo-logo"
						:src="this.$icons.cyboLogoWhiteSlim"
						class="max-h-20 mt-5"
					/>
					<div class="mb-5 text-white font-bold">Serviceportal</div>

					<PageCard title="Änderungsantrag für Ihre Zugangsdaten" class="w-96 mx-auto">
						<div class="text-sm text-justify">
							Im Folgenden können Sie die Zugangsdaten für Ihr Benutzerkonto ändern.
						</div>
					</PageCard>
				</div>

				<PageCard title="Authentifizierung ändern" class="w-96">
					<div class="text-sm font-bold mt-3 mb-2">Ihre E-Mail-Adresse</div>

					<div class="text-sm pb-3">
						<div class="pl-2 pb-1 pt-2 text-xs">E-Mail-Adresse</div>
						<div class="pl-2 text-black">
							{{ email }}
						</div>
					</div>

					<div class="text-sm font-bold mt-3 mb-2">
						Zwei-Faktor-Authentifizierung (2FA)
					</div>

					<div class="text-sm text-justify">
						Als zusätzlichen Schutz für Ihr Konto bieten wir eine 2FA an. Es wird dann
						bei jeder Anmeldung im Serviceportal ein zusätzlicher Verifikationscode
						benötigt. Bitte wählen Sie die neue Methode für die 2FA Ihres Kontos aus.
						Diese Änderung wird sofort aktiv.
					</div>
					<div class="mt-3">
						<CustomDropdown
							class="mx-auto"
							:data="twoFactorOptionsObj"
						></CustomDropdown>

						<div v-if="twoFactorOptionsObj.selected === 'EMAIL'" class="text-sm">
							<div class="mt-4 text-sm text-justify">
								Bei jeder Anmeldung im Serviceportal wird Ihnen der
								Verifikationscode an Ihre hinterlegte E-Mail-Adresse gesendet.
							</div>
						</div>

						<div
							v-else-if="
								twoFactorOptionsObj.selected === 'GOOGLE_AUTHENTICATOR' ||
								twoFactorOptionsObj.selected === 'MICROSOFT_AUTHENTICATOR'
							"
							class="text-sm"
						>
							<div class="mt-4 text-sm text-justify">
								Scannen Sie diesen QR-Code mit der
								{{
									twoFactorOptionsObj.selected === 'GOOGLE_AUTHENTICATOR'
										? 'Google'
										: 'Microsoft'
								}}
								Authenticator App:
							</div>
							<div class="flex flex-row mt-4">
								<div class="flex-1"></div>

								<img v-if="qrCodeLink" alt="QR-Code" :src="qrCodeLink" />
								<div v-else class="text-red-700">
									Fehler:<br />
									QR-Code konnte nicht geladen werden
								</div>

								<div class="flex-1"></div>
							</div>
							<div>
								<div class="mt-4 text-xs text-justify">
									(Wenden Sie sich bitte an info@cybo.de falls auch nach kurzer
									Zeit kein QR-Code angezeigt wird)
								</div>
								<CustomForm ref="reset2faForm">
									<div class="mt-4 text-sm text-justify">
										Bitte geben Sie den Verifikationscode aus der App ein, um
										die Aktivierung abzuschließen:
									</div>
									<CustomInput
										v-model="twoFactorObj"
										type="text"
										placeholder="Verifikationscode"
										class="mx-auto mt-4"
										:rules="[$rules.required]"
									></CustomInput>
								</CustomForm>
							</div>
						</div>
					</div>
					<div class="flex flex-row mt-10">
						<div class="flex-1"></div>
						<CustomButton
							text="Änderung speichern"
							:loading="isLoading"
							@click="resetTwoFactorType"
						></CustomButton>
					</div>
				</PageCard>

				<div class="flex-1"></div>
			</div>

			<!-- PAGE NOT AVAILABLE -->
			<div v-else>
				<OnetimepageNotAvailable></OnetimepageNotAvailable>
			</div>

			<div class="flex flex-1"></div>

			<div class="text-white text-sm py-4">
				Copyright © 2022 NovaNode GmbH |
				<a class="hover:underline cursor-pointer" @click="switchToPrivacyPolicyPage">
					Datenschutzerklärung
				</a>
				|
				<a class="hover:underline cursor-pointer" @click="switchToLegalNoticePage">
					Impressum
				</a>
			</div>
		</PageBody>
	</div>
</template>

<script>
import PageBody from './components/PageBody'
import PageCard from './components/PageCard'

import CustomButton from './components/inputs/CustomButton'
import CustomDropdown from './components/inputs/CustomDropdown'
import OnetimepageNotAvailable from './components/onetimepages/OnetimepageNotAvailable'
import CustomForm from './components/inputs/CustomForm'
import CustomInput from './components/inputs/CustomInput'

export default {
	name: 'TwoFactorAuthenticationPage',
	components: {
		CustomInput,
		CustomForm,
		OnetimepageNotAvailable,
		PageBody,
		PageCard,
		CustomButton,
		CustomDropdown,
	},
	data() {
		return {
			isLoading: false,
			identifier: undefined,
			isValidState: 0,
			email: undefined,
			qrCodeLink: undefined,
			twoFactorObj: {
				text: '',
				errorMessage: '',
			},
			twoFactorOptionsObj: {
				selected: 'NONE',
				options: [
					{ text: 'Keine 2FA benutzen', value: 'NONE' },
					{ text: 'Google Authenticator', value: 'GOOGLE_AUTHENTICATOR' },
					{ text: 'Microsoft Authenticator', value: 'MICROSOFT_AUTHENTICATOR' },
					{ text: 'E-Mail', value: 'EMAIL' },
				],
			},
		}
	},
	created() {
		this.identifier = this.$route.params.identifier
		this.validateIdentifier()
	},
	methods: {
		validateIdentifier: function () {
			let context = this
			this.$http
				.post('/panel/auth/validatePageIdentifier', {
					identifier: context.identifier,
				})
				.then((response) => {
					const status = response.data.status.value
					if (response.status === 200 && status === 100) {
						context.isValidState = 1
						context.email = response.data.body.email
						context.qrCodeLink = response.data.body.qrCodeLink
					} else {
						context.isValidState = 2
					}
				})
				.catch((err) => {
					context.isValidState = 2
					console.log(err)
				})
		},
		resetTwoFactorType: async function () {
			const context = this

			context.isLoading = true

			// validates the 2FA verification code form if google or microsoft authenticator was selected
			if (
				context.twoFactorOptionsObj.selected === 'GOOGLE_AUTHENTICATOR' ||
				context.twoFactorOptionsObj.selected === 'MICROSOFT_AUTHENTICATOR'
			) {
				if (!context.$refs['reset2faForm'].validate()) {
					context.$toast.error(
						'Bitte prüfen Sie Ihre Eingaben',
						context.$toaststyle.errorShort
					)
					context.isLoading = false
					return
				}

				try {
					let response = await context.$http.post('/panel/auth/validateTwoFactorCode', {
						pageIdentifier: context.identifier,
						code: context.twoFactorObj.text,
					})

					const status = response.data.status.value

					switch (status) {
						case 100:
							// 2FA code was valid
							break
						case 909:
							context.isLoading = false
							context.$toast.error(
								'Bitte prüfen Sie Ihre Eingaben',
								context.$toaststyle.errorShort
							)
							context.twoFactorObj.errorMessage = 'Ungültige Eingabe'
							return
						default:
							context.isLoading = false
							context.$toast.error(
								'Ein Fehler ist aufgetreten',
								context.$toaststyle.errorShort
							)

							console.log(response.data)
							return
					}
				} catch (err) {
					console.log(err)

					context.isLoading = false
					context.$toast.error(
						'Ein Fehler ist aufgetreten',
						context.$toaststyle.errorShort
					)
					return
				}
			}

			context.$http
				.post('/panel/auth/resetTwoFactorType', {
					identifier: context.identifier,
					email: context.email,
					twoFactorType: context.twoFactorOptionsObj.selected,
				})
				.then((response) => {
					const status = response.data.status.value
					if (response.status === 200 && status === 100) {
						context.$toast.success(
							'2FA erfolgreich geändert',
							context.$toaststyle.successMid
						)

						context.$router.push('/login').catch(() => {
							// required for router to not throw exception
						})
					} else {
						console.log(response.data)

						context.$toast.error(
							'Ein Fehler ist aufgetreten',
							context.$toaststyle.errorShort
						)
					}
				})
				.catch((err) => {
					console.log(err)

					context.$toast.error(
						'Ein Fehler ist aufgetreten',
						context.$toaststyle.errorShort
					)
				})
				.finally(() => {
					context.isLoading = false
				})
		},
		switchToPrivacyPolicyPage: function () {
			const routeData = this.$router.resolve({
				name: 'privacyPolicyPage',
				params: {
					from: 'index',
				},
			})

			window.open(routeData.href, '_blank')
		},
		switchToLegalNoticePage: function () {
			const routeData = this.$router.resolve({
				name: 'legalNoticePage',
				params: {
					from: 'index',
				},
			})

			window.open(routeData.href, '_blank')
		},
	},
}
</script>

<style scoped></style>