<template>
	<div class="flex flex-col w-full min-h-screen custom-linear-gradient">
		<div class="flex flex-row mx-auto h-full xl:w-container_xl">
			<div class="flex flex-col p-10 w-card_large">
				<div class="text-3xl pb-12 text-white">
					<div class="hidden lg:block">
						<img :src="this.$icons.cyboLogoWhiteSlim" alt="" class="mx-auto h-20" />
					</div>
					<div class="text-xl font-bold">Serviceportal</div>
				</div>

				<div
					v-if="state === 0"
					class="container bg-white flex flex-col pt-10 px-10 rounded-3xl mb-2"
				>
					<div class="text-2xl text-logtext text-left">Willkommen zurück!</div>

					<div class="text-left text-logtext_light mb-10">
						Melden Sie sich an, um unseren Service zu nutzen.
					</div>

					<div>
						<CustomForm ref="loginForm">
							<CustomInput
								v-model="emailObj"
								placeholder="E-Mail-Adresse"
								type="text"
								class="mx-auto mb-3"
								:rules="[$rules.required]"
								@enter="login"
							></CustomInput>

							<CustomInput
								v-model="passwordObj"
								placeholder="Passwort"
								type="password"
								class="mx-auto mb-6"
								:rules="[$rules.required]"
								@enter="login"
							></CustomInput>
						</CustomForm>

						<CustomButton
							text="Anmelden"
							class=""
							:loading="isLoading"
							@click="login"
						></CustomButton>
					</div>

					<div class="text-sm cursor-pointer hover:underline mt-12 mb-4" @click="toReset">
						Passwort vergessen?
					</div>
				</div>

				<!-- 2FA INPUT -->
				<div
					v-if="state === 3"
					class="container bg-white flex flex-col pt-10 px-10 rounded-3xl mb-2"
				>
					<div class="text-2xl text-logtext text-left">Zwei-Faktor-Authentifizierung</div>

					<div class="text-left text-logtext_light mb-10">
						Bitte geben Sie den Verifikationscode ein:
					</div>

					<div>
						<CustomForm ref="login2faInputForm">
							<CustomInput
								v-model="codeObj"
								placeholder="Verifikationscode"
								type="text"
								class="mx-auto mb-3"
								:rules="[$rules.required]"
								@enter="login"
							></CustomInput>
						</CustomForm>

						<CustomButton
							text="Anmelden"
							class=""
							:loading="isLoading"
							@click="login"
						></CustomButton>
					</div>

					<div class="text-sm cursor-pointer hover:underline mt-12 mb-4" @click="toLogin">
						Zurück zur Anmeldung
					</div>
				</div>

				<div
					v-else-if="state === 1"
					class="container bg-white flex flex-col pt-10 px-10 rounded-3xl mb-2"
				>
					<div class="text-2xl text-logtext text-left">Passwort vergessen?</div>

					<div class="text-left text-logtext_light mb-10">
						Sie erhalten eine E-Mail und können anschließend Ihr Passwort zurücksetzen.
					</div>

					<div>
						<CustomForm ref="loginResetPasswordForm">
							<CustomInput
								v-model="emailObj"
								placeholder="E-Mail-Adresse"
								type="text"
								class="mx-auto mb-3"
								:rules="[$rules.required, $rules.isEmail]"
								@enter="login"
							></CustomInput>
						</CustomForm>

						<CustomButton
							text="Zurücksetzen"
							class=""
							:loading="isLoading"
							@click="resetPassword"
						></CustomButton>
					</div>

					<div class="text-sm cursor-pointer hover:underline mt-12 mb-4" @click="toLogin">
						Zurück zur Anmeldung
					</div>
				</div>

				<div
					v-else-if="state === 2"
					class="container bg-white flex flex-col pt-10 px-10 rounded-3xl mb-2"
				>
					<div class="text-2xl text-logtext text-left">Passwort zurückgesetzt!</div>

					<div class="text-left text-logtext_light mb-10">
						Sie erhalten eine E-Mail und können anschließend Ihr Passwort zurücksetzen.
					</div>

					<div class="text-sm cursor-pointer hover:underline mt-12 mb-4" @click="toLogin">
						Zurück zur Anmeldung
					</div>
				</div>

				<div class="text-left text-white text-sm p-12">
					Sie haben noch keinen Account oder Probleme bei der Anmeldung? Melden Sie sich
					bei <a class="text-blue-300" href="mailto:info@cybo.de">info@cybo.de</a>
				</div>

				<div class="flex-1"></div>
			</div>

			<div class="hidden lg:block xl:w-card_large lg:w-card_large mx-auto">
				<img :src="this.$icons.loginIllustration" alt="" class="h-5/6 mx-auto" />
			</div>
		</div>

		<div class="text-white text-sm py-4">
			Copyright © 2022 NovaNode GmbH |
			<a class="hover:underline cursor-pointer" @click="switchToPrivacyPolicyPage"
				>Datenschutzerklärung</a
			>
			|
			<a class="hover:underline cursor-pointer" @click="switchToLegalNoticePage">Impressum</a>
		</div>
	</div>
</template>

<script>
import CustomInput from './components/inputs/CustomInput'
import CustomButton from './components/inputs/CustomButton'
import CustomForm from './components/inputs/CustomForm'

export default {
	name: 'LoginPage',
	components: {
		CustomForm,
		CustomInput,
		CustomButton,
	},
	data() {
		return {
			// boolean to show spinner in login button
			isLoading: false,
			// 0 = normal login page, 1 = reset password
			// 2 = reset password success, 3 = enter 2FA code
			state: 0,
			// object containing the email
			emailObj: {
				text: '',
				errorMessage: '',
			},
			// object containing the password
			passwordObj: {
				text: '',
				errorMessage: '',
			},
			// object containing the 2FA code
			codeObj: {
				text: '',
				errorMessage: '',
			},
		}
	},
	methods: {
		toLogin: function () {
			this.state = 0
			this.codeObj.text = ''
			this.emailObj.errorMessage = ''
			this.passwordObj.errorMessage = ''
		},
		toReset: function () {
			this.state = 1
			this.emailObj.errorMessage = ''
			this.passwordObj.errorMessage = ''
		},
		to2FA() {
			this.state = 3
		},
		login: async function () {
			if (this.state === 3) {
				if (!this.$refs['login2faInputForm'].validate()) {
					return
				}
			} else {
				if (!this.$refs['loginForm'].validate()) {
					return
				}
			}

			this.isLoading = true

			let context = this
			let statusCode = await this.$store.dispatch('auth/login', {
				email: context.emailObj.text,
				password: context.passwordObj.text,
				verificationCode: context.codeObj.text,
			})

			if (statusCode === 100) {
				const redirect = this.$router.history.current.query.redirect || '/'

				setTimeout(function () {
					context.isLoading = false

					context.$router
						.push({
							path: redirect,
						})
						.catch(() => {
							// ignore error that router produces when redirecting from redirected page
						})
				}, 1000)
			} else if (statusCode === 908 || statusCode === 913) {
				context.isLoading = false

				if (this.state !== 3) {
					this.to2FA()
				}
			} else if (statusCode === 909 || statusCode === 914) {
				// the 2FA code was not valid
				context.isLoading = false
				context.codeObj.errorMessage = 'Code nicht gültig'

				context.$toast.error('Code nicht gültig', context.$toaststyle.errorShort)
			} else {
				setTimeout(function () {
					context.isLoading = false
					context.emailObj.errorMessage = 'E-Mail-Adresse prüfen'
					context.passwordObj.errorMessage = 'Passwort prüfen'

					context.$toast.error(
						'E-Mail oder Passwort nicht korrekt',
						context.$toaststyle.errorMid
					)
				}, 1000)
			}
		},
		resetPassword: function () {
			if (this.$refs['loginResetPasswordForm'].validate()) {
				this.isLoading = true

				let context = this
				this.$http
					.post('/panel/auth/initResetPassword', {
						email: this.emailObj.text,
					})
					.then((response) => {
						const status = response.data.status.value
						if (response.status === 200 && status === 100) {
							context.state = 2

							context.$toast.success(
								'E-Mail zur Passwortänderung wurde versendet',
								context.$toaststyle.successMid
							)
							return
						}

						switch (status) {
							case 1001:
							case 1002:
								context.emailObj.errorMessage = 'Eingabe ungültig'
								context.$toast.error(
									'Eingabe ungültig',
									context.$toaststyle.errorMid
								)
								break
							default:
								context.$toast.error(
									'Ein Fehler ist aufgetreten',
									context.$toaststyle.errorShort
								)
								console.log(response.data)
								break
						}
					})
					.catch((err) => {
						console.log(err)

						context.$toast.error(
							'Ein Fehler ist aufgetreten',
							context.$toaststyle.errorShort
						)
					})
					.finally(() => {
						context.isLoading = false
					})
			}
		},
		switchToPrivacyPolicyPage: function () {
			this.$router.push({
				name: 'privacyPolicyPage',
				params: {
					from: 'login',
				},
			})
		},
		switchToLegalNoticePage: function () {
			this.$router.push({
				name: 'legalNoticePage',
				param: {
					from: 'login',
				},
			})
		},
	},
}
</script>

<style scoped></style>