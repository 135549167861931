import { CustomRouter } from '../../router'
import { ApiService } from '../../services/api.service'
import { LoggerService } from '../../services/logger.service'
import { TokenService } from '../../services/token.service'

const AuthModule = {
	namespaced: true,
	state: {
		isLoggedIn: false,
		email: undefined,
		identity: undefined,
	},
	getters: {
		isLoggedIn: function (state) {
			return state.isLoggedIn
		},
		getEmail: function (state) {
			return state.email
		},
		getIdentity: function (state) {
			return state.identity
		},
		isAdmin: function (state) {
			return state.identity === 'CYBO_ADMIN'
		},
		isReseller: function (state) {
			return state.identity === 'CYBO_RESELLER'
		},
		isCustomer: function (state) {
			return state.identity === 'CYBO_CUSTOMER'
		},
	},
	mutations: {
		loginRequest: function () {
			// TODO: implement
		},
		loginSuccess: function (state) {
			state.isLoggedIn = true
		},
		loginFailed: function (state) {
			state.isLoggedIn = false
		},
		logoutSuccess: function (state) {
			state.isLoggedIn = false
		},
		setIdentity(state, { email, identity }) {
			state.email = email
			state.identity = identity
		},
		removeIdentity(state) {
			state.email = undefined
			state.identity = undefined
		},
	},
	actions: {
		login: async function ({ commit }, { email, password, verificationCode }) {
			LoggerService.debug('auth.module.js:login', 'login request for ' + email)

			commit('loginRequest')

			try {
				let response = await ApiService.post('/panel/auth/authenticate', {
					email: email,
					password: password,
					verificationCode,
				})

				const status = response.data.status.value

				const token = response.data.body.token
				const identity = response.data.body.identity

				if (status === 100 && token && identity) {
					LoggerService.debug('auth.module.js:login', 'login request successful')

					TokenService.saveToken(token)
					TokenService.setIdentity(identity)
					ApiService.setAuthHeader(token)

					commit('loginSuccess')
					commit('setIdentity', {
						email: email,
						identity: identity,
					})

					return 100
				}

				if (status === 908 || status === 909 || status === 913 || status === 914) {
					return status
				}
			} catch (err) {
				LoggerService.debug('auth.module.js:login', 'login request failed')
				console.log(err)

				commit('loginFailed')
				commit('removeIdentity')
			}

			return 104
		},
		verifyToken: async function ({ commit }, { token }) {
			try {
				let response = await ApiService.post('/panel/auth/verifyToken', {
					token: token,
				})

				const status = response.data.status.value

				const email = response.data.body.email
				const identity = response.data.body.identity

				if (status === 100 && identity) {
					LoggerService.debug(
						'auth.module.js:verifyToken',
						'existing token successfully verified'
					)

					TokenService.saveToken(token)
					TokenService.setIdentity(identity)
					ApiService.setAuthHeader(token)

					commit('loginSuccess')
					commit('setIdentity', {
						email,
						identity,
					})

					return identity
				}
			} catch (err) {
				LoggerService.debug('auth.module.js:verifyToken', 'token could not be verified')
				console.log(err)

				TokenService.removeToken()
				TokenService.removeIdentity()
				ApiService.removeAuthHeader()

				commit('loginFailed')
				commit('removeIdentity')

				return undefined
			}
		},
		setLoggedIn: function ({ commit }, { token, identity }) {
			TokenService.saveToken(token)
			TokenService.setIdentity(identity)
			ApiService.setAuthHeader(token)

			commit('loginSuccess')
			commit('setIdentity', identity)
		},
		logout: async function ({ commit }, { targetRoute }) {
			TokenService.removeToken()
			TokenService.removeIdentity()
			ApiService.removeAuthHeader()

			commit('logoutSuccess')
			commit('removeIdentity')

			await CustomRouter.push(targetRoute)
		},
	},
}

export { AuthModule }
