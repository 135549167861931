let HISTORY_KEY = 'session_history'
let HISTORY = []
let isStartup = true

const BreadcrumbService = {
	addPage: function (name, path) {
		let lastItem = HISTORY.length > 0 ? HISTORY[HISTORY.length - 1] : undefined
		if (lastItem) {
			// removes the current state of the previous item
			lastItem.current = false

			// deletes the history if the user navigates to a page via the browser
			// that was not the last page of the history
			if (isStartup) {
				if (lastItem.name !== name) {
					this.clearHistory()
				}
				isStartup = false
			}
		}

		// removes all newer items if a previous page was called
		let index = -1
		for (let i in HISTORY) {
			if (HISTORY[i].name === name) {
				index = i
			}
		}
		if (index > -1) {
			HISTORY = HISTORY.splice(0, index)
		}

		HISTORY.push({
			name: name,
			path: path,
			current: true,
		})

		this.saveHistory()
	},
	getHistory: function () {
		return HISTORY
	},
	replaceLastPath: function (path) {
		if (HISTORY.length > 0) {
			HISTORY[HISTORY.length - 1].path = path
		}
	},
	clearHistory: function () {
		HISTORY = []
		localStorage.removeItem(HISTORY)
	},
	loadHistory: function () {
		HISTORY = JSON.parse(localStorage.getItem(HISTORY_KEY))
		if (!HISTORY) {
			HISTORY = []
		}
	},
	saveHistory: function () {
		localStorage.setItem(HISTORY_KEY, JSON.stringify(HISTORY))
	},
}

export { BreadcrumbService }
