const IconService = {
	cyboLogo: require('@/assets/svg/logo-cybo-light.svg'),
	cyboLogoWhite: require('@/assets/svg/logo-cybo-white.svg'),
	cyboLogoWhiteSlim: require('@/assets/svg/logo-cybo-white_slim.svg'),
	cyboLogoNavbarCollapsed: require('@/assets/svg/logo-light-cybo-small.svg'),
	cyboLogoNavbarExpanded: require('@/assets/svg/logo-light-cybo-large.svg'),

	loginIllustration: require('@/assets/images/login-illustration.svg'),

	success: require('@/assets/svg/icon-success.svg'),
	error: require('@/assets/svg/icon-error.svg'),

	badgeOk: require('@/assets/svg/icon-badge-ok.svg'),
	badgeError: require('@/assets/svg/icon-badge-error.svg'),
	badgeWarning: require('@/assets/svg/icon-badge-warning.svg'),
	badgeOffline: require('@/assets/svg/icon-badge-offline.svg'),

	arrowLeft: require('@/assets/svg/icon-arrow-left.svg'),
	arrowRight: require('@/assets/svg/icon-arrow-right.svg'),
	arrowUp: require('@/assets/svg/icon-arrow-up.svg'),
	arrowDown: require('@/assets/svg/icon-arrow-down.svg'),

	arrowRightWhite: require('@/assets/svg/icon-arrow-right-white.svg'),

	download: require('@/assets/svg/icon-download-active.svg'),

	loadingGif: require('@/assets/loading.gif'),

	edit: require('@/assets/svg/icon-light-edit.svg'),
	confirm: require('@/assets/svg/icon-light-success-active.svg'),
	cancel: require('@/assets/svg/icon-light-cancel.svg'),

	add: require('@/assets/svg/icon-light-add.svg'),
	addActive: require('@/assets/svg/icon-light-add-active.svg'),

	clock: require('@/assets/svg/icon-light-clock.svg'),
	clockActive: require('@/assets/svg/icon-light-clock-active.svg'),

	home: require('@/assets/svg/icon-light-home.svg'),
	homeActive: require('@/assets/svg/icon-light-home-active.svg'),

	document: require('@/assets/svg/icon-dark-document.svg'),
	documentActive: require('@/assets/svg/icon-dark-document-active.svg'),
	files: require('@/assets/svg/icon-light-files.svg'),
	filesActive: require('@/assets/svg/icon-light-files-active.svg'),
	box: require('@/assets/svg/icon-light-box.svg'),
	boxActive: require('@/assets/svg/icon-light-box-active.svg'),

	profile: require('@/assets/svg/icon-light-profile.svg'),
	profileActive: require('@/assets/svg/icon-light-profile-active.svg'),
	user: require('@/assets/svg/icon-light-user.svg'),
	userActive: require('@/assets/svg/icon-light-user-active.svg'),
	users: require('@/assets/svg/icon-light-users.svg'),
	usersActive: require('@/assets/svg/icon-light-users-active.svg'),
	userAdd: require('@/assets/svg/icon-light-user-add.svg'),
	userAddActive: require('@/assets/svg/icon-light-user-add-active.svg'),
	userGroup: require('@/assets/svg/icon-light-user-group.svg'),
	userGroupActive: require('@/assets/svg/icon-light-user-group-active.svg'),

	shop: require('@/assets/svg/icon-light-shop.svg'),
	shopActive: require('@/assets/svg/icon-light-shop-active.svg'),
	shop2: require('@/assets/svg/icon-light-shop2.svg'),
	shop2Active: require('@/assets/svg/icon-light-shop2-active.svg'),

	log: require('@/assets/svg/icon-dark-log.svg'),
	logActive: require('@/assets/svg/icon-dark-log-active.svg'),
	bon: require('@/assets/svg/icon-dark-bon.svg'),
	bonActive: require('@/assets/svg/icon-dark-bon-active.svg'),

	logout: require('@/assets/svg/icon-light-logout.svg'),
	logoutActive: require('@/assets/svg/icon-light-logout-active.svg'),

	mdiInformationOutline: require('@/assets/svg/mdi_information-outline.svg'),
	mdiPlus: require('@/assets/svg/mdi_plus.svg'),
	mdiMinus: require('@/assets/svg/mdi_minus.svg'),
}

export { IconService }
