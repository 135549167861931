import { LoggerService } from './logger.service'
import { ApiService } from './api.service'

const TOKEN_KEY = 'session_token'
let TOKEN = undefined
let IDENTITY = undefined

const TokenService = {
	loadToken: function () {
		TOKEN = localStorage.getItem(TOKEN_KEY)
	},
	hasToken: function () {
		return !!TOKEN
	},
	getToken: function () {
		return TOKEN
	},
	saveToken: function (token) {
		LoggerService.debug('token.service.js:saveToken', 'saving token to local storage')
		TOKEN = token
		localStorage.setItem(TOKEN_KEY, TOKEN)
	},
	removeToken: function () {
		TOKEN = undefined
		localStorage.removeItem(TOKEN_KEY)
	},
	setIdentity: function (identity) {
		IDENTITY = identity
	},
	removeIdentity: function () {
		IDENTITY = undefined
	},
	isReseller: function () {
		return IDENTITY === 'CYBO_RESELLER'
	},
	isCustomer: function () {
		return IDENTITY === 'CYBO_CUSTOMER'
	},
	isAdmin: function () {
		return IDENTITY === 'CYBO_ADMIN'
	},
}

export { TokenService }
