<template>
	<div class="flex flex-col">
		<PageHeader headline="Boxübersicht"></PageHeader>

		<PageBody>
			<div class="container mx-auto pt-4">
				<BoxList :loaded="loaded" @openBoxPage="switchToBoxPage"></BoxList>
			</div>
		</PageBody>

		<LoadingScreen :has-loaded="hasLoaded"></LoadingScreen>
	</div>
</template>

<script>
import PageHeader from '../components/PageHeader'
import PageBody from '../components/PageBody'

import BoxList from './components/BoxList'

import LoadingScreen from '../components/LoadingScreen'

export default {
	name: 'ResellerBoxAssignmentPage',
	components: {
		LoadingScreen,
		BoxList,
		PageBody,
		PageHeader,
	},
	data() {
		return {
			loaded: {
				boxActive: false,
			},
		}
	},
	computed: {
		hasLoaded: function () {
			return this.loaded.boxActive
		},
	},
	methods: {
		switchToBoxPage: function (boxId) {
			this.$router.push({
				path: '/reseller/boxes/' + boxId,
			})
		},
	},
}
</script>

<style scoped></style>