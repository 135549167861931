<template>
	<div class="flex flex-col">
		<PageHeader headline="Startseite" description="Übersicht"></PageHeader>

		<PageBody>
			<div v-if="indexInfo" class="flex flex-col xl:flex-row mt-10">
				<div class="flex-1"></div>

				<PageCard title="Anzahl Ihrer Kunden" class="mx-auto">
					<div class="text-2xl font-bold text-black">
						{{ indexInfo.numCustomers }}
					</div>
				</PageCard>

				<PageCard title="Von Ihnen zugewiesene Boxen" class="mx-auto">
					<div class="text-2xl font-bold text-black">
						{{ indexInfo.numActiveBoxes }}
					</div>
				</PageCard>

				<!--
				<PageCard title="Ausstehende Boxzuweisungen" class="mx-auto">
					<div class="text-2xl font-bold text-black">
						{{ indexInfo.numPendingBoxes }}
					</div>
				</PageCard>
				-->

				<div class="flex-1"></div>
			</div>
			<div v-else>
				<PageCard title="" class="inline-block">
					<div class="text-xs text-new_fg">Keine Informationen verfügbar</div>
				</PageCard>
			</div>
		</PageBody>

		<LoadingScreen :has-loaded="hasLoaded"></LoadingScreen>
	</div>
</template>

<script>
import PageHeader from '../components/PageHeader'
import PageCard from '../components/PageCard'
import PageBody from '../components/PageBody'
import LoadingScreen from '../components/LoadingScreen'

export default {
	name: 'ResellerIndexPage',
	components: {
		LoadingScreen,
		PageHeader,
		PageBody,
		PageCard,
	},
	data() {
		return {
			loaded: {
				indexInfo: false,
			},
			indexInfo: undefined,
		}
	},
	computed: {
		hasLoaded: function () {
			return this.loaded.indexInfo
		},
	},
	created() {
		this.getIndexInfo()
	},
	methods: {
		getIndexInfo: function () {
			let context = this
			this.$http
				.get('/panel/reseller/getIndexInfo')
				.then((response) => {
					context.loaded.indexInfo = true

					const status = response.data.status.value
					if (response.status === 200 && status === 100) {
						context.indexInfo = response.data.body
					}
				})
				.catch((err) => {
					context.loaded.indexInfo = true
					console.log(err)
				})
		},
	},
}
</script>

<style scoped></style>