const LOG_LEVELS = {
	silent: 0,
	error: 1,
	info: 2,
	debug: 3,
}

let logLevel = 'error'

const LoggerService = {
	setLevel: function (level) {
		if (level in LOG_LEVELS) {
			logLevel = level
			this.info('logger.service.js:setLevel', 'set log level: ' + level)
		} else {
			this.error('logger.service.js:setLevel', 'could not set log level: ' + level)
			this.error('logger.service.js:setLevel', 'set default log level: ' + logLevel)
		}
	},
	error: function (component, message) {
		if (LOG_LEVELS[logLevel] >= 1) {
			console.log(
				'[ %cERROR%c ] (' + component + ') ' + message,
				'color: #ff4580;',
				'color:none;'
			)
		}
	},
	info: function (component, message) {
		if (LOG_LEVELS[logLevel] >= 2) {
			console.log(
				'[ %cINFO%c  ] (' + component + ') ' + message,
				'color: #ababff;',
				'color:none;'
			)
		}
	},
	debug: function (component, message) {
		if (LOG_LEVELS[logLevel] >= 3) {
			console.log(
				'[ %cDEBUG%c ] (' + component + ') ' + message,
				'color: #17ff83;',
				'color:none;'
			)
		}
	},
}

export { LoggerService }
