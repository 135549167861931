<template>
	<div class="px-12 pt-16 min-h-full overflow-auto custom-linear-gradient" :class="marginLeft">
		<slot></slot>
	</div>
</template>

<script>
export default {
	name: 'PageBody',
	props: {
		noMargin: {
			type: Boolean,
		},
	},
	computed: {
		marginLeft: function () {
			return this.noMargin ? '' : 'ml-10 pl-20'
		},
	},
}
</script>

<style scoped></style>