<template>
	<div class="flex flex-col">
		<PageHeader headline="Details zu dieser Box"></PageHeader>

		<PageBody v-if="box !== undefined">
			<div class="flex flex-col xl:flex-row pt-10">
				<div class="flex-1"></div>

				<div class="flex flex-col mx-auto">
					<PageCard title="Archivierte Bons">
						<div class="text-2xl font-bold text-black">
							{{ box.numStoredBons }}
						</div>
					</PageCard>

					<PageCard title="Gedruckte Bons">
						<div class="text-2xl font-bold text-black">
							{{ box.numPrintedBons }}
						</div>
					</PageCard>
				</div>

				<div class="mx-auto">
					<PageCard title="Informationen zur cybo-box">
						<div class="flex-col text-left px-6 py-5 min-w-card">
							<div class="border-b border-gray-300 pb-3 mb-3 text-sm">
								<div class="pl-2 pb-1 text-xs">Status</div>
								<div class="pl-2 text-black">
									<BadgeFatGreen
										v-if="box.status === 'ACTIVE'"
										text="IN BETRIEB"
									></BadgeFatGreen>
									<BadgeFatPurple
										v-else-if="box.status === 'NOT_ACTIVATED'"
										text="NOCH NICHT AKTIVIERT"
									></BadgeFatPurple>
									<BadgeFatRed
										v-else-if="box.status === 'DEACTIVATED'"
										text="DEAKTIVIERT"
									></BadgeFatRed>
									<BadgeFatGray v-else text="UNBEKANNT"></BadgeFatGray>
								</div>
							</div>

							<div class="border-b border-gray-300 pb-3 mb-3 text-sm">
								<div class="pl-2 pb-1 text-xs">Name der Box</div>
								<div class="pl-2 text-black">
									{{ box.boxName }}
								</div>
							</div>

							<div class="border-b border-gray-300 pb-3 mb-3 text-sm">
								<div class="pl-2 pb-1 text-xs">Boxnummer</div>
								<div class="pl-2 text-black">
									{{ box.boxId }}
								</div>
							</div>

							<div class="border-b border-gray-300 pb-3 mb-3 text-sm">
								<div class="pl-2 pb-1 text-xs">Aktivierungsschlüssel</div>
								<div class="pl-2 text-black">
									{{ box.activationKey }}
								</div>
							</div>

							<div class="text-sm">
								<div class="pl-2 pb-1 text-xs">Aktivierungsdatum</div>
								<div class="pl-2 text-black">
									{{ box.activated }}
								</div>
							</div>
						</div>
					</PageCard>
				</div>

				<div class="flex flex-col mx-auto">
					<PageCard title="Informationen zum Kunden">
						<div class="flex-col text-left px-6 py-5 min-w-card">
							<div class="border-b border-gray-300 pb-3 mb-3 text-sm">
								<div class="pl-2 pb-1 text-xs">Name des Unternehmens</div>
								<div class="pl-2 text-black">
									{{ box.companyName }}
								</div>
							</div>

							<div class="border-b border-gray-300 pb-3 mb-3 text-sm">
								<div class="pl-2 pb-1 text-xs">Ansprechpartner</div>
								<div class="pl-2 text-black">
									{{ box.contactPerson }}
								</div>
							</div>

							<div class="border-b border-gray-300 pb-3 mb-3 text-sm">
								<div class="pl-2 pb-1 text-xs">Telefonnummer</div>
								<div class="pl-2 text-black">
									{{ box.phoneNumber }}
								</div>
							</div>

							<div class="text-sm">
								<div class="pl-2 pb-1 text-xs">Unternehmensanschrift</div>
								<div class="pl-2 text-black">
									{{ box.companyStreetName + ' ' + box.companyStreetNumber }}
									<br />
									{{ box.companyZipCode + ' ' + box.companyCityName }}
								</div>
							</div>
						</div>
					</PageCard>

					<PageCard title="Geschäft dieser cybo-box">
						<div class="flex-col text-left px-6 py-5 min-w-card">
							<div class="border-b border-gray-300 pb-3 mb-3 text-sm">
								<div class="pl-2 pb-1 text-xs">Name</div>
								<div class="pl-2 text-black">
									{{ box.shopName }}
								</div>
							</div>

							<div class="border-b border-gray-300 pb-3 mb-3 text-sm">
								<div class="pl-2 pb-1 text-xs">Anschrift</div>
								<div class="pl-2 text-black">
									{{ box.shopStreetName + ' ' + box.shopStreetNumber }}
									<br />
									{{ box.shopZipCode + ' ' + box.shopCityName }}
								</div>
							</div>

							<div class="text-sm">
								<div class="pl-2 pb-1 text-xs">Zuweisungsdatum</div>
								<div class="pl-2 text-black">
									{{ box.assigned }}
								</div>
							</div>
						</div>
					</PageCard>
				</div>

				<div class="flex-1"></div>
			</div>
		</PageBody>

		<LoadingScreen :has-loaded="hasLoaded"></LoadingScreen>
	</div>
</template>

<script>
import LoadingScreen from '../components/LoadingScreen'

import PageHeader from '../components/PageHeader'
import PageBody from '../components/PageBody'
import PageCard from '../components/PageCard'
import BadgeFatGreen from '../components/badges/BadgeFatGreen'
import BadgeFatPurple from '../components/badges/BadgeFatPurple'
import BadgeFatRed from '../components/badges/BadgeFatRed'
import BadgeFatGray from '../components/badges/BadgeFatGray'

export default {
	name: 'ResellerBoxPage',
	components: {
		BadgeFatGray,
		BadgeFatRed,
		BadgeFatPurple,
		BadgeFatGreen,
		LoadingScreen,
		PageCard,
		PageBody,
		PageHeader,
	},
	data() {
		return {
			boxId: undefined,
			loaded: {
				boxInfo: false,
			},
			box: undefined,
		}
	},
	computed: {
		hasLoaded: function () {
			return this.loaded.boxInfo
		},
	},
	created() {
		this.boxId = this.$route.params.identifier

		this.getBoxInfo()
	},
	methods: {
		getBoxInfo: function () {
			let context = this
			this.$http
				.post('/panel/reseller/getSingleBoxInfo', {
					boxId: this.boxId,
				})
				.then((response) => {
					context.loaded.boxInfo = true

					const status = response.data.status.value
					if (response.status === 200 && status === 100) {
						context.box = response.data.body
					}
				})
				.catch((err) => {
					context.loaded.boxInfo = true
					console.log(err)
				})
		},
	},
}
</script>

<style scoped></style>