<template>
	<div class="relative bg-dd_bg_inactive h-9 min-w-md rounded-3xl border border-new_fg">
		<input
			v-model="value.text"
			type="text"
			class="absolute top-0 left-0 px-4 h-full w-full z-10 appearance-none focus:outline-none bg-transparent text-black text-xs"
			placeholder="Keine Auswahl"
		/>

		<div class="absolute left-3 -top-3 text-xs text-new_fg px-2 bg-dd_bg_inactive select-none">
			{{ title }}
		</div>
	</div>
</template>

<script>
export default {
	name: 'CustomInputFilter',
	props: {
		title: {
			type: String,
			default: '',
		},
		value: {
			type: Object,
			required: true,
		},
	},
}
</script>

<style scoped></style>