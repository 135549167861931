const ToastStyleService = {
	errorShort: {
		position: 'top-right',
		timeout: 1000,
		closeOnClick: false,
		pauseOnFocusLoss: true,
		pauseOnHover: true,
		draggable: false,
		draggablePercent: 0.6,
		showCloseButtonOnHover: true,
		hideProgressBar: true,
		closeButton: 'button',
		icon: true,
		rtl: false,
	},
	errorMid: {
		position: 'top-right',
		timeout: 3000,
		closeOnClick: false,
		pauseOnFocusLoss: true,
		pauseOnHover: true,
		draggable: false,
		draggablePercent: 0.6,
		showCloseButtonOnHover: true,
		hideProgressBar: false,
		closeButton: 'button',
		icon: true,
		rtl: false,
	},
	successMid: {
		position: 'top-right',
		timeout: 3000,
		closeOnClick: false,
		pauseOnFocusLoss: true,
		pauseOnHover: true,
		draggable: false,
		draggablePercent: 0.6,
		showCloseButtonOnHover: true,
		hideProgressBar: false,
		closeButton: 'button',
		icon: true,
		rtl: false,
	},
}

export { ToastStyleService }
