<template>
	<div class="flex flex-row m-2">
		<div class="flex-1"></div>
		<div class="flex-1 h-10 w-40">
			<div class="flex flex-row h-full text-xs">
				<div
					class="flex flex-row p-2 pt-3 rounded-3xl select-none"
					:class="currentPageNum > 1 ? 'cursor-pointer hover:bg-dd_bg_inactive' : ''"
					@click="previousPage"
				>
					<img :src="this.$icons.arrowLeft" alt="" class="h-4" />
					<div class="">zurück</div>
					<div class="flex-1"></div>
				</div>

				<div class="flex-1 flex flex-col p-2 pt-3 select-none">
					<div class="flex-1 text-center">
						Seite {{ currentPageNum }} von {{ maxPageNum }}
					</div>
					<div class="flex-1 text-center">{{ totalElements }} Einträge</div>
				</div>

				<div
					class="flex flex-row p-2 pt-3 rounded-3xl select-none"
					:class="
						currentPageNum < maxPageNum ? 'cursor-pointer hover:bg-dd_bg_inactive' : ''
					"
					@click="nextPage"
				>
					<div class="flex-1"></div>
					<div class="leading-4">weiter</div>
					<img :src="this.$icons.arrowRight" alt="" class="h-4" />
				</div>
			</div>
		</div>
		<div class="flex-1"></div>
	</div>
</template>

<script>
export default {
	name: 'CustomPagination',
	props: {
		currentPageNum: {
			type: Number,
			required: true,
			default: 1,
		},
		maxPageNum: {
			type: Number,
			required: true,
			default: 1,
		},
		totalElements: {
			type: Number,
			required: false,
			default: 0,
		},
	},
	methods: {
		previousPage: function () {
			if (this.currentPageNum > 1) {
				this.$emit('previous')
			}
		},
		nextPage: function () {
			if (this.currentPageNum < this.maxPageNum) {
				this.$emit('next')
			}
		},
	},
}
</script>

<style scoped></style>