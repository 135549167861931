<template>
	<PageCard title="Ihre Kontaktinformationen">
		<div class="flex-col text-left px-6 py-5 min-w-card">
			<CustomForm ref="profile-information-form">
				<div class="border-b border-gray-300 pb-3 mb-3 text-sm">
					<div class="pl-2 pb-1 text-xs">Firmenname</div>
					<div v-if="state === 0" class="pl-2 text-black">
						{{ info.companyNameObj.text }}
					</div>
					<div v-else>
						<CustomInput
							:value="info.companyNameObj"
							:rules="[$rules.required, $rules.lengthAbove3, $rules.lengthBelow50]"
						></CustomInput>
					</div>
				</div>

				<div class="border-b border-gray-300 pb-3 mb-3 text-sm">
					<div class="pl-2 pb-1 text-xs">Firmenanschrift</div>
					<div v-if="state === 0" class="pl-2 text-black">
						{{ info.streetNameObj.text + ' ' + info.streetNumberObj.text }}
						<br />
						{{ info.zipCodeObj.text + ' ' + info.cityNameObj.text }}
					</div>
					<div v-else>
						<div class="flex flex-row">
							<div class="flex-1 mr-3">
								<CustomInput
									v-model="info.streetNameObj"
									placeholder="Straße"
									type="text"
									class="mx-auto mt-3"
									:rules="[
										$rules.required,
										$rules.lengthAbove3,
										$rules.lengthBelow50,
									]"
								></CustomInput>
							</div>
							<div class="w-1/4">
								<CustomInput
									v-model="info.streetNumberObj"
									placeholder="Nr."
									type="text"
									class="mx-auto mt-3"
									:rules="[$rules.required, $rules.lengthBelow20]"
								></CustomInput>
							</div>
						</div>

						<div class="flex flex-row">
							<div class="w-1/3">
								<CustomInput
									v-model="info.zipCodeObj"
									placeholder="PLZ"
									type="text"
									class="mt-3"
									:rules="[
										$rules.required,
										$rules.lengthAbove3,
										$rules.lengthBelow20,
									]"
								></CustomInput>
							</div>
							<div class="flex-1 ml-3">
								<CustomInput
									v-model="info.cityNameObj"
									placeholder="Ort"
									type="text"
									class="mt-3"
									:rules="[
										$rules.required,
										$rules.lengthAbove3,
										$rules.lengthBelow30,
									]"
								></CustomInput>
							</div>
						</div>
					</div>
				</div>

				<div class="border-b border-gray-300 pb-3 mb-3 text-sm">
					<div class="pl-2 pb-1 text-xs">Ansprechpartner</div>
					<div v-if="state === 0" class="pl-2 text-black">
						{{
							info.contactPersonFirstNameObj.text +
							' ' +
							info.contactPersonLastNameObj.text
						}}
					</div>
					<div v-else>
						<div class="flex flex-col">
							<div>
								<CustomInput
									v-model="info.contactPersonFirstNameObj"
									placeholder="Vorname"
									type="text"
									class="mx-auto mt-3"
									:rules="[$rules.required, $rules.lengthBelow30]"
								></CustomInput>
							</div>
							<div>
								<CustomInput
									v-model="info.contactPersonLastNameObj"
									placeholder="Nachname"
									type="text"
									class="mx-auto mt-3"
									:rules="[$rules.required, $rules.lengthBelow30]"
								></CustomInput>
							</div>
						</div>
					</div>
				</div>

				<div class="text-sm">
					<div class="pl-2 pb-1 text-xs">Telefonnummer</div>
					<div v-if="state === 0" class="pl-2 text-black">
						{{ info.phoneNumberObj.text }}
					</div>
					<div v-else>
						<CustomInput
							v-model="info.phoneNumberObj"
							type="text"
							class="mx-auto"
							:rules="[$rules.required, $rules.lengthAbove3, $rules.lengthBelow30]"
						></CustomInput>
					</div>
				</div>
			</CustomForm>
		</div>

		<div v-if="state === 0" class="flex flex-row">
			<div class="flex-1"></div>
			<CustomButtonIcon
				:icon="this.$icons.edit"
				tooltip="Bearbeiten"
				@click="editProfileInfo"
			></CustomButtonIcon>
			<div class="flex-1"></div>
		</div>
		<div v-else class="flex flex-row mt-4">
			<div class="flex-1"></div>
			<CustomButtonIcon
				:icon="this.$icons.cancel"
				tooltip="Abbrechen"
				class="mx-2 bg-btnbg_secondary_light"
				@click="cancelEditProfileInfo"
			></CustomButtonIcon>
			<CustomButtonIcon
				:icon="this.$icons.confirm"
				tooltip="Speichern"
				class="mx-2"
				@click="saveProfileInfo"
			></CustomButtonIcon>
			<div class="flex-1"></div>
		</div>
	</PageCard>
</template>

<script>
import PageCard from '../PageCard'
import CustomButtonIcon from '../inputs/CustomButtonIcon'
import CustomInput from '../inputs/CustomInput'
import CustomForm from '../inputs/CustomForm'

export default {
	name: 'ProfileInformationCard',
	components: {
		CustomForm,
		PageCard,
		CustomButtonIcon,
		CustomInput,
	},
	props: {
		loaded: {
			type: Object,
			default() {
				return {
					profileInfo: false,
				}
			},
		},
	},
	data() {
		return {
			tmp: undefined,
			state: 0,
			info: {
				companyNameObj: {
					text: 'Keine Angabe',
					errorMessage: '',
				},
				contactPersonFirstNameObj: {
					text: 'Keine Angabe',
					errorMessage: '',
				},
				contactPersonLastNameObj: {
					text: '',
					errorMessage: '',
				},
				phoneNumberObj: {
					text: '',
					errorMessage: '',
				},
				streetNameObj: {
					text: '',
					errorMessage: '',
				},
				streetNumberObj: {
					text: '',
					errorMessage: '',
				},
				zipCodeObj: {
					text: '',
					errorMessage: '',
				},
				cityNameObj: {
					text: '',
					errorMessage: '',
				},
			},
		}
	},
	created() {
		this.getProfileInfo()
	},
	methods: {
		getProfileInfo: function () {
			let context = this
			this.$http
				.get('/panel/account/getProfileInfo')
				.then((response) => {
					context.loaded.profileInfo = true

					const status = response.data.status.value
					if (response.status === 200 && status === 100) {
						if (response.data.body.companyName) {
							context.info.companyNameObj.text = response.data.body.companyName
						}
						if (response.data.body.contactPerson.phoneNumber) {
							context.info.phoneNumberObj.text =
								response.data.body.contactPerson.phoneNumber
						}
						if (response.data.body.contactPerson.firstName) {
							context.info.contactPersonFirstNameObj.text =
								response.data.body.contactPerson.firstName
						}
						if (response.data.body.contactPerson.lastName) {
							context.info.contactPersonLastNameObj.text =
								response.data.body.contactPerson.lastName
						}
						if (response.data.body.address.streetName) {
							context.info.streetNameObj.text = response.data.body.address.streetName
						}
						if (response.data.body.address.streetNumber) {
							context.info.streetNumberObj.text =
								response.data.body.address.streetNumber
						}
						if (response.data.body.address.zipCode) {
							context.info.zipCodeObj.text = response.data.body.address.zipCode
						}
						if (response.data.body.address.cityName) {
							context.info.cityNameObj.text = response.data.body.address.cityName
						}
					}
				})
				.catch((err) => {
					console.log(err)
				})
		},
		editProfileInfo: function () {
			this.tmp = JSON.parse(JSON.stringify(this.info))
			this.state = 1
		},
		cancelEditProfileInfo: function () {
			this.info = this.tmp
			this.tmp = undefined
			this.state = 0
		},
		saveProfileInfo: function () {
			if (this.$refs['profile-information-form'].validate()) {
				const context = this
				context.$http
					.post('/panel/account/updateProfileInfo', {
						companyName: this.info.companyNameObj.text,
						firstName: this.info.contactPersonFirstNameObj.text,
						lastName: this.info.contactPersonLastNameObj.text,
						phoneNumber: this.info.phoneNumberObj.text,
						streetName: this.info.streetNameObj.text,
						streetNumber: this.info.streetNumberObj.text,
						zipCode: this.info.zipCodeObj.text,
						cityName: this.info.cityNameObj.text,
					})
					.then((response) => {
						const status = response.data.status.value
						if (response.status === 200 && status === 100) {
							context.$toast.success(
								'Profildaten erfolgreich geändert',
								context.$toaststyle.successMid
							)
						} else {
							context.$toast.error(response.data.body, context.$toaststyle.errorMid)
						}
					})
					.catch((err) => {
						console.log(err)

						context.$toast.error(err.message, context.$toaststyle.errorMid)
					})
					.finally(() => {
						context.state = 0
					})
			}
		},
	},
}
</script>

<style scoped></style>