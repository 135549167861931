<template>
	<PageCard title="Ihr zuständiger Händler">
		<div class="flex-col text-left px-6 py-5 min-w-card">
			<div class="border-b border-gray-300 pb-3 mb-3 text-sm">
				<div class="pl-2 pb-1 text-xs">Händlernummer</div>
				<div class="pl-2 text-black">
					{{ resellerId }}
				</div>
			</div>

			<div class="border-b border-gray-300 pb-3 mb-3 text-sm">
				<div class="pl-2 pb-1 text-xs">E-Mail-Adresse</div>
				<div class="pl-2 text-black">
					{{ email }}
				</div>
			</div>

			<div class="border-gray-300 pb-3 mb-3 text-sm">
				<div class="pl-2 pb-1 text-xs">Kontaktperson</div>
				<div class="pl-2 text-black">
					{{ contactPersonName }}
				</div>
			</div>
		</div>
	</PageCard>
</template>

<script>
import PageCard from '../../components/PageCard'

export default {
	name: 'ResellerInformationCard',
	components: {
		PageCard,
	},
	props: {
		loaded: {
			type: Object,
			default() {
				return {
					resellerInfo: false,
				}
			},
		},
	},
	data() {
		return {
			resellerId: '',
			email: '',
			contactPersonName: '',
		}
	},
	created() {
		this.getResellerInfo()
	},
	methods: {
		getResellerInfo: function () {
			this.loaded.resellerInfo = false

			let context = this
			this.$http
				.get('/panel/customer/getResellerInfo')
				.then((response) => {
					context.loaded.resellerInfo = true

					const status = response.data.status.value
					if ((response.status === 200) & (status === 100)) {
						context.email = response.data.body.email
						context.resellerId = response.data.body.resellerId
						context.contactPersonName = response.data.body.contactPerson
					}
				})
				.catch((err) => {
					console.log(err)
				})
		},
	},
}
</script>

<style scoped></style>