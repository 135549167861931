<template>
	<div class="flex flex-col">
		<PageHeader headline="Startseite"></PageHeader>

		<PageBody class="pt-4">
			<div class="flex flex-row">
				<PageCard title="">
					<div>Reguläre Boxen (online)</div>
					<div>
						{{ countRegularBoxes[0] + ' / ' + countRegularBoxes[1] }}
					</div>
				</PageCard>
				<PageCard title="">
					<div>Händler Boxen (online)</div>
					<div>
						{{ countResellerBoxes[0] + ' / ' + countResellerBoxes[1] }}
					</div>
				</PageCard>
				<PageCard title="">
					<div>Entwickler Boxen (online)</div>
					<div>
						{{ countDeveloperBoxes[0] + ' / ' + countDeveloperBoxes[1] }}
					</div>
				</PageCard>
			</div>
		</PageBody>

		<LoadingScreen :has-loaded="hasLoaded"></LoadingScreen>
	</div>
</template>

<script>
import PageHeader from '../components/PageHeader'
import PageBody from '../components/PageBody'
import PageCard from '../components/PageCard'
import LoadingScreen from '../components/LoadingScreen'

export default {
	name: 'AdminIndexPage',
	components: {
		LoadingScreen,
		PageCard,
		PageHeader,
		PageBody,
	},
	data() {
		return {
			loaded: {},
		}
	},
	computed: {
		hasLoaded: function () {
			return true
		},
		countRegularBoxes: function () {
			let boxList = this.$store.state.status.boxList
			let tmp = Object.keys(boxList).map(function (key) {
				return boxList[key]
			})

			tmp = tmp.filter((item) => item.type === 'REGULAR_KEY')
			let numOffline = tmp.length

			tmp = tmp.filter((item) => item.online)
			let numOnline = tmp.length

			return [numOnline, numOffline]
		},
		countResellerBoxes: function () {
			let boxList = this.$store.state.status.boxList
			let tmp = Object.keys(boxList).map(function (key) {
				return boxList[key]
			})

			tmp = tmp.filter((item) => item.type === 'DISTRIBUTOR_KEY')
			let numOffline = tmp.length

			tmp = tmp.filter((item) => item.online)
			let numOnline = tmp.length

			return [numOnline, numOffline]
		},
		countDeveloperBoxes: function () {
			let boxList = this.$store.state.status.boxList
			let tmp = Object.keys(boxList).map(function (key) {
				return boxList[key]
			})

			tmp = tmp.filter((item) => item.type === 'DEVELOPER_KEY')
			let numOffline = tmp.length

			tmp = tmp.filter((item) => item.online)
			let numOnline = tmp.length

			return [numOnline, numOffline]
		},
	},
}
</script>

<style scoped></style>