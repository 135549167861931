<template>
	<div class="flex flex-row">
		<div class="flex flex-1"></div>

		<div>
			<img alt="cybo-logo" :src="this.$icons.cyboLogoWhiteSlim" class="h-20 mx-auto mt-5" />
			<div class="mb-5 text-white font-bold">Serviceportal</div>

			<PageCard title="Diese Seite ist nicht verfügbar" class="mt-10 w-96 mx-auto">
				<div class="text-sm text-justify">
					Bei Fragen wenden Sie sich bitte an:
					<a href="mailto:info@cybo.de" class="hover:underline">info@cybo.de</a>
				</div>
			</PageCard>
		</div>
		<div class="flex flex-1"></div>
	</div>
</template>

<script>
import PageCard from '../PageCard'

export default {
	name: 'OnetimepageNotAvailable',
	components: {
		PageCard,
	},
}
</script>

<style scoped></style>