<template>
	<div
		id="loaderContainer"
		class="fixed top-16 left-12 w-full h-full custom-linear-gradient z-29 bg-v1 flex flex-col"
		:class="fadeOut"
	>
		<div class="h-36"></div>
		<div class="flex-1">
			<img :src="this.$icons.cyboLogoWhite" alt="" class="w-32 h-32 mx-auto" />

			<svg
				id="L4"
				class="w-32 h-32 mx-auto"
				xmlns="http://www.w3.org/2000/svg"
				x="0px"
				y="0px"
				viewBox="-22 40 100 100"
				xml:space="preserve"
			>
				<circle fill="#ffffff" stroke="none" cx="6" cy="50" r="6">
					<animate
						attributeName="opacity"
						dur="1s"
						values="0;1;0"
						repeatCount="indefinite"
						begin="0.1"
					/>
				</circle>
				<circle fill="#ffffff" stroke="none" cx="26" cy="50" r="6">
					<animate
						attributeName="opacity"
						dur="1s"
						values="0;1;0"
						repeatCount="indefinite"
						begin="0.2"
					/>
				</circle>
				<circle fill="#ffffff" stroke="none" cx="46" cy="50" r="6">
					<animate
						attributeName="opacity"
						dur="1s"
						values="0;1;0"
						repeatCount="indefinite"
						begin="0.3"
					/>
				</circle>
			</svg>
		</div>
		<div class="flex-1"></div>
	</div>
</template>

<script>
export default {
	name: 'LoadingScreen',
	props: {
		hasLoaded: {
			type: Boolean,
		},
	},
	computed: {
		fadeOut: function () {
			return this.hasLoaded ? 'animate-fade-out' : ''
		},
	},
	mounted() {
		let container = document.getElementById('loaderContainer')
		container.addEventListener(
			'transitionend',
			() => {
				console.log('transitioned')
			},
			false
		)
	},
}
</script>

<style scoped></style>