var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col"},[_c('PageHeader',{attrs:{"headline":"Details zu diesem Geschäft"}}),_c('PageBody',[_c('div',{staticClass:"flex flex-row pt-10"},[_c('div',{staticClass:"flex-1"}),_c('PageCard',{attrs:{"title":""}},[_c('div',{staticClass:"flex-col text-left px-6 py-5 min-w-card"},[_c('div',{staticClass:"border-b border-gray-300 pb-3 mb-3"},[_c('div',{staticClass:"pl-2 pb-1 text-xs"},[_vm._v("Boxen in diesem Geschäft")]),_c('div',{staticClass:"pl-2 text-black"},[_vm._v(" "+_vm._s(_vm.meta.numBoxes)+" ")])]),_c('div',{staticClass:"border-b border-gray-300 pb-3 mb-3"},[_c('div',{staticClass:"pl-2 pb-1 text-xs"},[_vm._v("Archivierte Kassenbons")]),_c('div',{staticClass:"pl-2 text-black"},[_vm._v(" "+_vm._s(_vm.meta.numStoredBons)+" ")])]),_c('div',{},[_c('div',{staticClass:"pl-2 pb-1 text-xs"},[_vm._v("Gedruckte Kassenbons")]),_c('div',{staticClass:"pl-2 text-black"},[_vm._v(" "+_vm._s(_vm.meta.numPrintedBons)+" ")])]),_c('div',{},[_c('div',{staticClass:"p-2"}),_c('div',{staticClass:"p-2 text-black"},[_c('CustomButton',{attrs:{"text":"Zur Boxübersicht"},on:{"click":_vm.changeToBoxListPage}})],1)])])]),_c('PageCard',{attrs:{"title":"Informationen zum Geschäft"}},[_c('div',{staticClass:"flex-col text-left px-6 py-5 min-w-card"},[_c('CustomForm',{ref:"customerChangeShopInfoForm"},[_c('div',{staticClass:"border-b border-gray-300 pb-3 mb-3"},[_c('div',{staticClass:"pl-2 pb-1 text-xs"},[_vm._v("Name des Geschäfts")]),(_vm.state === 0)?_c('div',{staticClass:"pl-2 text-black"},[_vm._v(" "+_vm._s(_vm.shopNameObj.text)+" ")]):_c('div',[_c('CustomInput',{attrs:{"value":_vm.shopNameObj,"rules":[
										_vm.$rules.required,
										_vm.$rules.lengthAbove3,
										_vm.$rules.lengthBelow50 ]}})],1)]),_c('div',[_c('div',{staticClass:"pl-2 pb-1 text-xs"},[_vm._v("Anschrift")]),(_vm.state === 0)?_c('div',{staticClass:"pl-2 text-black"},[_vm._v(" "+_vm._s(_vm.shopStreetNameObj.text + ' ' + _vm.shopStreetNumberObj.text)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.shopZipCodeObj.text + ' ' + _vm.shopCityNameObj.text)+" ")]):_c('div',[_c('div',{staticClass:"flex flex-row"},[_c('div',{staticClass:"flex-1 mr-3"},[_c('CustomInput',{staticClass:"mx-auto mt-3",attrs:{"placeholder":"Straße","type":"text","rules":[
												_vm.$rules.required,
												_vm.$rules.lengthAbove3,
												_vm.$rules.lengthBelow30 ]},model:{value:(_vm.shopStreetNameObj),callback:function ($$v) {_vm.shopStreetNameObj=$$v},expression:"shopStreetNameObj"}})],1),_c('div',{staticClass:"w-1/4"},[_c('CustomInput',{staticClass:"mx-auto mt-3",attrs:{"placeholder":"Nr.","type":"text","rules":[_vm.$rules.required, _vm.$rules.lengthBelow20]},model:{value:(_vm.shopStreetNumberObj),callback:function ($$v) {_vm.shopStreetNumberObj=$$v},expression:"shopStreetNumberObj"}})],1)]),_c('div',{staticClass:"flex flex-row"},[_c('div',{staticClass:"w-1/3"},[_c('CustomInput',{staticClass:"mt-3",attrs:{"placeholder":"PLZ","type":"text","rules":[
												_vm.$rules.required,
												_vm.$rules.lengthAbove3,
												_vm.$rules.lengthBelow20 ]},model:{value:(_vm.shopZipCodeObj),callback:function ($$v) {_vm.shopZipCodeObj=$$v},expression:"shopZipCodeObj"}})],1),_c('div',{staticClass:"flex-1 ml-3"},[_c('CustomInput',{staticClass:"mt-3",attrs:{"placeholder":"Ort","type":"text","rules":[
												_vm.$rules.required,
												_vm.$rules.lengthAbove3,
												_vm.$rules.lengthBelow30 ]},model:{value:(_vm.shopCityNameObj),callback:function ($$v) {_vm.shopCityNameObj=$$v},expression:"shopCityNameObj"}})],1)])])])])],1),(_vm.state === 0)?_c('div',{staticClass:"flex flex-row mt-4"},[_c('div',{staticClass:"flex-1"}),_c('CustomButtonIcon',{attrs:{"icon":this.$icons.edit,"tooltip":"Bearbeiten"},on:{"click":_vm.editShopInfo}}),_c('div',{staticClass:"flex-1"})],1):_c('div',{staticClass:"flex flex-row mt-4"},[_c('div',{staticClass:"flex-1"}),_c('CustomButtonIcon',{staticClass:"mx-2 bg-btnbg_secondary_light",attrs:{"icon":this.$icons.cancel,"tooltip":"Abbrechen"},on:{"click":_vm.cancelEditShopInfo}}),_c('CustomButtonIcon',{staticClass:"mx-2",attrs:{"icon":this.$icons.confirm,"tooltip":"Speichern","loading":_vm.editShop.isLoading},on:{"click":_vm.saveEditShopInfo}}),_c('div',{staticClass:"flex-1"})],1)]),_c('div',{staticClass:"flex-1"})],1)]),_c('LoadingScreen',{attrs:{"has-loaded":_vm.hasLoaded}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }