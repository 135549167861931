<template>
	<div id="admin-container" class="flex flex-1 flex-row">
		<div
			id="admin-navbar"
			class="fixed top-0 left-0 z-30 group flex flex-col bg-navbg_light h-full w-20 hover:w-80 rounded-br-nav rounded-tr-nav shadow-nav"
			@mouseover="hoverOn"
			@mouseleave="hoverOff"
		>
			<NavbarLogoItem></NavbarLogoItem>

			<NavbarItem
				:active="mainRoute === 'home'"
				text="Startseite"
				:image="this.$icons.home"
				:image-active="this.$icons.homeActive"
				@selected="changeToHomePage"
			></NavbarItem>

			<NavbarItem
				:active="mainRoute === 'boxes' || mainRoute === 'boxes'"
				text="Boxübersicht"
				:image="this.$icons.userGroup"
				:image-active="this.$icons.userGroupActive"
				@selected="changeToBoxListPage"
			></NavbarItem>

			<NavbarItem
				:active="mainRoute === 'reseller' || mainRoute === 'addReseller'"
				text="Händlerübersicht"
				:image="this.$icons.userGroup"
				:image-active="this.$icons.userGroupActive"
				@selected="changeToResellerListPage"
			></NavbarItem>

			<NavbarSubItem
				v-show="hoverItem"
				text="Händler hinzufügen"
				:active="mainRoute === 'addReseller'"
				:image="this.$icons.add"
				:image-active="this.$icons.addActive"
				@selected="changeToResellerAddPage"
			></NavbarSubItem>

			<NavbarItem
				:active="mainRoute === 'developerkeys' || mainRoute === 'addDeveloperKey'"
				text="Developer Keys"
				:image="this.$icons.userGroup"
				:image-active="this.$icons.userGroupActive"
				@selected="changeToDeveloperKeysPage"
			></NavbarItem>

			<NavbarSubItem
				v-show="hoverItem"
				text="Key erstellen"
				:active="mainRoute === 'addDeveloperKey'"
				:image="this.$icons.add"
				:image-active="this.$icons.addActive"
				@selected="changeToAddDeveloperKeyPage"
			></NavbarSubItem>

			<NavbarItem
				:active="mainRoute === 'logs'"
				text="Log Dateien"
				:image="this.$icons.log"
				:image-active="this.$icons.logActive"
				@selected="changeToLogPage"
			></NavbarItem>

			<div class="flex-1"></div>

			<NavbarItem
				:active="mainRoute === 'profile'"
				text="Profil bearbeiten"
				:image="this.$icons.profile"
				:image-active="this.$icons.profileActive"
				@selected="changeToProfilePage"
			></NavbarItem>

			<NavbarItem
				:active="false"
				class="mb-5"
				text="Abmelden"
				:image="this.$icons.logout"
				:image-active="this.$icons.logoutActive"
				@selected="logout"
			></NavbarItem>
		</div>

		<router-view :key="millis" class="flex-1 min-h-screen"></router-view>
	</div>
</template>

<script>
import NavbarItem from '../components/NavbarItem'
import NavbarLogoItem from '../components/NavbarLogoItem'
import NavbarSubItem from '../components/NavbarSubItem'

export default {
	name: 'AdminContainerPage',
	components: {
		NavbarSubItem,
		NavbarItem,
		NavbarLogoItem,
	},
	data() {
		return {
			millis: 0,
			mainRoute: '',
			hoverItem: undefined,
		}
	},
	created() {
		this.updateRoutes()

		this.$store.dispatch('status/init', this.$API_BASEURL)
		this.$store.dispatch('status/connect')
	},
	beforeUpdate() {
		this.updateRoutes()
	},
	methods: {
		hoverOn: function (itemName) {
			this.hoverItem = itemName
		},
		hoverOff: function () {
			this.hoverItem = undefined
		},
		updateKey: function () {
			this.millis = new Date().getMilliseconds()
		},
		updateRoutes: function () {
			let route = this.$route.path
			this.mainRoute = route.replace('/admin/', '')
			const indextSlash = this.mainRoute.indexOf('/')
			if (indextSlash > -1) {
				this.mainRoute = this.mainRoute.substr(0, this.mainRoute.indexOf('/'))
			}
		},
		changeToHomePage: function () {
			this.updateKey()
			this.$breadcrumbs.clearHistory()
			this.route = '/admin/home'
			this.$router.push(this.route).catch(() => {})
		},
		changeToDeveloperKeysPage: function () {
			this.updateKey()
			this.$breadcrumbs.clearHistory()
			this.route = '/admin/developerkeys'
			this.$router.push(this.route).catch(() => {})
		},
		changeToAddDeveloperKeyPage: function () {
			this.updateKey()
			this.$breadcrumbs.clearHistory()
			this.route = '/admin/addDeveloperKey'
			this.$router.push(this.route).catch(() => {})
		},
		changeToBoxListPage: function () {
			this.updateKey()
			this.$breadcrumbs.clearHistory()
			this.route = '/admin/boxes'
			this.$router.push(this.route).catch(() => {})
		},
		changeToResellerListPage: function () {
			this.updateKey()
			this.$breadcrumbs.clearHistory()
			this.route = '/admin/reseller'
			this.$router.push(this.route).catch(() => {})
		},
		changeToResellerAddPage: function () {
			this.updateKey()
			this.$breadcrumbs.clearHistory()
			this.route = '/admin/addReseller'
			this.$router.push(this.route).catch(() => {})
		},
		changeToLogPage: function () {
			this.updateKey()
			this.$breadcrumbs.clearHistory()
			this.route = '/admin/logs'
			this.$router.push(this.route).catch(() => {})
		},
		changeToProfilePage: function () {
			this.updateKey()
			this.$breadcrumbs.clearHistory()
			this.route = '/admin/profile'
			this.$router.push(this.route).catch(() => {})
		},
		logout: function () {
			this.$store.dispatch('status/disconnect')

			this.$store.dispatch('auth/logout', {
				targetRoute: '/login',
			})
		},
	},
}
</script>

<style scoped>
#logo-container {
	transition: 0s all;
}
#admin-navbar {
	/*position: fixed;
        top: 0;
        left: 0;*/
	transition: all 0.15s;
}
</style>