<template>
	<div class="flex flex-col">
		<PageHeader headline="Ausstehende Boxzuweisungen"></PageHeader>

		<PageBody>
			<div class="pt-10">
				<BoxPendingList :loaded="loaded"></BoxPendingList>
			</div>
		</PageBody>

		<LoadingScreen :has-loaded="hasLoaded"></LoadingScreen>
	</div>
</template>

<script>
import PageHeader from '../components/PageHeader'
import PageBody from '../components/PageBody'
import BoxPendingList from './components/BoxPendingList'
import LoadingScreen from '../components/LoadingScreen'

export default {
	name: 'CustomerBoxPendingListPage',
	components: {
		LoadingScreen,
		BoxPendingList,
		PageBody,
		PageHeader,
	},
	data() {
		return {
			loaded: {
				boxPending: false,
			},
		}
	},
	computed: {
		hasLoaded: function () {
			return this.loaded.boxPending
		},
	},
}
</script>

<style scoped></style>