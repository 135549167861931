import Vue from 'vue'
import Router from 'vue-router'

import LoginPage from './components/panel/LoginPage'
import LogoutPage from './components/panel/LogoutPage'
import IndexPage from './components/panel/IndexPage'
import PrivacyPolicyPage from './components/panel/PrivacyPolicyPage'
import LegalNoticePage from './components/panel/LegalNoticePage'
import NotFoundPage from './components/panel/NotFoundPage'
import ResetPasswordPage from './components/panel/ResetPasswordPage'
import ResetEmailPage from './components/panel/ResetEmailPage'
import ResetTwoFactorAuthenticationPage from './components/panel/ResetTwoFactorAuthenticationPage'
import AccountActivationPage from './components/panel/AccountActivationPage'

import AdminContainerPage from './components/panel/admin/AdminContainerPage'
import AdminIndexPage from './components/panel/admin/AdminIndexPage'
import AdminProfilePage from './components/panel/admin/AdminProfilePage'
import AdminLogPage from './components/panel/admin/AdminLogPage'
import AdminResellerAddPage from './components/panel/admin/AdminResellerAddPage'
import AdminResellerListPage from './components/panel/admin/AdminResellerListPage'
import AdminResellerPage from './components/panel/admin/AdminResellerPage'
import AdminDeveloperKeysPage from './components/panel/admin/AdminDeveloperKeysPage'
import AdminAddDeveloperKeyPage from './components/panel/admin/AdminAddDeveloperKeyPage'
import AdminBoxListPage from './components/panel/admin/AdminBoxListPage'
import AdminBoxPage from './components/panel/admin/AdminBoxPage'

import CustomerContainerPage from './components/panel/customer/CustomerContainerPage'
import CustomerIndexPage from './components/panel/customer/CustomerIndexPage'
import CustomerProfilePage from './components/panel/customer/CustomerProfilePage'
import CustomerShopListPage from './components/panel/customer/CustomerShopListPage'
import CustomerSingleShopPage from './components/panel/customer/CustomerSingleShopPage'
import CustomerAddShopPage from './components/panel/customer/CustomerAddShopPage'
import CustomerAddBoxPage from './components/panel/customer/CustomerAddBoxPage'
import CustomerBoxListPage from './components/panel/customer/CustomerBoxListPage'
import CustomerBoxPage from './components/panel/customer/CustomerBoxPage'
import CustomerBonListPage from './components/panel/customer/CustomerBonListPage'
import CustomerBonPage from './components/panel/customer/CustomerBonPage'

import ResellerContainerPage from './components/panel/reseller/ResellerContainerPage'
import ResellerIndexPage from './components/panel/reseller/ResellerIndexPage'
import ResellerProfilePage from './components/panel/reseller/ResellerProfilePage'
import ResellerCustomerListPage from './components/panel/reseller/ResellerCustomerListPage'
import ResellerCustomerPendingListPage from './components/panel/reseller/ResellerCustomerPendingListPage'
import ResellerCustomerPage from './components/panel/reseller/ResellerCustomerPage'
import ResellerCustomerAddPage from './components/panel/reseller/ResellerCustomerAddPage'
import ResellerBoxListPage from './components/panel/reseller/ResellerBoxListPage'
import ResellerBoxPage from './components/panel/reseller/ResellerBoxPage'
import ResellerBoxPendingListPage from './components/panel/reseller/ResellerBoxPendingListPage'
import ResellerBoxAddPage from './components/panel/reseller/ResellerBoxAddPage'

import { TokenService } from './services/token.service'
import { LoggerService } from './services/logger.service'
import { BreadcrumbService } from './services/breadcrumb.service'

Vue.use(Router)

const CustomRouter = new Router({
	mode: 'history',
	base: '/',
	routes: [
		{
			path: '/',
			name: 'index',
			component: IndexPage,
			meta: {
				noHistory: true,
			},
		},
		{
			path: '/login',
			name: 'login',
			component: LoginPage,
			meta: {
				public: true,
				onlyWhenLoggedOut: true,
				noHistory: true,
			},
		},
		{
			path: '/logout',
			name: 'logout',
			component: LogoutPage,
			meta: {
				noHistory: true,
			},
		},
		{
			path: '/privacyPolicy',
			name: 'privacyPolicyPage',
			component: PrivacyPolicyPage,
			props: true,
			meta: {
				public: true,
				noHistory: true,
			},
		},
		{
			path: '/legalNotice',
			name: 'legalNoticePage',
			component: LegalNoticePage,
			props: true,
			meta: {
				public: true,
				noHistory: true,
			},
		},
		{
			path: '/resetPassword/:identifier',
			name: 'resetPassword',
			component: ResetPasswordPage,
			meta: {
				public: true,
				onlyWhenLoggedOut: true,
				forceLogout: true,
				noHistory: true,
			},
		},
		{
			path: '/resetEmail/:identifier',
			name: 'resetEmail',
			component: ResetEmailPage,
			meta: {
				public: true,
				onlyWhenLoggedOut: true,
				forceLogout: true,
				noHistory: true,
			},
		},
		{
			path: '/resetTwoFactorType/:identifier',
			name: 'resetTwoFactorType',
			component: ResetTwoFactorAuthenticationPage,
			meta: {
				public: true,
				onlyWhenLoggedOut: true,
				forceLogout: true,
				noHistory: true,
			},
		},
		{
			path: '/accountActivation/:identifier',
			name: 'accountActivation',
			component: AccountActivationPage,
			meta: {
				public: true,
				onlyWhenLoggedOut: true,
				forceLogout: true,
				noHistory: true,
			},
		},
		{
			path: '/admin',
			name: 'adminContainer',
			component: AdminContainerPage,
			children: [
				{
					path: 'home',
					name: 'adminIndexPage',
					component: AdminIndexPage,
					meta: {
						onlyAdmin: true,
						name: 'Startseite',
					},
				},
				{
					path: 'profile',
					name: 'adminProfilePage',
					component: AdminProfilePage,
					meta: {
						onlyAdmin: true,
					},
				},
				{
					path: 'logs',
					component: AdminLogPage,
					meta: {
						onlyAdmin: true,
					},
				},
				{
					path: 'developerkeys',
					component: AdminDeveloperKeysPage,
					meta: {
						onlyAdmin: true,
						name: 'Developer Keys',
					},
				},
				{
					path: 'addDeveloperKey',
					component: AdminAddDeveloperKeyPage,
					meta: {
						onlyAdmin: true,
						name: 'Neuen Key anlegen',
					},
				},
				{
					path: 'reseller',
					component: AdminResellerListPage,
					meta: {
						onlyAdmin: true,
						name: 'Händlerübersicht',
					},
				},
				{
					path: 'reseller/:identifier',
					component: AdminResellerPage,
					meta: {
						onlyAdmin: true,
						name: 'Details zum Händler',
					},
				},
				{
					path: 'addReseller',
					component: AdminResellerAddPage,
					meta: {
						onlyAdmin: true,
						name: 'Neuen Händler anlegen',
					},
				},
				{
					path: 'boxes',
					component: AdminBoxListPage,
					meta: {
						onlyAdmin: true,
						name: 'Boxübersicht',
					},
				},
				{
					path: 'boxes/:identifier',
					component: AdminBoxPage,
					meta: {
						onlyAdmin: true,
						name: 'Details zur Box',
					},
				},
				{
					path: '*',
					redirect: '/',
				},
			],
		},
		{
			path: '/reseller',
			name: 'resellerContainer',
			component: ResellerContainerPage,
			children: [
				{
					path: 'home',
					name: 'resellerIndexPage',
					component: ResellerIndexPage,
					meta: {
						onlyReseller: true,
						name: 'Startseite',
					},
				},
				{
					path: 'profile',
					name: 'resellerProfilePage',
					component: ResellerProfilePage,
					meta: {
						onlyReseller: true,
						name: 'Profilseite',
					},
				},
				{
					path: 'customers',
					name: 'resellerCustomerListPage',
					component: ResellerCustomerListPage,
					meta: {
						onlyReseller: true,
						name: 'Kundenübersicht',
					},
				},
				{
					path: 'customersPending',
					name: 'resellerCustomerPendingListPage',
					component: ResellerCustomerPendingListPage,
					meta: {
						onlyReseller: true,
						name: 'Ausstehende Kontoaktivierungen',
					},
				},
				{
					path: 'addCustomer',
					name: 'resellerCustomerAddPage',
					component: ResellerCustomerAddPage,
					meta: {
						onlyReseller: true,
						name: 'Neuen Kunden anlegen',
					},
				},
				{
					path: 'customers/:identifier',
					name: 'resellerCustomerPage',
					component: ResellerCustomerPage,
					meta: {
						onlyReseller: true,
						name: 'Details zum Kunden',
					},
				},
				{
					path: 'boxes',
					name: 'resellerBoxListPage',
					component: ResellerBoxListPage,
					meta: {
						onlyReseller: true,
						name: 'Boxübersicht',
					},
				},
				{
					path: 'boxes/:identifier',
					name: 'resellerBoxPage',
					component: ResellerBoxPage,
					meta: {
						onlyReseller: true,
						name: 'Details zur cybo-box',
					},
				},
				{
					path: 'pendingBoxes',
					name: 'resellerBoxPendingListPage',
					component: ResellerBoxPendingListPage,
					meta: {
						onlyReseller: true,
						name: 'Ausstehende cybo-box-Zuweisungen',
					},
				},
				{
					path: 'addBox',
					name: 'resellerBoxAddPage',
					component: ResellerBoxAddPage,
					meta: {
						onlyReseller: true,
						name: 'Neue cybo-box zuweisen',
					},
				},
				{
					path: '*',
					redirect: '/',
				},
			],
		},
		{
			path: '/customer',
			name: 'customerContainerPage',
			component: CustomerContainerPage,
			children: [
				{
					path: 'home',
					name: 'customerIndexPage',
					component: CustomerIndexPage,
					meta: {
						onlyCustomer: true,
						name: 'Startseite',
					},
				},
				{
					path: 'profile',
					name: 'customerProfilePage',
					component: CustomerProfilePage,
					meta: {
						onlyCustomer: true,
						name: 'Profilseite',
					},
				},
				{
					path: 'addShop',
					name: 'customerAddShopPage',
					component: CustomerAddShopPage,
					meta: {
						onlyCustomer: true,
						name: 'Geschäft hinzufügen',
					},
				},
				{
					path: 'shops',
					name: 'customerShopListPage',
					component: CustomerShopListPage,
					meta: {
						onlyCustomer: true,
						name: 'Geschäfteübersicht',
					},
				},
				{
					path: 'shops/:identifier',
					name: 'customerSingleShopPage',
					component: CustomerSingleShopPage,
					meta: {
						onlyCustomer: true,
						name: 'Details zum Geschäft',
					},
				},
				{
					path: 'addBox',
					name: 'customerAddBoxPage',
					component: CustomerAddBoxPage,
					meta: {
						onlyCustomer: true,
						name: 'Neue Box zuweisen',
					},
				},
				{
					path: 'boxes',
					name: 'customerBoxListPage',
					component: CustomerBoxListPage,
					meta: {
						onlyCustomer: true,
						name: 'Boxübersicht',
					},
				},
				{
					path: 'boxes/:identifier',
					name: 'customerBoxPage',
					component: CustomerBoxPage,
					meta: {
						onlyCustomer: true,
						name: 'Details zur Box',
					},
					props: true,
				},
				{
					path: 'bons',
					name: 'customerBonListPage',
					component: CustomerBonListPage,
					meta: {
						onlyCustomer: true,
						name: 'Bonarchiv',
					},
				},
				{
					path: 'bons/:identifier',
					name: 'customerBonPage',
					component: CustomerBonPage,
					meta: {
						onlyCustomer: true,
						name: 'Details zum Kassenbon',
					},
					props: true,
				},
				{
					path: '*',
					redirect: '/',
				},
			],
		},
		{
			path: '*',
			redirect: '/',
		},
		{
			path: '/error',
			name: 'error',
			component: NotFoundPage,
			meta: {
				public: true,
				noHistory: true,
			},
		},
	],
})

import { CustomStore } from './store/store'

async function routerGuard(to, from, next) {
	const isPublic = to.matched.some((record) => record.meta.public)
	const onlyWhenLoggedOut = to.matched.some((record) => record.meta.onlyWhenLoggedOut)
	const forceLogout = to.matched.some((record) => record.meta.forceLogout)
	const onlyReseller = to.matched.some((record) => record.meta.onlyReseller)
	const onlyCustomer = to.matched.some((record) => record.meta.onlyCustomer)
	const onlyAdmin = to.matched.some((record) => record.meta.onlyAdmin)

	const isLoggedIn = CustomStore.getters['auth/isLoggedIn']
	const isAdmin = CustomStore.getters['auth/isAdmin']
	const isReseller = CustomStore.getters['auth/isReseller']
	const isCustomer = CustomStore.getters['auth/isCustomer']

	if (!isLoggedIn && !isPublic) {
		LoggerService.debug(
			'router.js:routerGuard',
			'not public and not logged in -> redirect to /login'
		)
		return next({
			path: '/login',
			query: {
				redirect: to.fullPath,
			},
		})
	}

	if (isLoggedIn && forceLogout) {
		return next({
			path: '/logout',
			query: {
				redirect: to.fullPath,
			},
		})
	}

	if (isLoggedIn && onlyWhenLoggedOut) {
		LoggerService.debug(
			'router.js:routerGuard',
			'logged in and only when logged out -> redirect to /'
		)
		return next('/')
	}

	// redirect to index page depending on the identity of the user
	if (to.path === '/') {
		if (isReseller) {
			return next({
				name: 'resellerIndexPage',
			})
		} else if (isCustomer) {
			return next({
				name: 'customerIndexPage',
			})
		} else if (isAdmin) {
			return next({
				name: 'adminIndexPage',
			})
		}
	}

	if (onlyReseller && !isReseller) {
		return next('/')
	}
	if (onlyCustomer && !isCustomer) {
		return next('/')
	}
	if (onlyAdmin && !isAdmin) {
		return next('/')
	}

	return next()
}

CustomRouter.beforeEach((to, from, next) => {
	return routerGuard(to, from, next)
})

CustomRouter.afterEach((to, from) => {
	const noHistory = to.matched.some((record) => record.meta.noHistory)
	if (noHistory) {
		BreadcrumbService.clearHistory()
		return
	}

	if (from.path !== from.fullPath) {
		BreadcrumbService.replaceLastPath(from.fullPath)
	}

	BreadcrumbService.addPage(to.meta.name, to.path)
})

export { CustomRouter }
