import axios from 'axios'

import { LoggerService } from './logger.service'

const ApiService = {
	init: function (baseURL) {
		LoggerService.debug('api.service.js:init', 'init api base url -> ' + baseURL)
		axios.defaults.baseURL = baseURL
	},
	getBaseUrl: function () {
		return axios.defaults.baseURL
	},
	setAuthHeader: function (token) {
		LoggerService.debug('api.service.js:setAuthHeader', 'setting auth header')
		axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
	},
	removeAuthHeader: function () {
		LoggerService.debug('api.service.js:removeAuthHeader', 'removing auth header')
		axios.defaults.headers.common['Authorization'] = ''
	},
	setHeader: function (name, value) {
		axios.defaults.headers.common[name] = value
	},
	removeHeader: function (name) {
		axios.defaults.headers.common[name] = ''
	},
	get: function (url) {
		return axios.get(url)
	},
	post: function (url, body) {
		return axios.post(url, body)
	},
}

export { ApiService }
