<template>
	<div
		style="padding-top: 0.1em; padding-bottom: 0.1rem"
		class="text-xs px-3 w-40 bg-red-200 text-red-800 rounded-full text-center"
	>
		{{ text }}
	</div>
</template>

<script>
export default {
	name: 'BadgeError',
	props: {
		text: {
			type: String,
			required: true,
		},
	},
}
</script>

<style scoped></style>