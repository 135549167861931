<template>
	<div class="flex flex-col">
		<PageHeader headline="Log Dateien" description="Übersicht der Ereignisse"></PageHeader>

		<PageBody>
			<div>Noch im Aufbau</div>
		</PageBody>
	</div>
</template>

<script>
import PageHeader from '../components/PageHeader'
import PageBody from '../components/PageBody'

export default {
	name: 'AdminLogPage',
	components: {
		PageHeader,
		PageBody,
	},
}
</script>

<style scoped></style>