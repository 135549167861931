<template>
	<div class="flex flex-col">
		<PageHeader headline="Boxübersicht"></PageHeader>

		<PageBody>
			<div class="container mx-auto pt-4">
				<PageCard title="Übersicht über Ihre cybo-boxen">
					<div class="bg-listbg_dark rounded-3xl px-4 mb-4">
						<div class="flex flex-row">
							<div
								class="flex flex-row text-left pt-2 pb-2 text-xs font-bold text-page_card_header_text cursor-pointer hover:underline"
								@click="toggleFilters"
							>
								<img
									:src="showFilters ? this.$icons.mdiMinus : this.$icons.mdiPlus"
									alt=""
									class="h-4"
								/>
								<div class="ml-1 select-none">
									Listenfilter {{ showFilters ? 'ausblenden' : 'einblenden' }}
								</div>
							</div>
							<div class="flex flex-1"></div>
						</div>
						<div v-if="showFilters" class="flex flex-row pb-3 pt-2">
							<div class="flex flex-row">
								<div class="mx-1">
									<CustomDropdownFilter
										:data="filter.status"
										class="w-52"
										title="Status"
									></CustomDropdownFilter>
								</div>
								<div class="mx-1">
									<CustomInputFilter
										v-model="filter.boxUserIdObj"
										class="w-40"
										title="Boxnummer"
									></CustomInputFilter>
								</div>
								<div class="mx-1">
									<CustomInputFilter
										v-model="filter.boxNameObj"
										class="w-64"
										title="Boxbezeichnung"
									></CustomInputFilter>
								</div>
								<div class="mx-1">
									<CustomInputFilter
										v-model="filter.shopNameObj"
										class="w-64"
										title="Name des Geschäfts"
									></CustomInputFilter>
								</div>
								<div class="">
									<CustomButtonIcon
										:icon="this.$icons.cancel"
										tooltip="Alle Filter zurücksetzen"
										bg-color="bg-red-300"
										class="ml-2 mt-1"
										@click="resetAllFilters"
									></CustomButtonIcon>
								</div>
							</div>
						</div>
					</div>

					<div class="text-center">
						<div class="bg-listbg_dark rounded-3xl px-4 py-4">
							<div class="flex flex-row text-list_textheader_dark h-9 text-xs">
								<div class="w-48 h-full py-1 pl-4 font-bold text-left pl-4">
									Status
								</div>
								<div class="w-32 h-full py-1 font-bold text-left pl-4">
									Boxnummer
								</div>
								<div class="flex-1 h-full py-1 font-bold text-left pl-4">
									Boxbezeichnung
								</div>
								<div class="flex-1 h-full py-1 font-bold text-left pl-4">
									Name des Geschäfts
								</div>
								<div class="w-36 h-full py-1 font-bold text-right pr-4">
									Archivierte Bons
								</div>
								<div
									class="flex-1 flex flex-row h-full py-1 font-bold text-left pl-4 cursor-pointer hover:underline"
									@click="sortBy('assigned')"
								>
									<div class="flex-1">Zuweisungsdatum</div>
									<div v-if="sort.column === 'assigned'" class="flex-1 pl-4">
										<img
											v-if="sort.ascending"
											:src="this.$icons.arrowUp"
											alt=""
											class="h-4"
										/>
										<img
											v-if="!sort.ascending"
											:src="this.$icons.arrowDown"
											alt=""
											class="h-4"
										/>
									</div>
								</div>
							</div>

							<div>
								<!-- click method is not on this elem so that the button click does not trigger it -->
								<div
									v-for="box in boxList"
									:key="box.id"
									class="flex flex-row text-new_fg cursor-pointer rounded-3xl hover:bg-white text-xs border-t border-white"
									@click="openBoxPage(box.id)"
								>
									<div class="w-48 h-full py-3 pl-4 text-left">
										<BadgeFatGreen
											v-if="box.status === 'ACTIVE'"
											text="IN BETRIEB"
										></BadgeFatGreen>
										<BadgeFatPurple
											v-else-if="box.status === 'NOT_ACTIVATED'"
											text="NOCH NICHT AKTIVIERT"
										></BadgeFatPurple>
										<BadgeFatRed v-else text="DEAKTIVIERT"></BadgeFatRed>
									</div>
									<div class="w-32 h-full py-3 text-left pl-4">
										{{ box.boxUserId }}
									</div>
									<div class="flex-1 h-full py-3 text-left pl-4">
										{{ box.boxName }}
									</div>
									<div class="flex-1 h-full py-3 text-left pl-4">
										{{ box.shopName }}
									</div>
									<div class="w-36 h-full py-3 text-right pr-4">
										{{ box.numStoredBons }}
									</div>
									<div class="flex-1 h-full py-3 text-left pl-4">
										{{
											$utils.date2timeString(new Date(box.assigned)) +
											' ' +
											$utils.date2dateString(new Date(box.assigned))
										}}
									</div>
								</div>
							</div>

							<div v-if="boxList.length === 0" class="text-sm">
								Keine cybo-box gefunden
							</div>
						</div>
					</div>

					<CustomPagination
						:current-page-num="page.current"
						:max-page-num="page.max"
						:total-elements="page.totalElements"
						class="mt-4"
						@previous="previousPage"
						@next="nextPage"
					></CustomPagination>
				</PageCard>
			</div>
		</PageBody>

		<LoadingScreen :has-loaded="hasLoaded"></LoadingScreen>
	</div>
</template>

<script>
import PageHeader from '../components/PageHeader'
import PageBody from '../components/PageBody'
import PageCard from '../components/PageCard'
import LoadingScreen from '../components/LoadingScreen'

import CustomDropdownFilter from '../components/inputs/CustomDropdownFilter'
import CustomInputFilter from '../components/inputs/CustomInputFilter'
import CustomPagination from '../components/CustomPagination'
import BadgeFatGreen from '../components/badges/BadgeFatGreen'
import BadgeFatPurple from '../components/badges/BadgeFatPurple'
import BadgeFatRed from '../components/badges/BadgeFatRed'
import CustomButtonIcon from '../components/inputs/CustomButtonIcon'

const moment = require('moment')

export default {
	name: 'CustomerBoxListPage',
	components: {
		CustomButtonIcon,
		BadgeFatRed,
		BadgeFatPurple,
		BadgeFatGreen,
		CustomPagination,
		CustomInputFilter,
		CustomDropdownFilter,
		LoadingScreen,
		PageCard,
		PageBody,
		PageHeader,
	},
	data() {
		return {
			loaded: {
				boxList: false,
			},
			boxList: [],
			showFilters: false,
			filter: {
				status: {
					selected: {
						text: 'Keine Auswahl',
						value: 'NONE',
					},
					options: [
						{ text: 'Keine Auswahl', value: 'NONE' },
						{ text: 'IN BETRIEB', value: 'ACTIVE' },
						{ text: 'DEAKTIVIERT', value: 'DEACTIVATED' },
						{ text: 'NOCH NICHT AKTIVIERT', value: 'NOT_ACTIVATED' },
					],
				},
				boxUserIdObj: {
					text: '',
				},
				boxNameObj: {
					text: '',
				},
				shopNameObj: {
					text: '',
				},
			},
			sort: {
				column: 'assigned',
				ascending: false,
			},
			page: {
				entriesPerPage: 10,
				current: 1,
				max: 1,
				totalElements: 0,
			},
		}
	},
	computed: {
		hasLoaded: function () {
			return this.loaded.boxList
		},
	},
	watch: {
		'filter.status.selected.value': function (e) {
			let query = Object.assign({}, this.$route.query)

			if (!e || e.length === 0 || e === 'NONE') {
				delete query.status
			} else {
				query.status = e
			}

			this.$router.push({ query }).catch(() => {
				/* catches the redundant navigation error on page load */
			})

			this.getBoxList()
		},
		'filter.boxUserIdObj.text': function (e) {
			let query = Object.assign({}, this.$route.query)

			if (!e || e.length === 0) {
				delete query.boxId
			} else {
				query.boxId = e
			}

			this.$router.push({ query }).catch(() => {
				/* catches the redundant navigation error on page load */
			})

			this.getBoxList()
		},
		'filter.boxNameObj.text': function (e) {
			let query = Object.assign({}, this.$route.query)

			if (!e || e.length === 0) {
				delete query.boxName
			} else {
				query.boxName = e
			}

			this.$router.push({ query }).catch(() => {
				/* catches the redundant navigation error on page load */
			})

			this.getBoxList()
		},
		'filter.shopNameObj.text': function (e) {
			let query = Object.assign({}, this.$route.query)

			if (!e || e.length === 0) {
				delete query.shopName
			} else {
				query.shopName = e
			}

			this.$router.push({ query }).catch(() => {
				/* catches the redundant navigation error on page load */
			})

			this.getBoxList()
		},
	},
	created() {
		const queryParams = this.$route.query

		if (queryParams.page) {
			this.updateTargetPage(parseInt(queryParams.page))
			this.updateCurrentPage(parseInt(queryParams.page))
		}

		let hasParam = false

		if (queryParams.status) {
			if (queryParams.status === 'ACTIVE') {
				this.filter.status.selected = { text: 'AKTIVIERT', value: 'ACTIVE' }
				hasParam = true
			} else if (queryParams.status === 'NOT_ACTIVATED') {
				this.filter.status.selected = {
					text: 'NOCH NICHT AKTIVIERT',
					value: 'NOT_ACTIVATED',
				}
				hasParam = true
			} else if (queryParams.status === 'DEACTIVATED') {
				this.filter.status.selected = { text: 'DEAKTIVIERT', value: 'DEACTIVATED' }
				hasParam = true
			}
		}
		if (queryParams.boxId) {
			this.filter.boxUserIdObj.text = queryParams.boxId
			hasParam = true
		}
		if (queryParams.boxName) {
			this.filter.boxNameObj.text = queryParams.boxName
			hasParam = true
		}
		if (queryParams.shopName) {
			this.filter.shopNameObj.text = queryParams.shopName
			hasParam = true
		}

		if (hasParam) {
			this.showFilters = true
		}

		this.getBoxList()
	},
	methods: {
		previousPage: function () {
			this.page.current = Math.max(0, this.page.current - 1)

			this.getBoxList()
		},
		nextPage: function () {
			this.page.current = Math.min(this.page.max, this.page.current + 1)

			this.getBoxList()
		},
		sortBy: function (column) {
			if (column === this.sort.column) {
				this.sort.ascending = !this.sort.ascending
			} else {
				this.sort.column = column
				this.sort.ascending = false
			}

			this.getBoxList()
		},
		toggleFilters: function () {
			if (this.showFilters) {
				// do not hide filters if filters are set
				if (
					this.filter.boxUserIdObj.text.length === 0 &&
					this.filter.boxNameObj.text.length === 0 &&
					this.filter.shopNameObj.text.length === 0 &&
					this.filter.status.selected.value === 'NONE'
				) {
					this.showFilters = false
				} else {
					this.$toast.info('Es sind noch Filter ausgewählt', this.$toaststyle.errorMid)
				}
			} else {
				this.showFilters = true
			}
		},
		resetAllFilters: function () {
			this.filter.status.selected = Object.assign({}, this.filter.status.options[0])
			this.filter.boxUserIdObj.text = ''
			this.filter.boxNameObj.text = ''
			this.filter.shopNameObj.text = ''

			this.$toast.success('Listenfilter zurückgesetzt', this.$toaststyle.successMid)
		},
		getBoxList: function () {
			const context = this

			let boxStatus = context.filter.status.selected.value
			if (boxStatus === 'NONE') {
				boxStatus = ''
			}

			context.$http
				.post('/panel/customer/getBoxList', {
					page: context.page.current - 1,
					sortAscending: context.sort.ascending,
					boxStatus: boxStatus,
					boxUserId: context.filter.boxUserIdObj.text,
					boxName: context.filter.boxNameObj.text,
					shopName: context.filter.shopNameObj.text,
				})
				.then((response) => {
					context.loaded.boxList = true

					const status = response.data.status.value
					if (response.status === 200 && status === 100) {
						context.page.max = response.data.body.pageInfo.numPages
						context.page.totalElements = response.data.body.pageInfo.numEntries
						context.boxList = response.data.body.boxList
					}
				})
				.catch((err) => {
					context.$toast.error(err.message, context.$toaststyle.errorMid)
					console.log(err)
				})
				.finally(() => {
					context.loaded.boxList = true
				})
		},
		openBoxPage: function (boxId) {
			this.$router.push({
				path: '/customer/boxes/' + boxId,
				query: {
					from: '/customer/boxes',
				},
			})
		},
	},
}
</script>

<style scoped></style>